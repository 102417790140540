import { Button, FormControl, Grid, Paper, TextField, Typography } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import React, { useContext, useEffect, useState } from 'react';
import { useRef } from "react";
import { AppContext } from '../AppContextProvider';
import { MasterService } from '../Services/MasterService';
import { openLink } from "../Utils";
import { DataInconsistencyCheck } from "./data-inconsistency-check";

const useStyles = makeStyles(theme => ({

    link: { marginLeft: 8, fontSize: 16, color: blue[700], cursor: "pointer" },
}));

function ImportantLinks(props) {

    const classes = useStyles()
    const { setTitle, showProgressDialog, showSnackBar, closeProgressDialog, showConfirmDialog1D, closeConfirmDialog1D } = useContext(AppContext);
    const [links, setLinks] = useState([])
    const [panName, setPanName] = useState('')
    const [linkMessage, setLinkMessage] = useState("");
    const [linkStatus, setLinkStatus] = useState("");
    const panRef = useRef(null)
    const panARef = useRef(null)
    const aadhaarRef = useRef(null)

    useEffect(() => {
        setTitle("Important Links")
        showProgressDialog('Loading...');
        MasterService.importantLinks()
            .then(data => {
                console.log(data)
                if (data.success) {
                    setLinks(data.links)
                } else {
                    showSnackBar(data.error, "error")
                }
            })
            .catch(error => {
                showSnackBar('Something went wrong. Please try again later.', 'error');
            })
            .finally(() => closeProgressDialog())
        return () => {
            setTitle("")
        }
    }, []);

    const fetchPANName = () => {
        if (panRef.current.value.length === 10) {
            showProgressDialog('Fetching...');
            MasterService.fetchPANName(panRef.current.value)
                .then(data => {
                    console.log(data)
                    if (data.success) {
                        let name = []
                        name.push(data.data.first_name)
                        name.push(data.data.middle_name)
                        name.push(data.data.last_name)
                        setPanName(name.join(' '))
                    } else {
                        setPanName('')
                        console.error(data.error)
                        showSnackBar(data.error, "error")
                    }
                })
                .catch(error => {
                    setPanName('')
                    console.error(error)
                    showSnackBar('Something went wrong. Please try again later.', 'error');
                })
                .finally(() => closeProgressDialog())
        } else {
            showConfirmDialog1D('Enter valid PAN No', undefined, () => {
                closeConfirmDialog1D();
            }, 'OK');
        }
    }

    const fetchAadhaarPANLinked = () => {
        console.log(panARef.current.value);
        console.log(aadhaarRef.current.value);
        if (panARef.current.value.length === 10 && aadhaarRef.current.value.length === 12) {
            showProgressDialog("Fetching...");
            MasterService.aadhaarPANLinked(panARef.current.value, aadhaarRef.current.value)
                .then((data) => {
                    console.log(data);
                    if (data.success) {
                        setLinkMessage(data.message);
                        setLinkStatus(data.linked ? "Linked" : "Not Linked");
                    } else {
                        showSnackBar(data.error, "error");
                    }
                })
                .catch((error) => {
                    showSnackBar(
                        "Something went wrong. Please try again later.",
                        "error"
                    );
                })
                .finally(() => closeProgressDialog());
        } else {
            showConfirmDialog1D(
                "Enter valid input",
                undefined,
                () => {
                    closeConfirmDialog1D();
                },
                "OK"
            );
        }
    }

    return (
        <div className="Home">
            <div className="lander">
                <div >
                    <Grid container direction="row" spacing={2} justify="center">
                        <Grid item xl={8} lg={8} xs={12}>
                            <Paper style={{ padding: 16, textAlign: 'start' }}>
                                <Grid container spacing={1} alignItems="center" justify="space-between">
                                    <Grid item xs={12} md={3}>
                                        <Typography variant="subtitle1" component="span">Fetch PAN name</Typography>
                                        <Typography variant="caption" component="div">PAN name is fetched from the IT dept</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormControl variant="outlined" fullWidth>
                                            <TextField
                                                label="PAN"
                                                margin="dense"
                                                id="outlined-pan"
                                                required
                                                InputProps={{
                                                    inputProps: {
                                                        maxLength: 10,
                                                        style: {
                                                            textTransform: 'uppercase'
                                                        }
                                                    },
                                                    endAdornment: <Button color="primary" onClick={fetchPANName}>Fetch</Button>,
                                                }}
                                                variant="outlined"
                                                inputRef={panRef}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        {
                                            panName ? (
                                                <div style={{ padding: 7, border: '1px dashed grey', borderRadius: 6, marginTop: 3 }}>
                                                    < Typography style={{ color: 'black' }}>PAN Name: {panName}</Typography>
                                                </div>
                                            ) : (null)
                                        }
                                    </Grid>

                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xl={8} lg={8} xs={12}>
                            <Paper style={{ padding: 16, textAlign: 'start' }}>
                                <Grid container spacing={1} alignItems="center" justify="space-between">
                                    <Grid item xs={12} md={3}>
                                        <Typography variant="subtitle1" component="span">Aadhaar PAN Linked</Typography>
                                        <Typography variant="caption" component="div">Status of aadhaar pan link is fetched</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormControl variant="outlined" fullWidth>
                                            <TextField
                                                label="PAN"
                                                margin="dense"
                                                id="outlined-pan"
                                                required
                                                InputProps={{
                                                    inputProps: {
                                                        maxLength: 10,
                                                        style: {
                                                            textTransform: 'uppercase'
                                                        }
                                                    }
                                                }}
                                                variant="outlined"
                                                inputRef={panARef}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <FormControl variant="outlined" fullWidth>
                                            <TextField
                                                label="AADHAAR"
                                                margin="dense"
                                                id="outlined-pan"
                                                required
                                                InputProps={{
                                                    inputProps: {
                                                        maxLength: 12,
                                                        style: {
                                                            textTransform: 'uppercase'
                                                        }
                                                    }
                                                }}
                                                variant="outlined"
                                                inputRef={aadhaarRef}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Button color="primary" onClick={fetchAadhaarPANLinked}>Fetch</Button>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1} alignItems="center" justify="space-between">
                                    <Grid item xs={12} md={12}>
                                        {
                                            linkMessage && linkStatus ? (
                                                <div style={{ padding: 8, border: '1px dashed grey', borderRadius: 6 }}>
                                                    < Typography style={{ color: 'black' }}>Status: {linkStatus}</Typography>
                                                    < Typography style={{ color: 'black' }}>Message: {linkMessage}</Typography>
                                                </div>
                                            ) : (null)
                                        }
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xl={8} lg={8} xs={12}>
                            <DataInconsistencyCheck />
                        </Grid>
                        <Grid item xl={8} lg={8} xs={12}>
                            <Paper style={{ padding: 16, textAlign: 'start' }}>
                                {
                                    links && links.length > 0 ? (
                                        <ul>
                                            {
                                                links.map((link, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <Typography variant="subtitle1" component="span">{link.label}</Typography><OpenInNewIcon className={classes.link} fontSize="small" onClick={() => openLink(link.url)} />
                                                            <Typography variant="caption" component="div">{link.description}</Typography>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    ) : null
                                }
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            </div >
        </div >
    )
}

export default ImportantLinks;
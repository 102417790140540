import { blue, green, red } from '@material-ui/core/colors';
import { Button, withStyles } from '@material-ui/core';

const BlueButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(blue[500]),
      backgroundColor: blue[500],
      '&:hover': {
        backgroundColor: blue[700],
      },
    },
}))(Button);

export default BlueButton;

export const RedButton = withStyles((theme) => ({
    root: {
      color: red[900],
      //backgroundColor: red[500],
      '&:hover': {
        backgroundColor: red[300],
        color: theme.palette.getContrastText(red[300]),
      },
    },
}))(Button);

export const GreenButton = withStyles((theme) => ({
    root: {
      color: green[900],
    //   backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[300],
        color: theme.palette.getContrastText(green[300]),
      },
    },
}))(Button);
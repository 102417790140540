import { Button, Divider, Grid, Icon, IconButton, makeStyles, Paper, Table, TableBody, TablePagination, TableRow, Tooltip, Typography } from "@material-ui/core";
import { blue, red } from "@material-ui/core/colors";
import moment from "moment";
import React, { useContext } from "react";
import { AppContext } from "../AppContextProvider";
import EnhancedTableHead from "../components/EnhancedTableHead";
import SearchBar from "../components/SearchBar";
import SingleDate from "../components/SingleDate";
import { RowCountOptions } from "../Config";
import { TABLE_COUNTS } from "../Constants";
import { MasterService } from "../Services/MasterService";
import { format, formatNumber, format_fancy, getTimeInIST, StyledTableCell, toInt, BlueTick } from "../Utils";

const useStyles = makeStyles(theme => ({
    section: {
        width: '100%',
        height: '100%',
        marginTop: theme.spacing(1),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    qtyField: {
        width: "40%",
        position: "relative",
        [theme.breakpoints.up('md')]: {
            right: '100px',
            width: "10%",
            marginRight: 10
        },
        right: "22px"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom: '20px',
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px'
    },
    icon: {
        padding: '0px',
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
    },
    tablecell: {
        fontSize: '16pt',
        padding: theme.spacing(4)
    },
    container: {
        paddingTop: theme.spacing(1)
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    blueAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: blue[500],
    },
    instructions: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: 0,
        // height: theme.spacing(7),
        // float: 'right',
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            // float: 'inherit',
            marginLeft: theme.spacing(1)
        },
        marginRight: theme.spacing(4),

    },
    tableWrapper: {
        maxHeight: 407,
        overflow: 'auto',
    },
    list: {
        width: '100%',
        height: 250,
        overflow: "auto"
    },
    summary: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    title: {
        fontSize: 18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subtitle: {
        fontSize: 18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    selectRoot: {
        fontSize: 12,
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            marginRight: 0
        },
    },
    autoComplete: {
        // zIndex: 3,
        // [theme.breakpoints.up('md')]: {
        //     marginLeft: theme.spacing(1),
        // },
        marginTop: '0px !important',
    },
}));

export default function PennyDropLogsPage(props) {
    const { setTitle, setLinearLoading, showProgressDialog, closeProgressDialog, showSnackBar } = useContext(AppContext);
    const classes = useStyles();
    const [startdate, setStartDate] = React.useState(moment().add(-1, "month").startOf('day'));
    const [enddate, setEndDate] = React.useState(moment().endOf('day'));
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('created_date');
    const [currentRows, setCurrentRows] = React.useState([]);
    const [generate, setGenerate] = React.useState(true);
    const [filter, setFilter] = React.useState('');
    const [showProgress, setShowProgress] = React.useState(true);

    const columns = [
        { id: 'sl_no', align: 'left', numeric: false, disablePadding: false, label: 'Sl.No.', sortable: false },
        { id: 'pennydrop_id', align: 'left', numeric: false, disablePadding: true, label: 'Pennydrop ID', sortable: true },
        { id: 'account_number', align: 'left', numeric: false, disablePadding: true, label: 'Account Number', sortable: true },
        { id: 'name_with_bank', align: 'left', numeric: false, disablePadding: true, label: 'Name with Bank', sortable: true },
        { id: 'ifsc', align: 'left', numeric: false, disablePadding: true, label: 'IFSC', sortable: true },
        { id: 'amount', align: 'right', numeric: false, disablePadding: true, label: 'Amount', sortable: true },
        { id: 'verified', align: 'center', numeric: false, disablePadding: true, label: 'Verified', sortable: true },
        { id: 'verified_at', align: 'left', numeric: false, disablePadding: true, label: 'Verified on', sortable: true },
        { id: 'created_date', align: 'left', numeric: false, disablePadding: true, label: 'Requested', sortable: false },
        { id: 'modified_date', align: 'left', numeric: false, disablePadding: true, label: 'Last Modified', sortable: false },
    ];

    //Pagination
    const [totalItems, setTotalItems] = React.useState(0);
    const [numItemsPerPage, setNumItemsPerPage] = React.useState(localStorage.getItem(TABLE_COUNTS.pennydrop_tab_count) ? toInt(localStorage.getItem(TABLE_COUNTS.pennydrop_tab_count)) : RowCountOptions[0]);
    const [currentPageNumber, setCurrentPageNumber] = React.useState(0);
    const [fetchPage, setFetchPage] = React.useState(false);

    const handleChangeRowsPerPage = event => {
        setNumItemsPerPage(event.target.value);
        setCurrentPageNumber(0);
        setFetchPage(true);
        localStorage.setItem(TABLE_COUNTS.pennydrop_tab_count, event.target.value);
    }

    const handleChangePage = (event, page) => {
        if (currentPageNumber > page) {
            fetchRow(currentPageNumber - 1);
        } else {
            fetchRow(currentPageNumber + 1);
        }
    };

    const fetchRow = (newCurrentPage) => {
        setCurrentPageNumber(newCurrentPage);
        setFetchPage(true);
    }

    React.useEffect(() => {
        setTitle("View Pennydrop Transactions");
    }, [true]);

    React.useEffect(() => {
        if (generate) {
            setCurrentRows([]);
            setLinearLoading(true);
            setCurrentPageNumber(0);
            setTotalItems(0);
            if (showProgress) {
                showProgressDialog();
            }
            let fc = filter && filter.length > 0 ? filter : '';
            MasterService.getPennydropTxnCount(fc, moment(startdate).startOf('d').utc().format(format), moment(enddate).endOf('d').utc().format(format))
                .then(data => {
                    console.log(data);
                    setTotalItems(toInt(data.count));
                    if (toInt(data.count) === 0) {
                        setCurrentRows([]);
                        closeProgressDialog();
                    } else {
                        setFetchPage(true);
                    }
                    setLinearLoading(false);
                })
                .catch(error => {
                    console.log(error);
                    setLinearLoading(false);
                });
        }
        setGenerate(false);
    }, [generate]);

    React.useEffect(() => {
        let rows = [];
        if (fetchPage) {
            setLinearLoading(true);
            let fc = filter && filter.length > 0 ? filter : '';
            MasterService.getPennydropTxn(fc, moment(startdate).startOf('d').utc().format(format), moment(enddate).endOf('d').utc().format(format), currentPageNumber * numItemsPerPage, numItemsPerPage, orderBy, order)
                .then((data) => {
                    console.log(data);
                    if (data && data.logs) {
                        data.logs.forEach((element, index) => {
                            rows.push(
                                <TableRow
                                    key={index}
                                    hover
                                    style={{ cursor: 'pointer' }}>
                                    <StyledTableCell scope="row" align="center" padding='none' style={{ width: 130 }}>
                                        {index + 1}
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="left" padding='none'>
                                        {element.pennydrop_id || '-'}
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="left" padding='none'>
                                        {element.account_number || '-'}
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="left" padding='none'>
                                        {element.name_with_bank || '-'}
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="left" padding='none'>
                                        {element.ifsc || '-'}
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="right" padding='none'>
                                        {element.amount ? formatNumber(element.amount) : '-'}
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="center" padding='none'>
                                        {element.verified ? <BlueTick fontSize="small" /> : ""}
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="left" padding='none'>
                                        {element.verified_at ? moment(element.verified_at, format).format(format_fancy) : "-"}
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="left" padding='none'>
                                        {element.created_date ? getTimeInIST(element.created_date).format(format_fancy) : "-"}
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="left" padding='none'>
                                        {element.modified_date ? getTimeInIST(element.modified_date).format(format_fancy) : "-"}
                                    </StyledTableCell>
                                </TableRow>
                            );
                        });
                    }
                    setCurrentRows(rows);
                    setLinearLoading(false);
                })
                .catch(error => {
                    console.log(error);
                    setLinearLoading(false);
                })
                .finally(() => {
                    if (showProgress) {
                        closeProgressDialog();
                        setShowProgress(true)
                    }
                });
        }
        setFetchPage(false);
    }, [fetchPage]);

    const sortBy = (event, property) => {
        let o = 'desc';
        if (orderBy === property && order === 'desc') {
            o = 'asc';
        }
        setOrder(o);
        setOrderBy(property);
        setGenerate(true);
    }

    const exportReport = () => {
        showProgressDialog('Exporting...');
        let fc = filter && filter.length > 0 ? filter : '';
        MasterService.getPennydropTxnExport(fc, moment(startdate).startOf('d').utc().format(format), moment(enddate).endOf('d').utc().format(format), 0, totalItems, orderBy, order)
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Pennydrop Transactions Report.xlsx');
                // 3. Append to html page
                document.body.appendChild(link);
                // 4. Force download
                link.click();
                // 5. Clean up and remove the link
                link.parentNode.removeChild(link);
            })
            .catch(error => {
                console.log(error);
                showSnackBar('Failed to Export. Please try again', 'error')
            })
            .finally(() => {
                closeProgressDialog();
            });
    }

    return (
        <div className="Home">
            <div className="lander">
                <Grid container direction="row" spacing={1} justify="center">
                    <Grid item xl={10} lg={12} xs={12}>
                        <Paper className={classes.section}>
                            <Grid container spacing={1} alignItems="center" justify="space-between">
                                <Grid item container xs={12} md={8} spacing={1} alignItems="center">
                                    <Grid item>
                                        <div style={{ maxWidth: 200, paddingLeft: 8 }}>
                                            <SingleDate
                                                label="From"
                                                date={startdate}
                                                margin="dense"
                                                maxDate={moment()}
                                                setDate={(date) => {
                                                    setStartDate(date);
                                                }} />
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <div style={{ maxWidth: 200, marginLeft: 4 }}>
                                            <SingleDate
                                                label="To"
                                                date={enddate}
                                                margin="dense"
                                                minDate={startdate}
                                                maxDate={moment()}
                                                setDate={(date) => {
                                                    setEndDate(date);
                                                }} />
                                        </div>
                                    </Grid>
                                    <Grid item lg={1}>
                                        <Button variant="contained" className={classes.button} color="primary" onClick={() => { !generate && setGenerate(true) }}>
                                            Search
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} md={4} spacing={1} alignItems="center" justify="flex-end">
                                    <Grid item xs={7} md={2} lg={2} xl={3}>
                                        {
                                            (
                                                <div style={{ float: 'right', marginLeft: 8, marginRight: 8 }}>
                                                    <SearchBar
                                                        hint="Search..."
                                                        searchText={filter}
                                                        size="small"
                                                        onTextChange={(e) => {
                                                            setShowProgress(false);
                                                            setFilter(e.target.value);
                                                            setGenerate(true);
                                                        }
                                                        } />
                                                </div>
                                            )
                                        }
                                    </Grid>
                                    <Grid item>
                                        <Tooltip arrow title={totalItems === 0 ? "" : "Export"}>
                                            <IconButton size="medium" disabled={totalItems === 0} aria-label="Refresh" color="inherit" onClick={exportReport}>
                                                <Icon>save_alt</Icon>
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <Divider style={{ marginBottom: 0, marginTop: 10 }} />
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <div style={{ minHeight: 100, overflowY: 'auto', display: 'block' }}>
                                        <Table className={classes.table} stickyHeader>
                                            <EnhancedTableHead
                                                order={order}
                                                orderBy={orderBy}
                                                onRequestSort={sortBy}
                                                rows={columns} />
                                            <TableBody>
                                                {currentRows}
                                            </TableBody>
                                        </Table>
                                    </div>
                                    {
                                        totalItems === 0 &&
                                        <Typography color="error" variant="subtitle2">No Logs</Typography>
                                    }
                                    <TablePagination
                                        rowsPerPageOptions={RowCountOptions}
                                        component="div"
                                        count={totalItems}
                                        rowsPerPage={numItemsPerPage}
                                        page={currentPageNumber}
                                        backIconButtonProps={{
                                            'aria-label': 'Previous Page',
                                        }}
                                        nextIconButtonProps={{
                                            'aria-label': 'Next Page',
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        classes={{ selectRoot: classes.selectRoot }} />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}
import React, { useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import { Grid, TextField } from '@material-ui/core';
import ItemSelection from './ItemSelection';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import { isEmpty } from 'lodash';

const useStyles = makeStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
	sectionHeading: {
		fontSize: theme.typography.pxToRem(16),
		fontWeight: theme.typography.fontWeightMedium,
	},
	heading: {
		fontSize: theme.typography.pxToRem(14),
		fontWeight: theme.typography.fontWeightMedium,
	},
	subheading: {
		fontSize: theme.typography.pxToRem(14),
		fontWeight: theme.typography.fontWeightRegular,
		textAlign: "left"
	},
	dialogContent: {
		paddingTop: 0
	},
	formControl: {
		marginLeft: theme.spacing(2),
		marginTop: theme.spacing(1),
	}
}));

const DialogTitle = (props => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6" style={{ paddingRight: 24 }}>{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles(theme => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(1),
	},
}))(MuiDialogActions);

export default function MarkApprovedDialog(props) {
	const { options, required, type, documents } = props;
	const [dropdodwnOptions, setDropdownOptions] = React.useState([]);
	const [selectedOptions, setSelectedOptions] = React.useState([]);
	const [open, setOpen] = React.useState(false);
	const [title, setTitle] = React.useState(null);
	const [error, setError] = React.useState(false);
	const [errorMessage, setErrorMessage] = React.useState(undefined);
	const [documentList, setDocumentList] = React.useState([]);
	const classes = useStyles();
	const commentsRef = useRef()

	React.useEffect(() => {
		if (props.open) {
			setErrorMessage(undefined)
			setError(false)
			setOpen(props.open);
			setTitle(props.title);
			if (options && options.length > 0) {
				setDropdownOptions(options.map(o => ({ name: o.label, value: o.value })))
			}
			if (type === 'reject' && documents && documents.length > 0) {
				setDocumentList(documents.map(d => ({ id: d.document_id, label: d.document_label, rejected: false })))
			}
		} else {
			setOpen(props.open);
		}
	}, [props.open]);

	const handleClose = () => {
		props.close();
		setTitle(null);
		setSelectedOptions([])
		setOpen(false);
		setError(false)
		setErrorMessage(undefined)
	};

	const handleSave = () => {
		if (required && isEmpty(selectedOptions)) {
			setError(true)
			setErrorMessage('Select atleast one Remark')
		} else {
			const remarks = selectedOptions.map(option => option.value).join(',')
			const comments = commentsRef.current.value || null
			props.save(!isEmpty(selectedOptions) ? remarks : null, documentList.filter(d => d.rejected).map(d => d.id), comments)
		}
	}

	const handleChange = e => {
		let d = documentList.find(d => d.id === e.target.name);
		if (d) {
			d.rejected = e.target.checked
			setDocumentList(documentList)
		}
	}

	return (
		<Dialog fullWidth={props.fullWidth !== undefined ? props.fullWidth : true} maxWidth={props.maxWidth !== undefined ? props.maxWidth : "lg"} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
			<DialogTitle id="customized-dialog-title" onClose={handleClose} classes={classes}>
				<Typography variant="h6" component="div">
					{title}
				</Typography>
			</DialogTitle>
			<DialogContent dividers style={{ paddingTop: 0 }}>
				<Grid container spacing={1}>
					<Grid item xs={12} lg={9}>
						{
							dropdodwnOptions && dropdodwnOptions.length > 0 && (
								<ItemSelection
									required={required || false}
									label='Remarks'
									error={error}
									helperText={errorMessage || undefined}
									value={selectedOptions || null}
									multiple
									disableCloseOnSelect
									optionLabel='value'
									options={dropdodwnOptions}
									renderOption={(option, { selected }) => (
										<li {...props}>
											<Checkbox
												checked={selected}
											/>
											{option.value}
										</li>
									)}
									style={{ zIndex: 500, marginTop: 0, marginLeft: 0 }}
									formClass={classes.formAutoClass}
									selected={(options) => {
										setSelectedOptions(options);
									}} />
							)
						}
					</Grid>
					<Grid item xs={12} lg={9}>
						<TextField
							id="remarks"
							label="Other Remarks/Comments"
							multiline
							rows={2}
							variant='outlined'
							fullWidth
							inputRef={commentsRef}
							style={{ marginLeft: 8 }}
						/>
					</Grid>
					<Grid item xs={12} lg={9}>
						{
							type === 'reject' && documentList && documentList.length > 0 && (
								<FormControl component="fieldset" className={classes.formControl}>
									<FormLabel component="legend">Select Proofs to be Rejected</FormLabel>
									<FormGroup>
										{
											documentList.map((d, i) => (
												<FormControlLabel
													key={i}
													control={<Checkbox defaultChecked={d.rejected} onChange={handleChange} name={d.id} />}
													label={d.label}
												/>
											))
										}
									</FormGroup>
									<FormHelperText>Lead will be requested to Re-Upload the proofs rejected</FormHelperText>
								</FormControl>
							)
						}
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button autoFocus onClick={handleClose} color="primary">
					Cancel
				</Button>
				<Button autoFocus onClick={handleSave} color="primary">
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
}

MarkApprovedDialog.prototype = {
	open: PropTypes.bool.isRequired,
	title: PropTypes.string.isRequired,
	close: PropTypes.func.isRequired,
	save: PropTypes.func.isRequired,
	showAmount: PropTypes.bool.isRequired,
	mode: PropTypes.string.isRequired,
	minAmount: PropTypes.number.isRequired,
	bankLedgers: PropTypes.array,
	currentDisbursementLedger: PropTypes.object.isRequired,
	maxWidth: PropTypes.any,
	fullWidth: PropTypes.bool
};
import { FormControl, FormControlLabel, Grid, Hidden, makeStyles, Switch } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { AppContext } from '../AppContextProvider';
import ItemSelection from '../components/ItemSelection';
import SingleDate from '../components/SingleDate';
import { MasterService } from '../Services/MasterService';
import { format, getTimeInIST } from '../Utils';

const useStyles = makeStyles((theme) => ({
    action: {
        marginTop: 0
    },
    rootCard: {
        padding: '8px 0px'
    },
    root: {
        // minHeight: 90,
        // '& .MuiTextField-root': {
        //     margin: theme.spacing(1),
        // },
    },
}));

const TYPES = [
    {
        label: 'Percentage',
        value: 'percentage'
    },
    {
        label: 'Flat',
        value: 'flat'
    }
]

export default function AddCoupon(props) {
    const { showProgressDialog, showSnackBar, closeProgressDialog, showConfirmDialog, closeConfirmDialog } = useContext(AppContext);
    const { open, option, onSuccess, onError, onCancel } = props;
    const classes = useStyles();
    const [newOption, setNewOption] = React.useState({ type: TYPES[0].value, use_count: false, start_date: moment().startOf('day'), end_date: moment().add(1, "M").endOf('day'), maximum_discount: 0 });
    const [error, setError] = React.useState({
        coupon_code: false,
        value: false,
        description: false,
        type: false,
        maximum_discount: false,
        max_count: false,
        start_date: false,
        end_date: false,
    });

    React.useEffect(() => {
        if (open) {
            if (option) {
                option.start_date = option.start_date ? getTimeInIST(option.start_date) : null
                option.end_date = option.end_date ? getTimeInIST(option.end_date) : null
                setNewOption(option);
            }
        }
    }, [open]);

    const handleChange = event => {
        event.persist()
        if (event.target.type === 'checkbox') {
            setNewOption(p => ({ ...p, [event.target.name]: event.target.checked }))
        } else if (event.target.name === 'coupon_code') {
            setNewOption(p => ({ ...p, coupon_code: event.target.value ? event.target.value.replace(/ +/g, "") : "" }))
        } else {
            setNewOption(p => ({ ...p, [event.target.name]: event.target.value }))
        }
    };

    const validate = () => {
        let isValid = true;
        if (newOption.coupon_code.length === 0) {
            isValid = false;
        }
        setError(e => ({ ...e, coupon_code: newOption.coupon_code.length === 0 }))
        if (newOption.value.length === 0) {
            isValid = false;
        }
        setError(e => ({ ...e, value: newOption.value.length === 0 }))
        if (!newOption.start_date) {
            isValid = false;
        }
        setError(e => ({ ...e, start_date: !newOption.start_date }))
        if (!newOption.end_date) {
            isValid = false;
        }
        setError(e => ({ ...e, end_date: !newOption.end_date }))

        return isValid;
    }

    const save = () => {
        if (validate()) {
            showConfirmDialog('Confirm Save?', undefined, () => {
                closeConfirmDialog();
                showProgressDialog(`Saving Option...`);

                let body = {
                    id: newOption.coupon_id || null, // Not Mandatory
                    coupon_code: newOption.coupon_code || null,
                    value: newOption.value ? Number(newOption.value) : null,
                    description: newOption.description || null, //Can update
                    status: newOption.status ? Number(newOption.status) : 1, // Can update 1 => enabled, 0 => disabled,
                    type: newOption.type, //percentage, flat
                    maximum_discount: newOption.maximum_discount ? Number(newOption.maximum_discount) : 0,  //Not Mandatory
                    max_count: newOption.max_count ? Number(newOption.max_count) : 0, //can update
                    use_count: newOption.use_count || false,//can update
                    start_date: newOption.start_date ? moment(newOption.start_date).startOf('day').utc().format(format) : null, //Can update
                    end_date: newOption.end_date ? moment(newOption.end_date).endOf('day').utc().format(format) : null, //Can update
                }

                MasterService.updateCoupon(body)
                    .then((data) => {
                        if (data.success) {
                            showSnackBar('Coupon Saved', "info");
                            resetForm();
                            onSuccess();
                            closeProgressDialog();
                        } else {
                            showSnackBar('Something went wrong. Please try again.', "error");
                            closeProgressDialog();
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        onError();
                        showSnackBar('Something went wrong. Please try again later', "error");
                        closeProgressDialog();
                    })
            })
        }
    }

    const handleClose = () => {
        resetForm();
        onCancel();
    }

    const resetForm = () => {
        setError({
            coupon_code: false,
            value: false,
            description: false,
            type: false,
            maximum_discount: false,
            max_count: false,
            start_date: false,
            end_date: false,
        })
        setNewOption({ type: TYPES[0].value, use_count: false, start_date: moment(), end_date: moment(), maximum_discount: 0 });
    }

    return (
        <Dialog fullWidth onClose={handleClose} maxWidth="sm" open={open} aria-labelledby="form-dialog-title">
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth className={classes.root}>
                            <TextField
                                autoFocus
                                label='Coupon Code'
                                name="coupon_code"
                                defaultValue={newOption ? newOption.coupon_code : null}
                                type="text"
                                required
                                margin="dense"
                                fullWidth
                                disabled={newOption.coupon_id ? true : false}
                                helperText={`${(newOption && newOption.coupon_code ? newOption.coupon_code.length : 0)} / 30`}
                                error={error.coupon_code}
                                variant="outlined"
                                onChange={handleChange}
                                InputProps={{
                                    inputProps: { min: 1, maxLength: 30 }
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth className={classes.root}>
                            <TextField
                                label='Description'
                                type="text"
                                name="description"
                                margin="dense"
                                defaultValue={newOption ? newOption.description : null}
                                fullWidth
                                error={error.description}
                                variant="outlined"
                                onChange={handleChange}
                                helperText={`${(newOption && newOption.description ? newOption.description.length : 0)} / 150`}
                                InputProps={{
                                    inputProps: { min: 0, maxLength: 150 }
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ItemSelection
                            label='Type'
                            value={TYPES.find(t => t.value === newOption.type)}
                            optionLabel='label'
                            required={true}
                            disabled={newOption.coupon_id ? true : false}
                            autoCompleteClass={classes.autoComplete}
                            options={TYPES}
                            style={{ zIndex: 500, marginTop: 0, marginRight: 10, marginLeft: -8 }}
                            selected={(s) => {
                                setNewOption(o => ({ ...o, type: s.value }));
                            }} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth className={classes.root}>
                            <TextField
                                label='Value'
                                type="number"
                                required
                                name="value"
                                margin="dense"
                                disabled={newOption.coupon_id ? true : false}
                                defaultValue={newOption ? newOption.value : null}
                                fullWidth
                                error={error.value}
                                variant="outlined"
                                onChange={handleChange}
                                InputProps={{
                                    inputProps: { min: 0, max: 100 }
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth className={classes.root}>
                            <TextField
                                label='Maximum Discount'
                                type="number"
                                margin="dense"
                                name="maximum_discount"
                                disabled={newOption.coupon_id ? true : false}
                                defaultValue={newOption ? newOption.maximum_discount : 0}
                                fullWidth
                                error={error.maximum_discount}
                                variant="outlined"
                                onChange={handleChange}
                                InputProps={{
                                    inputProps: { min: 0 }
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Hidden xsDown>
                        <Grid item md={6} />
                    </Hidden>
                    <Grid item xs={12} md={6}>
                        <FormControlLabel
                            style={{ marginTop: -2, minHeight: 58 }}
                            control={
                                <Switch
                                    checked={newOption ? newOption.use_count : false}
                                    onChange={handleChange}
                                    name="use_count"
                                    color="primary"
                                />
                            }
                            label="Enable Count Limitation"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {
                            newOption.use_count && (
                                <FormControl fullWidth className={classes.root}>
                                    <TextField
                                        label='Remaining Count'
                                        type="number"
                                        margin="dense"
                                        name="max_count"
                                        defaultValue={newOption ? newOption.max_count : 0}
                                        fullWidth
                                        error={error.max_count}
                                        variant="outlined"
                                        onChange={handleChange}
                                        InputProps={{
                                            inputProps: { min: 0 }
                                        }}
                                    />
                                </FormControl>
                            )
                        }
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <SingleDate
                            label="Start Date"
                            date={newOption ? moment(newOption.start_date) : moment()}
                            margin="dense"
                            maxDate={moment(newOption.end_date) || moment()}
                            setDate={(date) => {
                                setNewOption(o => ({ ...o, start_date: date }));
                            }} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <SingleDate
                            label="End Date"
                            date={newOption ? newOption.end_date : moment()}
                            margin="dense"
                            minDate={newOption.start_date || moment()}
                            setDate={(date) => {
                                setNewOption(o => ({ ...o, end_date: date }));
                            }} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={save} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

AddCoupon.propTypes = {
    open: PropTypes.bool.isRequired,
    placeholder: PropTypes.string,
    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    option: PropTypes.object
};
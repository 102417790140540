import React from "react";
import { Popover } from "react-bootstrap";
import moment from "moment";
import "moment-timezone";
import { TableCell, makeStyles, withStyles, Tooltip, Chip } from "@material-ui/core";
import { toWords } from 'number-to-words';
import Config from "./Config";
import { COUPON_STATUS_MAP, GENDER, STATUS_MAP, STATUS_MAP_CLOSURE, USERTYPES } from "./Constants";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DotIcon from '@material-ui/icons/Brightness1';
import CancelIcon from '@material-ui/icons/Cancel';
import HelpIcon from '@material-ui/icons/HelpOutline';
import { green, grey, red, yellow } from "@material-ui/core/colors";
import path from "./path";
import { NotInterested, Spellcheck } from "@material-ui/icons";

const formatter = new Intl.NumberFormat('en-IN');

export function randomHex() {
  let letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function popover(text) {
  return (
    <Popover id="popover-top">
      <strong>{text}</strong>
    </Popover>
  );
}

export const format = "YYYY-MM-DD HH:mm:ss";
export const format_export = "DD-MM-YYYY HH:mm:ss";
export const format_fancy = "Do MMM YYYY HH:mm:ss";
export const format_display_withtime = "Do MMM YYYY HH:mm:ss a";
export const format_without_time = "YYYY-MM-DD";
export const format_display = "DD/MM/YYYY";
// export const format_export = "DD/MM/YY";
export const format_display_date = "Do MMM YYYY HH:mm";
export const format_display_fancy_notime = "Do MMM YYYY";
export const format_display_fancy_my = "MMM YYYY";
export const format_display_fancy_notime_withDay = "dddd, Do MMM";
export const format_display_fancy_notime_withDayAndyear = "dddd, Do MMM YYYY";

export function getTimeInIST(dateString) {
  const timeInUTC = moment.utc(dateString, format);
  return timeInUTC.clone().tz("Asia/Kolkata");
}

export function currentTimeInIST() {
  return moment.utc().clone().tz("Asia/Kolkata");
}

export function formatQty(number) {
  return formatter.format(number);
  // return parseFloat(number).toLocaleString('en-IN', {style: 'unit', minimumFractionDigits: 2, maximumFractionDigits: 2});
}

export function toInt(n) {
  return parseInt(n, 10);
}

export function formatNumber(number) {
  return parseFloat(number).toLocaleString("en-IN", {
    style: "currency",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency: "INR",
  });
}

export function formatNumberNoSym(number) {
  return parseFloat(number).toLocaleString("en-IN", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export function toPercentage(portion, total) {
  return parseFloat((portion / total) * 100).toFixed(1) + "%";
}

export function isInt(n) {
  return n % 1 === 0;
}

export const HtmlTooltip = withStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    maxWidth: 350,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: 14
  },
}));

export function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

export const StyledTableCell = withStyles((theme) => ({
  root: {
    paddingLeft: 16,
    paddingRight: 16,
    borderBottom: '1px solid rgba(238, 238, 238, 1)',
    fontSize: 14,
    height: 35,
  }
}))(TableCell);

export const generateID = () => {
  // // Math.random should be unique because of its seeding algorithm.
  // // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // // after the decimal.
  // return '_' + Math.random().toString(36).substr(2, 9);
  return new Date().getTime().toString();
};

export function titleCase(str) {
  if (!str || str.trim().length === 0) {
    return str
  }
  return str.toLowerCase().split(' ').map(function (word) {
    return word.replace(word[0], word[0].toUpperCase());
  }).join(' ');
}

export function precision(number) {
  return parseFloat(number.toFixed(2));
}

export function increaseby(figure = 0, percentage = 0) {
  return figure * (1 + percentage / 100);
}

export function numberToText(number) {
  return `${toWords(number).replace(/-/g, ' ')} only`;
}

export function calculateInterest(scheme, noOfDays, amount) {
  if (scheme) {
    let interval = scheme.interval_days;
    if (interval > 0) {
      noOfDays = Math.ceil(noOfDays / interval) * interval;
    }
    let interest = getInterestPercentage(scheme, noOfDays);
    return amount * (noOfDays / 360) * (interest / 100)
  }
  return 0;
}

export function getInterestPercentage(scheme, noOfDays) {
  let interest = 0;
  if (noOfDays <= 30) {
    interest = scheme.interest_1;
  } else if (noOfDays > 30 && noOfDays <= 270) {
    interest = scheme.interest_2;
  } else if (noOfDays > 270) {
    interest = scheme.interest_3;
  }
  return interest;
}

export function openMap(location) {
  window.open(Config.google_url + location, "_blank");
}

export function openLink(link) {
  window.open(link, "_blank")
}

export function getGender(gender) {
  switch (gender) {
    case "F": return GENDER.F;
    case "M": return GENDER.M;
    default: return GENDER.O;
  }
}

export function getStatusBadge(status) {
  let color = 'white';
  let textColor = 'white';
  let text = 'Unknown';
  let s = STATUS_MAP.find(s => s.value === status);
  if (s) {
    color = s.color;
    text = s.label
    textColor = s.textColor || textColor
  }
  return <Chip size="small" label={text} style={{ backgroundColor: color, color: textColor }} />;
}

export function getStatusBadgeClosure(status) {
  let color = 'white';
  let textColor = 'white';
  let text = 'Unknown';
  let s = STATUS_MAP_CLOSURE.find(s => s.value === status);
  if (s) {
    color = s.color;
    text = s.label
    textColor = s.textColor || textColor
  }
  return <Chip size="small" label={text} style={{ backgroundColor: color, color: textColor }} />;
}

export function getCouponStatusBadge(status) {
  if (status === undefined || status === null) return "-"
  let color = 'white';
  let textColor = 'white';
  let text = 'Unknown';
  let s = COUPON_STATUS_MAP.find(s => s.value === status);
  if (s) {
    color = s.color;
    text = s.label
    textColor = s.textColor || textColor
  }
  return <Chip size="small" label={text} style={{ backgroundColor: color, color: textColor }} />;
}

export const GreyDot = withStyles((theme) => ({
  root: {
    color: grey[300],
  },
}))(DotIcon);

export const GreenDot = withStyles((theme) => ({
  root: {
    color: green[300],
  },
}))(DotIcon);

export const YellowDot = withStyles((theme) => ({
  root: {
    color: yellow[300],
  },
}))(DotIcon);

export const RedDot = withStyles((theme) => ({
  root: {
    color: red[300],
  },
}))(DotIcon);

export const GreyHelpIcon = withStyles((theme) => ({
  root: {
    color: grey[300],
  },
}))(HelpIcon);


export const GreenTick = withStyles((theme) => ({
  root: {
    color: green[500],
  },
}))(CheckCircleIcon);

export const YellowTick = withStyles((theme) => ({
  root: {
    color: yellow[500],
  },
}))(CheckCircleIcon);

export const GreyNotInterested = withStyles((theme) => ({
  root: {
    color: grey[500],
  },
}))(NotInterested);

export const GreenSpellCheck = withStyles((theme) => ({
  root: {
    color: green[500],
  },
}))(Spellcheck);

export const BlueTick = withStyles((theme) => ({
  root: {
    color: theme.palette.secondary.dark,
  },
}))(CheckCircleIcon);

export const RedCancelIcon = withStyles((theme) => ({
  root: {
    color: red[500],
  },
}))(CancelIcon);

export function linkPreventCaching(link = undefined) {
  if (!link) {
    return undefined
  }
  return link.includes('?') ? `${link}&${(new Date()).getTime()}` : `${link}?${(new Date()).getTime()}`
}

export function diff(obj1, obj2) {

  // Make sure an object to compare is provided
  if (!obj2 || Object.prototype.toString.call(obj2) !== '[object Object]') {
    return obj1;
  }

  //
  // Variables
  //
  var diffs = {};
  var key;

  //
  // Methods
  //

  /**
   * Check if two arrays are equal
   * @param  {Array}   arr1 The first array
   * @param  {Array}   arr2 The second array
   * @return {Boolean}      If true, both arrays are equal
   */
  var arraysMatch = function (arr1, arr2) {

    // Check if the arrays are the same length
    if (arr1.length !== arr2.length) return false;

    // Check if all items exist and are in the same order
    for (var i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }

    // Otherwise, return true
    return true;

  };

  /**
     * Compare two items and push non-matches to object
     * @param  {*}      item1 The first item
     * @param  {*}      item2 The second item
     * @param  {String} key   The key in our object
     */
  var compare = function (item1, item2, key) {

    // Get the object type
    var type1 = Object.prototype.toString.call(item1);
    var type2 = Object.prototype.toString.call(item2);

    // If type2 is undefined it has been removed
    if (type2 === '[object Undefined]') {
      diffs[key] = null;
      return;
    }

    // If items are different types
    if (type1 !== type2) {
      diffs[key] = item2;
      return;
    }

    // If an object, compare recursively
    if (type1 === '[object Object]') {
      var objDiff = diff(item1, item2);
      if (Object.keys(objDiff).length > 0) {
        diffs[key] = objDiff;
      }
      return;
    }

    // If an array, compare
    if (type1 === '[object Array]') {
      if (!arraysMatch(item1, item2)) {
        diffs[key] = item2;
      }
      return;
    }

    // Else if it's a function, convert to a string and compare
    // Otherwise, just compare
    if (type1 === '[object Function]') {
      if (item1.toString() !== item2.toString()) {
        diffs[key] = item2;
      }
    } else {
      if (item1 !== item2) {
        diffs[key] = item2;
      }
    }

  };


  //
  // Compare our objects
  //

  // Loop through the first object
  for (key in obj1) {
    if (obj1.hasOwnProperty(key)) {
      compare(obj1[key], obj2[key], key);
    }
  }

  // Loop through the second object and find missing items
  for (key in obj2) {
    if (obj2.hasOwnProperty(key)) {
      if (!obj1[key] && obj1[key] !== obj2[key]) {
        diffs[key] = obj2[key];
      }
    }
  }

  // Return the object of differences
  return diffs;
}

export function colorGenerator(count = 1) {
  return ['#3333ff', '#CC0000', '#F38822', '#FFE4E1', '#FF00FF', '#800000', '#E5B8AD', '#DAA520', '#000000', '#FF0000', '#808000', '#00FF00', '#008000', '#00FFFF', '#0000FF', '#FF00FF', '#800080', '#0076CE', '#008080', '#04B000', '#0B0F70', '#275B4B', '#2F8FD6', '#466108', '#5B6E31', '#792C97', '#800080', '#972C95', '#A3C0C6', '#AE1D08', '#B3D9FF', '#B5BDC4', '#BBEEFF', '#BBFFEE', '#C0F762', '#C1D4CC', '#C6A9A3', '#C9DEEA', '#CCAC95', '#808080', '#E1FCFF', '#E1FFE4', '#C0C0C0', '#F797A5', '#FCC00F', '#AAAAAA', '#FFF3CE'].slice(0, count);
  // return randomColor({count, luminosity: 'bright'});
}

export function getRelativeTime(value = 0, unit) {
  let days = 0, remainder = 0, hours = 0, minutes = 0
  if (unit === 'hour') {
    days = Math.floor(value / 24);
    remainder = value % 24;
    hours = Math.floor(remainder);
    minutes = Math.floor(60 * (remainder - hours));
  } else if (unit === 'minute') {
    minutes = value;
  }
  let result = "";
  if (days > 0) {
    result = `${result} ${days} Days`
  }
  if (hours > 0) {
    result = `${result} ${hours} Hrs`
  }
  if (minutes > 0) {
    result = `${result} ${minutes} mins`
  }
  return result
}

export function getHighlightedText(text, highlight) {
  // Split on highlight term and include term into parts, ignore case
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
  return <span> {parts.map((part, i) =>
    <span key={i} style={highlight.toLowerCase().includes(part.toLowerCase()) ? { fontWeight: 'bold' } : {}}>
      {part}
    </span>)
  } </span>;
}

export function checkAuthorization(feature, role = "") {
  switch (feature) {
    case path.DASHBOARD:
      return [USERTYPES.KYC, USERTYPES.BRANCH, USERTYPES.MD, USERTYPES.KYCHO, USERTYPES.HO, USERTYPES.FRAN, USERTYPES.RM, USERTYPES.SALEHD, USERTYPES.STATEMG].includes(role);
    case path.LEADS:
      return [USERTYPES.KYC, USERTYPES.BRANCH, USERTYPES.MD, USERTYPES.KYCHO, USERTYPES.HO, USERTYPES.FRAN, USERTYPES.RM, USERTYPES.SALEHD, USERTYPES.STATEMG].includes(role);
    case path.REKYC_LEADS:
      return [USERTYPES.KYC, USERTYPES.BRANCH, USERTYPES.MD, USERTYPES.KYCHO, USERTYPES.HO, USERTYPES.FRAN, USERTYPES.RM, USERTYPES.SALEHD, USERTYPES.STATEMG].includes(role);
    case path.CLOSURE_LEADS:
      return [USERTYPES.KYC, USERTYPES.BRANCH, USERTYPES.MD, USERTYPES.KYCHO, USERTYPES.HO, USERTYPES.FRAN, USERTYPES.RM, USERTYPES.SALEHD, USERTYPES.STATEMG].includes(role);
    case path.VIEW_LEAD:
      return [USERTYPES.KYC, USERTYPES.BRANCH, USERTYPES.MD, USERTYPES.KYCHO, USERTYPES.HO, USERTYPES.FRAN, USERTYPES.RM, USERTYPES.SALEHD, USERTYPES.STATEMG].includes(role);
    case path.COUPON:
      return [USERTYPES.KYC].includes(role);
    case path.DOCUMENTS:
      return [USERTYPES.KYC].includes(role);
    case path.DROPDOWNS:
      return [USERTYPES.KYC].includes(role);
    case path.TERMS:
      return [USERTYPES.KYC].includes(role);
    case path.LOGS:
      return [USERTYPES.KYC].includes(role);
    case path.PENNY_DROP_LOGS:
      return [USERTYPES.KYC].includes(role);
    case path.UTILITY:
      return [USERTYPES.KYC].includes(role);
    case path.UPLOAD:
      return [USERTYPES.KYC, USERTYPES.KYCHO].includes(role);
    case path.REKYC_UPLOAD:
      return [USERTYPES.KYC, USERTYPES.KYCHO].includes(role);
    case path.JOB_LOGS:
      return [USERTYPES.KYC].includes(role);
    case path.IMP_LINKS:
      return true;
    case path.NOT_FOUND:
      return true
    default:
      return false;
  }
}

export const getFinancialYearDates = () => {
  return {
    startdate: moment().quarter() === 1 ? moment().add(-1, 'y').month('April').startOf('M').startOf('day') : moment().month('April').startOf('M').startOf('day'),
    enddate: moment().quarter() === 1 ? moment().month('March').endOf('M').endOf('day') : moment().add(1, 'y').month('March').endOf('M').endOf('day'),
  }
}
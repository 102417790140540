import React, { useContext, useEffect } from 'react'
import { AppContext } from '../AppContextProvider'

function ViewLead({match}) {

    const {openLead, closeLead} = useContext(AppContext);
    const id = match.params.id || null;

    useEffect(() => {
        if(id){
            openLead(id);
        }
        return () => {
            closeLead();
        }
    }, [id])

    return (
        <div>
            
        </div>
    )
}

export default ViewLead

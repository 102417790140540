import { Button, FormControl, Grid, Paper, TextField, Typography } from '@material-ui/core'
import React, { useContext, useRef, useState } from 'react'
import { AppContext } from '../AppContextProvider'
import { MasterService } from '../Services/MasterService'

const DataInconsistencyCheck = () => {
    const ref = useRef(null)
    const [description, setDescription] = useState('')
    const { showProgressDialog, showSnackBar, closeProgressDialog, showConfirmDialog1D, closeConfirmDialog1D } = useContext(AppContext);


    const fetchData = () => {
        if (ref.current.value) {
            showProgressDialog('Fetching...');
            MasterService.fetchDataInconsistency(ref.current.value)
                .then(data => {
                    console.log(data)
                    if (data.success) {
                        setDescription(data.missing_fields.join(', '))
                    } else {
                        setDescription('')
                        console.error(data.error)
                        showSnackBar(data.error, "error")
                    }
                })
                .catch(error => {
                    setDescription('')
                    console.error(error)
                    showSnackBar('Something went wrong. Please try again later.', 'error');
                })
                .finally(() => closeProgressDialog())
        } else {
            showConfirmDialog1D('Enter valid client code', undefined, () => {
                closeConfirmDialog1D();
            }, 'OK');
        }
    }

    return (
        <Paper style={{ padding: 16, textAlign: 'start' }}>
            <Grid container spacing={1} alignItems="center" justify="space-between">
                <Grid item xs={12} md={3}>
                    <Typography variant="subtitle1" component="span">Data inconsistency check</Typography>
                    <Typography variant="caption" component="div">Use this to check missing details in Back Office</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl variant="outlined" fullWidth>
                        <TextField
                            label="Client Code"
                            margin="dense"
                            id="outlined-pan"
                            required
                            InputProps={{
                                inputProps: {
                                    maxLength: 10,
                                    style: {
                                        textTransform: 'uppercase'
                                    }
                                },
                                endAdornment: <Button color="primary" onClick={fetchData}>Fetch</Button>,
                            }}
                            variant="outlined"
                            inputRef={ref}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                    {
                        description ? (
                            <div style={{ padding: 7, border: '1px dashed grey', borderRadius: 6, marginTop: 3 }}>
                                <Typography style={{ color: 'black' }}><b>Missing Fields:</b> {description}</Typography>
                            </div>
                        ) : (null)
                    }
                </Grid>

            </Grid>
        </Paper>
    )
}

export { DataInconsistencyCheck }
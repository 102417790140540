import React, { useContext, useRef, useState } from "react";
import "../css/home.css";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {
	Fab,
	Grid,
	Icon,
	IconButton,
	makeStyles,
	Typography,
} from '@material-ui/core';
import { AppContext } from "../AppContextProvider";
import CardViewBlock from "../components/CardViewBlock";
import CardViewLoader from "../components/CardViewLoader";
import { BootstrapTooltip, format, formatQty } from "../Utils";
import AddIcon from '@material-ui/icons/Add';
import LinkIcon from '@material-ui/icons/Link';
import VisibilityIcon from '@material-ui/icons/Visibility';
import moment from "moment";
import DashboardPopover from "./DashboardPopover";
import Config, { ACCOUNT_CLOSURE_MODULE, ADD_LEAD_MODULE, REFERRAL_MODULE } from "../Config";
import { MasterService } from "../Services/MasterService";
import { DashboardContext } from "../DashboardContext";
import EmptyBlock from "./EmptyBlock";
import { LeadService } from "../Services/LeadService";
import { STATUS, STATUS_MAP, USERTYPES } from "../Constants";
import TableStats from "./TableStats";
import DemographicStats from "./DemographicStats";
import TopRankingStates from "./TopRankingStates";
import TopRankingCities from "./TopRankingCities";
import AdminStats from "./AdminStats";
import StepwiseStats from "./StepwiseStats";
import TATStats from "./TATStats";
import AccountsClosed from "./AccountsClosed";
import ReasonForClosure from "./ReasonForClosure";
import ClosureStatus from "./ClosureStatus";

const useStyles = makeStyles(theme => ({
	fab: {
		margin: theme.spacing(1),
		position: 'fixed',
		bottom: theme.spacing(2),
		right: theme.spacing(2),
	},
}));

let counter = 1;

function Home(props) {
	const classes = useStyles();
	const {
		state,
		setTitle,
		// openNewLead,
		showProgressDialog,
		closeProgressDialog,
		showSnackBar,
		showConfirmDialog1D,
		closeConfirmDialog1D } = useContext(AppContext);
	const { reportBlocks, setReportBlocks } = useContext(DashboardContext);

	const dashboardConfigRef = useRef(null);
	const [showDashBoardConfig, setShowDashBoardConfig] = useState(false);
	const [lastRefreshed, setLastRefreshed] = useState(moment());
	const [lastRefreshedString, setLastRefreshedString] = useState('');
	const [showBlockLoader, setShowBlockLoader] = useState(false);
	const [intervalID, setIntervalID] = useState(null);
	const [timedIntervalID, setTimedIntervalID] = useState(null);
	const defaultRange = "last_30";

	// Stats
	const [todayNewLeads, setTodayNewLeads] = React.useState(0);
	const [todayEsignPending, setTodayEsignPending] = React.useState(0);
	const [todayEsignRequested, setTodayEsignRequested] = React.useState(0);
	const [todaySubmitted, setTodaySubmitted] = React.useState(0);
	const [todayApproved, setTodayApproved] = React.useState(0);
	const [todayRejected, setTodayRejected] = React.useState(0);
	const [todayActivated, setTodayActivated] = React.useState(0);
	const [todayTotal, setTodayTotal] = React.useState(0);
	const [weekNewLeads, setWeekNewLeads] = React.useState(0);
	const [weekEsignPending, setWeekEsignPending] = React.useState(0);
	const [weekEsignRequested, setWeekEsignRequested] = React.useState(0);
	const [weekSubmitted, setWeekSubmitted] = React.useState(0);
	const [weekApproved, setWeekApproved] = React.useState(0);
	const [weekRejected, setWeekRejected] = React.useState(0);
	const [weekActivated, setWeekActivated] = React.useState(0);
	const [weekTotal, setWeekTotal] = React.useState(0);

	React.useEffect(() => {
		if (state.isAuthenticated) {
			fetchDashboard();
			setTitle('Dashboard');
			refresh();
			timedEvents();
			setIntervalID(setInterval(refresh, Config.REFRESH_INTERVAL));
			setTimedIntervalID(setInterval(timedEvents, Config.SHORT_REFRESH_INTERVAL));
		}
		return () => {
			clearInterval(intervalID);
			clearInterval(timedIntervalID);
			setIntervalID(null);
			setTimedIntervalID(null);
		}
	}, [state.isAuthenticated, true]);

	const handleClick = () => {
		// openNewLead(null, () => {
		// 	counter++;
		// });
		showConfirmDialog1D('Work in progress. Coming Soon', undefined, () => {
			closeConfirmDialog1D();
		}, 'OK')
	}

	const copyReferral = () => {
		showProgressDialog("Creating Referral Link...")
		let type = undefined
		let empType = state.user && state.user.EmpUtype
		if (empType === USERTYPES.FRAN) {
			type = 'franchisee'
		} else if (empType === USERTYPES.RM) {
			type = 'employee'
		} else if (empType === USERTYPES.BRANCH) {
			type = 'branch'
		}
		if (!type) {
			return
		}
		MasterService.createReferral({ referral_id: empType, type, status: 1 })
			.then((data) => {
				console.log(data)
				if (data.url) {
					if ('clipboard' in navigator) {
						return navigator.clipboard.writeText(data.url);
					} else {
						return document.execCommand('copy', true, data.url);
					}
				} else {
					return Promise.reject({ message: "failed to copy" })
				}
			})
			.then(() => {
				showSnackBar('Link Copied', 'success')
			})
			.catch(error => {
				console.error(error)
				showConfirmDialog1D('Something went wrong. Please try again.', undefined, () => {
					closeConfirmDialog1D();
				}, 'OK')
			})
			.finally(() => closeProgressDialog())
	}

	const timedEvents = () => {
		setLastRefreshedString(lastRefreshed.fromNow());
	}

	const refresh = () => {
		setShowBlockLoader(true);
		let referral_ids = null;
		if ([USERTYPES.FRAN, USERTYPES.RM, USERTYPES.BRANCH].includes(state.user.EmpUtype)) {
			referral_ids = state.user.EmpCode
		}
		Promise.all([
			LeadService.getLeadsStatusCount(STATUS_MAP.find(s => s.type === 'ongoing').value, moment().startOf('day').utc().format(format), moment().endOf('day').utc().format(format), referral_ids),
			LeadService.getLeadsStatusCount(STATUS_MAP.find(s => s.type === 'completed').value, moment().startOf('day').utc().format(format), moment().endOf('day').utc().format(format), referral_ids),
			LeadService.getLeadsStatusCount(STATUS_MAP.find(s => s.type === 'approved').value, moment().startOf('day').utc().format(format), moment().endOf('day').utc().format(format), referral_ids),
			LeadService.getLeadsStatusCount(STATUS_MAP.find(s => s.type === 'rejected').value, moment().startOf('day').utc().format(format), moment().endOf('day').utc().format(format), referral_ids),
			LeadService.getLeadsStatusCount(STATUS_MAP.find(s => s.type === 'ongoing').value, moment().startOf('week').utc().format(format), moment().endOf('week').utc().format(format), referral_ids),
			LeadService.getLeadsStatusCount(STATUS_MAP.find(s => s.type === 'completed').value, moment().startOf('week').utc().format(format), moment().endOf('week').utc().format(format), referral_ids),
			LeadService.getLeadsStatusCount(STATUS_MAP.find(s => s.type === 'approved').value, moment().startOf('week').utc().format(format), moment().endOf('week').utc().format(format), referral_ids),
			LeadService.getLeadsStatusCount(STATUS_MAP.find(s => s.type === 'rejected').value, moment().startOf('week').utc().format(format), moment().endOf('week').utc().format(format), referral_ids),
			LeadService.getLeadsStatusCount(null, moment().startOf('day').utc().format(format), moment().endOf('day').utc().format(format), referral_ids),
			LeadService.getLeadsStatusCount(null, moment().startOf('week').utc().format(format), moment().endOf('week').utc().format(format), referral_ids),
			LeadService.getLeadsStatusCount(STATUS.ESIGN_PENDING, moment().startOf('day').utc().format(format), moment().endOf('day').utc().format(format), referral_ids),
			LeadService.getLeadsStatusCount(STATUS.ESIGN_PENDING, moment().startOf('week').utc().format(format), moment().endOf('week').utc().format(format), referral_ids),
			LeadService.getLeadsStatusCount(STATUS.ESIGN_REQUESTED, moment().startOf('day').utc().format(format), moment().endOf('day').utc().format(format), referral_ids),
			LeadService.getLeadsStatusCount(STATUS.ESIGN_REQUESTED, moment().startOf('week').utc().format(format), moment().endOf('week').utc().format(format), referral_ids),
			LeadService.getLeadsStatusCount(STATUS.ACTIVATED, moment().startOf('day').utc().format(format), moment().endOf('day').utc().format(format), referral_ids),
			LeadService.getLeadsStatusCount(STATUS.ACTIVATED, moment().startOf('week').utc().format(format), moment().endOf('week').utc().format(format), referral_ids),
		])
			.then(datas => {
				if (datas[0]) {
					setTodayNewLeads(datas[0].count);
				}
				if (datas[1]) {
					setTodaySubmitted(datas[1].count);
				}
				if (datas[2]) {
					setTodayApproved(datas[2].count);
				}
				if (datas[3]) {
					setTodayRejected(datas[3].count);
				}
				if (datas[4]) {
					setWeekNewLeads(datas[4].count);
				}
				if (datas[5]) {
					setWeekSubmitted(datas[5].count);
				}
				if (datas[6]) {
					setWeekApproved(datas[6].count);
				}
				if (datas[7]) {
					setWeekRejected(datas[7].count);
				}
				if (datas[8]) {
					setTodayTotal(datas[8].count);
				}
				if (datas[9]) {
					setWeekTotal(datas[9].count);
				}
				if (datas[10]) {
					setTodayEsignPending(datas[10].count);
				}
				if (datas[11]) {
					setWeekEsignPending(datas[11].count);
				}
				if (datas[12]) {
					setTodayEsignRequested(datas[12].count);
				}
				if (datas[13]) {
					setWeekEsignRequested(datas[13].count);
				}
				if (datas[14]) {
					setTodayActivated(datas[14].count);
				}
				if (datas[15]) {
					setWeekActivated(datas[15].count);
				}
				setShowBlockLoader(false);
			})
			.catch(error => {
				console.error(error)
				setShowBlockLoader(false);
			})
		setLastRefreshed(moment());
		counter++
	}

	const handleDashboardClose = () => {
		setShowDashBoardConfig(false);
	}

	const fetchDashboard = () => {
		MasterService.getDashboard()
			.then(data => {
				if (data.preference) {
					const preference = data.preference.filter(pref => {
						switch (pref.id) {
							case '9f902dce-f118-11eb-82b5-001e67d8749f':
								return state.user && ([USERTYPES.KYC, USERTYPES.MD].includes(state.user.EmpUtype))
							case '9f9034fc-f118-11eb-82b5-001e67d8749f':
								return state.user && (![USERTYPES.KYCHO].includes(state.user.EmpUtype));
							case '9f903654-f118-11eb-82b5-001e67d8749f':
								return state.user && ([USERTYPES.KYC, USERTYPES.MD, USERTYPES.SALEHD].includes(state.user.EmpUtype))
							case '9f9036f8-f118-11eb-82b5-001e67d8749f':
								return state.user && (![USERTYPES.KYCHO].includes(state.user.EmpUtype))
							case '9f903787-f118-11eb-82b5-001e67d8749f':
								return true
							case '26f8bce7-fb55-11eb-b424-001e67d8749f':
								return true
							case 'a4eb4308-3eff-11ed-b6cd-001e67d8749e':
								return state.user && ([USERTYPES.KYC, USERTYPES.MD].includes(state.user.EmpUtype))
							case '7a14d8e3-dda2-11ed-bd4b-001e67d8749e':
								return ACCOUNT_CLOSURE_MODULE && state.user && ([USERTYPES.KYC, USERTYPES.MD, USERTYPES.KYCHO, USERTYPES.SALEHD, USERTYPES.BRANCH].includes(state.user.EmpUtype))
							case '7a17684f-dda2-11ed-bd4b-001e67d8749e':
								return ACCOUNT_CLOSURE_MODULE && state.user && ([USERTYPES.KYC, USERTYPES.MD, USERTYPES.KYCHO, USERTYPES.SALEHD, USERTYPES.BRANCH].includes(state.user.EmpUtype))
							case '7a1846ed-dda2-11ed-bd4b-001e67d8749e':
								return ACCOUNT_CLOSURE_MODULE
							default:
								return false
						}
					})
					setReportBlocks(preference);
				}
			})
			.catch(error => {
				console.log(error);
			})
	}

	const getReportView = (report) => {
		switch (report.id) {
			case '9f902dce-f118-11eb-82b5-001e67d8749f':
				return state.user && ([USERTYPES.KYC, USERTYPES.MD].includes(state.user.EmpUtype)) ? (<TableStats key={report.id} count={counter} report={report} range={defaultRange} />) : null;
			case '9f9034fc-f118-11eb-82b5-001e67d8749f':
				return state.user && (![USERTYPES.KYCHO].includes(state.user.EmpUtype)) ? (<DemographicStats key={report.id} count={counter} report={report} range={defaultRange} />) : null;
			case '9f903654-f118-11eb-82b5-001e67d8749f':
				return state.user && ([USERTYPES.KYC, USERTYPES.MD, USERTYPES.SALEHD].includes(state.user.EmpUtype)) ? (<TopRankingStates key={report.id} count={counter} report={report} range={defaultRange} />) : null;
			case '9f9036f8-f118-11eb-82b5-001e67d8749f':
				return state.user && (![USERTYPES.KYCHO].includes(state.user.EmpUtype)) ? (<TopRankingCities key={report.id} count={counter} report={report} range={defaultRange} />) : null;
			case '9f903787-f118-11eb-82b5-001e67d8749f':
				return (<AdminStats key={report.id} count={counter} report={report} range={defaultRange} />);
			case '26f8bce7-fb55-11eb-b424-001e67d8749f':
				return (<StepwiseStats key={report.id} count={counter} report={report} />);
			case 'a4eb4308-3eff-11ed-b6cd-001e67d8749e':
				return state.user && ([USERTYPES.KYC, USERTYPES.MD].includes(state.user.EmpUtype)) ? (<TATStats key={report.id} count={counter} report={report} />) : null;
			case '7a14d8e3-dda2-11ed-bd4b-001e67d8749e':
				return ACCOUNT_CLOSURE_MODULE && state.user && ([USERTYPES.KYC, USERTYPES.MD, USERTYPES.KYCHO, USERTYPES.SALEHD, USERTYPES.BRANCH].includes(state.user.EmpUtype)) ? (<AccountsClosed key={report.id} count={counter} report={report} range={defaultRange} />) : null;
			case '7a17684f-dda2-11ed-bd4b-001e67d8749e':
				return ACCOUNT_CLOSURE_MODULE && state.user && ([USERTYPES.KYC, USERTYPES.MD, USERTYPES.KYCHO, USERTYPES.SALEHD, USERTYPES.BRANCH].includes(state.user.EmpUtype)) ? (<ReasonForClosure key={report.id} count={counter} report={report} />) : null;
			case '7a1846ed-dda2-11ed-bd4b-001e67d8749e':
				return ACCOUNT_CLOSURE_MODULE ? (<ClosureStatus key={report.id} count={counter} report={report} range={defaultRange} />) : null;
			default:
				return (<EmptyBlock key={report.id} report={report} />);
		}
	}

	return (
		<div className="Home">
			<div className="lander">
				<div>
					{
						state.isAuthenticated && (
							<Grid container direction="row" spacing={1} justify="center">
								<Grid item data-tour="license" xs={12} lg={12} xl={10}>
									<Grid item xl={12} lg={12} xs={12}>
										<div style={{ float: "right" }}>
											<IconButton ref={dashboardConfigRef} data-tour="eye" onClick={(e) => setShowDashBoardConfig(true)}>
												<VisibilityIcon />
											</IconButton>
										</div>
										<div style={{ float: "right" }}>
											<Typography variant="caption">
												Last Refreshed:
												{
													lastRefreshed && lastRefreshedString
												}
											</Typography>
											<IconButton aria-label="close" data-tour="refresh" onClick={refresh}>
												<Icon>refresh</Icon>
											</IconButton>
										</div>
									</Grid>
									<Grid container item data-tour="today" xs={12} lg={12} xl={12} justify="center">
										<Grid item lg={3} xs={12}>
											<CardViewBlock title={''} subtitle={'Today'} />
										</Grid>
										<Grid item lg={1} xs={6}>
											{
												showBlockLoader ?
													<CardViewLoader />
													:
													<CardViewBlock title={'Ongoing'} subtitle={formatQty(todayNewLeads || 0)} />
											}
										</Grid>
										<Grid item lg={1} xs={6}>
											{
												showBlockLoader ?
													<CardViewLoader />
													:
													<CardViewBlock title={'ESign Pending'} subtitle={formatQty(todayEsignPending || 0)} />
											}
										</Grid>
										<Grid item lg={1} xs={6}>
											{
												showBlockLoader ?
													<CardViewLoader />
													:
													<CardViewBlock title={'ESign Requested'} subtitle={formatQty(todayEsignRequested || 0)} />
											}
										</Grid>
										<Grid item lg={1} xs={6}>
											{
												showBlockLoader ?
													<CardViewLoader />
													:
													<CardViewBlock color="yellow" title={'Completed'} subtitle={formatQty(todaySubmitted || 0)} />
											}
										</Grid>
										<Grid item lg={1} xs={6}>
											{
												showBlockLoader ?
													<CardViewLoader />
													:
													<CardViewBlock color="green" title={'Approved'} subtitle={formatQty(todayApproved || 0)} />
											}
										</Grid>
										<Grid item lg={1} xs={6}>
											{
												showBlockLoader ?
													<CardViewLoader />
													:
													<CardViewBlock color="red" title={'Rejected'} subtitle={formatQty(todayRejected || 0)} />
											}
										</Grid>
										<Grid item lg={1} xs={6}>
											{
												showBlockLoader ?
													<CardViewLoader />
													:
													<CardViewBlock color="green" title={'Activated'} subtitle={formatQty(todayActivated || 0)} />
											}
										</Grid>
										<Grid item lg={1} xs={6}>
											{
												showBlockLoader ?
													<CardViewLoader />
													:
													<CardViewBlock color="green" title={'Total'} subtitle={formatQty(todayTotal || 0)} />
											}
										</Grid>
									</Grid>
									<Grid container item data-tour="today" xs={12} lg={12} xl={12} justify="center">
										<Grid item lg={3} xs={12}>
											<CardViewBlock title={''} subtitle={'This Week'} />
										</Grid>
										<Grid item lg={1} xs={6}>
											{
												showBlockLoader ?
													<CardViewLoader />
													:
													<CardViewBlock title={'Ongoing'} subtitle={formatQty(weekNewLeads || 0)} />
											}
										</Grid>
										<Grid item lg={1} xs={6}>
											{
												showBlockLoader ?
													<CardViewLoader />
													:
													<CardViewBlock title={'ESign Pending'} subtitle={formatQty(weekEsignPending || 0)} />
											}
										</Grid>
										<Grid item lg={1} xs={6}>
											{
												showBlockLoader ?
													<CardViewLoader />
													:
													<CardViewBlock title={'ESign Requested'} subtitle={formatQty(weekEsignRequested || 0)} />
											}
										</Grid>
										<Grid item lg={1} xs={6}>
											{
												showBlockLoader ?
													<CardViewLoader />
													:
													<CardViewBlock color="yellow" title={'Completed'} subtitle={formatQty(weekSubmitted || 0)} />
											}
										</Grid>
										<Grid item lg={1} xs={6}>
											{
												showBlockLoader ?
													<CardViewLoader />
													:
													<CardViewBlock color="green" title={'Approved'} subtitle={formatQty(weekApproved || 0)} />
											}
										</Grid>
										<Grid item lg={1} xs={6}>
											{
												showBlockLoader ?
													<CardViewLoader />
													:
													<CardViewBlock color="red" title={'Rejected'} subtitle={formatQty(weekRejected || 0)} />
											}
										</Grid>
										<Grid item lg={1} xs={6}>
											{
												showBlockLoader ?
													<CardViewLoader />
													:
													<CardViewBlock color="green" title={'Activated'} subtitle={formatQty(weekActivated || 0)} />
											}
										</Grid>
										<Grid item lg={1} xs={6}>
											{
												showBlockLoader ?
													<CardViewLoader />
													:
													<CardViewBlock color="green" title={'Total'} subtitle={formatQty(weekTotal || 0)} />
											}
										</Grid>
									</Grid>
									<Grid container item xl={12} lg={12} xs={12} spacing={0} justify={"flex-start"}>
										{
											reportBlocks.filter(r => r.visible).map(report => (
												getReportView(report)
											))
										}
									</Grid>
								</Grid>
							</Grid>
						)
					}
				</div>
				<DashboardPopover
					anchorEl={dashboardConfigRef.current}
					onClose={handleDashboardClose}
					open={showDashBoardConfig}
				/>
				{
					ADD_LEAD_MODULE && (

						<BootstrapTooltip arrow title="Add New Lead">
							<Fab variant="extended" color="primary" aria-label="Add" className={classes.fab} onClick={handleClick}>
								<AddIcon />
								Add New Lead
							</Fab>
						</BootstrapTooltip>
					)
				}
				{
					REFERRAL_MODULE && state.user && ([USERTYPES.FRAN, USERTYPES.BRANCH, USERTYPES.RM].includes(state.user.EmpUtype)) && (

						<BootstrapTooltip arrow title="Copy Referral Link">
							<Fab variant="extended" color="secondary" size="small" aria-label="Add" className={classes.fab} onClick={copyReferral}>
								<LinkIcon />
								Referral Link
							</Fab>
						</BootstrapTooltip>
					)
				}
			</div>
		</div>
	);
}

export default Home;
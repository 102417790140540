import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  listItem: {
    '&:hover': {
      backgroundColor: theme.palette.hover
    }
  },
  arrowForwardIcon: {
    color: theme.palette.icon,
    cursor: 'pointer',
  },
  listitem: {
    cursor: 'pointer',
  },
  unread: {
    backgroundColor: theme.palette.unread,
  }
}));

const NotificationList = props => {
  const { notifications, className, ...rest } = props;
  const classes = useStyles();

  return (
    <List
      {...rest}
      className={clsx(classes.root, className)}
      disablePadding
    >
      {notifications.map((notification, i) => (
        <ListItem
          className={classes.listItem}
          component={'div'}
          divider={i < notifications.length - 1}
          key={i}
        >
            <ListItemText
              primary={notification}
              className={classes.listitem}
              primaryTypographyProps={{ variant: 'body2' }}
            />
        </ListItem>
      ))}
    </List>
  );
};

NotificationList.propTypes = {
  className: PropTypes.string,
  notifications: PropTypes.array.isRequired,
  onClose: PropTypes.func,
};

export default NotificationList;

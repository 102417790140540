import React, { useContext, useState } from "react";
import { Card, CardHeader, Divider, CardContent, makeStyles, IconButton, Grid } from "@material-ui/core";
import { BootstrapTooltip } from "../Utils";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import PropTypes from "prop-types";
import { DashboardContext } from "../DashboardContext";

const useStyles = makeStyles(theme => ({
    card: {
        margin: 5, 
        minHeight: 190,
    },
    icon: {
        padding: 2
    },
    griditem: {
        cursor: 'move'
    },
}));

const EmptyBlock = ({ forwardedRef, ...props }) => {
    const classes = useStyles();
    const {hideReport, showReport} = useContext(DashboardContext);

    const {report} = props;
    const [isShown, setIsShown] = useState(false);

    const changeVisibility = e => {
        if(report.visible){
            hideReport(report.id);
        } else {
            showReport(report.id);
        }
    }

    return (
        <Grid item xl={4} lg={4} xs={12}>
            <Card 
                ref={forwardedRef}
                className={classes.card} 
                onMouseEnter={() => setIsShown(true)}
                onMouseLeave={() => setIsShown(false)}>
                <CardHeader 
                    title={report.name}
                    className={classes.griditem}
                    titleTypographyProps={{align: 'left'}}
                    action={
                        isShown && (
                            <BootstrapTooltip title={report.visible ? 'Click to Hide' : 'Click to Show' }>
                                <IconButton size="small" className={classes.icon} onClick={changeVisibility}>
                                    {report.visible ? <VisibilityOffIcon /> : <VisibilityIcon /> }
                                </IconButton>
                            </BootstrapTooltip>
                        )
                    }/>
                <Divider />
                <CardContent>
                    Charts
                </CardContent>
            </Card>
        </Grid>
    );
};

EmptyBlock.propTypes = {
    report: PropTypes.object.isRequired,
};


export default EmptyBlock;
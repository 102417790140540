import React, { createContext, useContext, useMemo, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import Slide from "@material-ui/core/Slide";
import PropTypes from "prop-types";
import {
  Grid,
  CardHeader,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  FormControl,
  TextField,
  RadioGroup,
  FormLabel,
  Radio,
  Tooltip,
  Badge,
} from "@material-ui/core";
import { blue, red } from "@material-ui/core/colors";
import {
  format_display_date,
  format_display_fancy_notime,
  getTimeInIST,
  openLink,
  GreenTick,
  RedCancelIcon,
  GreyDot,
  GreyNotInterested,
  GreenSpellCheck,
  currentTimeInIST,
} from "../Utils";
import { AppContext } from "../AppContextProvider";
import {
  HOLDINGS_OPTIONS,
  STATUS,
  STATUS_MAP_CLOSURE,
  USERTYPES,
} from "../Constants";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { LeadService } from "../Services/LeadService";
import { MasterService } from "../Services/MasterService";
import MarkApprovedDialog from "../components/MarkApprovedDialog";
import ItemSelectionAsync from "../components/ItemSelectionAsync";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import LeadDocuments from "./LeadDocuments";
import ToolbarBottom from "./ToolbarBottom";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { isEmpty } from "lodash";
import { NotificationsPopover } from "../components/Notifications";
import { Check, Error, NotInterested, Undo } from "@material-ui/icons";
import { GreenButton } from "../components/ColorButton";
import { RedButton } from "../components/ColorButton";

export const LeadDialogContext = createContext();

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  appBarBottom: {
    top: "auto",
    bottom: 0,
    backgroundColor: theme.palette.white,
  },
  accordionSummary: {
    backgroundColor: theme.palette.white,
    color: theme.palette.white,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  header: {
    fontWeight: 700,
  },
  taskHeader: {
    fontWeight: 700,
    textTransform: "initial",
  },
  gridList: {
    margin: 16,
    width: "90%",
  },
  page: {
    height: "100%",
    backgroundColor: theme.palette.divider,
    overflow: "auto",
    paddingBottom: 100,
  },
  section: {
    margin: 16,
    minHeight: 200,
  },
  avatar: {
    backgroundColor: red[500],
  },
  image: {
    margin: 12,
    position: "relative",
    height: 200,
    width: 200,
    [theme.breakpoints.down("xs")]: {
      width: "100% !important", // Overrides inline-style
      height: 100,
    },
    "&:hover,  &Mui-focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.7,
      },
      "& $imageMarked": {
        opacity: 0,
      },
      "& $imageTitle": {
        border: "4px solid currentColor",
        marginLeft: 8,
        marginRight: 8,
      },
    },
  },
  imageBackdrop: {
    borderRadius: 8,
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.3,
    transition: theme.transitions.create("opacity"),
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(0)}px ${theme.spacing(4)}px ${theme.spacing(
      0
    )}px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  },
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  imageButtonBottom: {
    position: "absolute",
    left: 0,
    right: 0,
    // top: 0,
    // bottom: 0,
    bottom: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  formAutoClass: {
    zIndex: 3,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  card: {
    margin: 8,
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  icon: {
    color: theme.palette.white,
  },
  iconT: {
    fontSize: 18,
    marginLeft: 16,
    [theme.breakpoints.down("lg")]: {
      marginTop: 4,
    },
    cursor: "pointer",
  },
  title1: {
    marginLeft: theme.spacing(1),
    fontWeight: 700,
  },
  subtitle: {
    marginLeft: theme.spacing(1),
  },
  titleBar: {
    fontSize: 14,
  },
  subtitleBar: {
    fontSize: 12,
  },
  grow: {
    flexGrow: 1,
  },
  editBanner: {
    positon: "relative",
    bottom: 0,
    width: "100%",
    backgroundColor: "#BFBFBF",
    color: "black",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
  },
  editBannerTitle: {
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const getStatus = (status) => {
  if (status === -1) {
    return (
      <Tooltip title={"Not initiated"}>
        <GreyDot />
      </Tooltip>
    );
  }
  if (status === 1) {
    return (
      <Tooltip title={"Completed"}>
        <GreenTick />
      </Tooltip>
    );
  }
  if (status === 0) {
    return (
      <Tooltip title={"Failed"}>
        <RedCancelIcon />
      </Tooltip>
    );
  }
  if (status === -2) {
    return (
      <Tooltip title={"Skipped"}>
        <GreyNotInterested />
      </Tooltip>
    );
  }
  return null;
};

const fieldMargin = "dense";
const fieldSize = "small";
const fieldVariant = "outlined";
export default function ViewLeadDialog(props) {
  const {
    showSnackBar,
    showProgressDialog,
    closeProgressDialog,
    showConfirmDialog,
    closeConfirmDialog,
    showConfirmDialog1D,
    closeConfirmDialog1D,
  } = useContext(AppContext);
  const { id } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [hasChanges, setHasChanges] = React.useState(false);
  const [expandedDetails, setExpandedDetails] = React.useState(true);
  const [expandedApprovalSection, setExpandedApprovalSection] = React.useState(false);
  const [expandedTaskDetails, setExpandedTaskDetails] = React.useState(false);
  const [expandedProofs, setExpandedProofs] = React.useState(false);
  const [lead, setLead] = React.useState({});
  const [originalLead, setOriginalLead] = React.useState({});
  const [proofs, setProofs] = React.useState(new Map());

  const [editMode, setEditMode] = React.useState(false);
  const [disabled, setDisabled] = React.useState(true);
  const [compareProofs, setCompareProofs] = React.useState(undefined);
  const [summary, setSummary] = React.useState({});
  const [errorList, setErrorList] = React.useState({});
  const [appBarStyle, setAppBarStyle] = React.useState({});


  const [approve, setApprove] = React.useState({ open: false });
  const [approveOptions, setApproveOptions] = React.useState([]);

  const [returnUrl, setReturnURL] = useState(false);
  const history = useHistory();

  React.useEffect(() => {
    setDisabled(true);
    if (props.location && props.location.search) {
      let qString = queryString.parse(props.location.search);
      if (qString.redirectUrl) {
        setReturnURL(qString.redirectUrl);
      }
    }
    setOpen(props.open);
    if (props.open) {
      fetchData();
    }
    return () => {
      setLead({});
      setOriginalLead({});
      setSummary({});
      setApprove({ open: false });
      setCompareProofs(undefined);
      setOpen(false);
    };
  }, [props.open]);

  const fetchData = () => {
    // setLoading(true);
    showProgressDialog("Loading...");
    Promise.all([
      LeadService.getClosureLeadById(id, true),
      MasterService.getDropdownOptions("approve"),
      MasterService.getDropdownOptions("reject"),
    ])
      .then((datas) => {
        console.log(datas);
        if (datas[0]) {
          let lead = datas[0];
          lead.status = parseInt(lead.status, 10)
          let status = STATUS_MAP_CLOSURE.find((s) => s.value === lead.status);
          setAppBarStyle({
            paddingRight: 0,
            backgroundColor: status.color,
            color: status.textColor || "white",
          });
          setLead(lead);
          setOriginalLead(lead);
          if (lead.documents) {
            let m = new Map();
            lead.documents.forEach((doc) => {
              m.set(doc.document_code, doc);
            });
            setProofs(m);
          }

          let summary = {};

          switch (lead.status) {
            case STATUS.ONGOING:
              summary.title = `Current Step: ${lead.current_step}`;
              summary.subtitle = lead.modified_date
                ? `Last Updated: ${getTimeInIST(lead.modified_date).format(
                  format_display_fancy_notime
                )}`
                : "";
              break;
            case STATUS.APPROVED:
              summary.title = `Approved by ${lead.performed_by_name || "-"}`;
              summary.remarks = (
                <>
                  <div>Remarks: {lead.remarks ? lead.remarks.split(',').join(', ') : "-"}</div>
                  {lead.comments ? <div>Other Comments: {lead.comments}</div> : null}
                </>
              )
              summary.subtitle = lead.performed_on
                ? getTimeInIST(lead.performed_on).format(format_display_date)
                : "-";
              break;
            case STATUS.ACTIVATED:
              summary.title = `Approved by ${lead.performed_by_name || "-"}`;
              summary.remarks = (
                <>
                  <div>Remarks: {lead.remarks ? lead.remarks.split(',').join(', ') : "-"}</div>
                  {lead.comments ? <div>Other Comments: {lead.comments}</div> : null}
                </>
              )
              summary.subtitle = lead.performed_on
                ? getTimeInIST(lead.performed_on).format(format_display_date)
                : "-";
              break;
            case STATUS.REJECTED:
              summary.title = `Rejected by ${lead.performed_by_name || "-"}`;
              summary.remarks = (
                <>
                  <div>Remarks: {lead.remarks ? lead.remarks.split(',').join(', ') : "-"}</div>
                  {lead.comments ? <div>Other Comments: {lead.comments}</div> : null}
                </>
              )
              summary.subtitle = lead.performed_on
                ? getTimeInIST(lead.performed_on).format(format_display_date)
                : "-";
              summary.btnEdit = true;
              break;
            case STATUS.ESIGN_PENDING:
              summary.title =
                lead.retake_ipv === 0 || lead.retake_photo === 0
                  ? "Note: Lead has been requested to Retake Video/Photo"
                  : "";
              summary.subtitle = "";
              summary.btnEdit = true;
              summary.btnReject = true;
              break;
            case STATUS.COMPLETED:
              summary.title = lead.modified_date
                ? `Last Updated: ${getTimeInIST(lead.modified_date).format(
                  format_display_fancy_notime
                )}`
                : "";
              summary.subtitle = "";
              summary.btnApprove = lead.approval_status;
              summary.btnReject = lead.approval_status;
              summary.btnEdit = true;
              break;
            default:
              summary.title =
                lead.retake_ipv === 0 || lead.retake_photo === 0
                  ? "Note: Lead has been requested to Retake Video/Photo"
                  : "";
              summary.subtitle = "";
          }
          setSummary(summary);
        }
        if (datas[1] || datas[2]) {
          setApproveOptions({
            approve: (datas[1] && datas[1].approve) || [],
            reject: (datas[2] && datas[2].reject) || [],
          });
        }
        closeProgressDialog();
        // setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        showConfirmDialog1D(
          "Something went wrong. Please try again.",
          undefined,
          () => {
            handleClose();
            closeConfirmDialog1D();
          }
        );
        // setLoading(false);
        closeProgressDialog();
      });
  };

  const handleClose = (callback = false) => {
    close(hasChanges)
  };

  const close = (hasChanges) => {
    props.onClose(hasChanges);
    setLead({});
    setOriginalLead({});
    setApprove({ open: false });
    setSummary({});
    setAppBarStyle({});
    setOpen(false);
    setDisabled(true);
    setEditMode(false);
    setExpandedDetails(true);
    setExpandedApprovalSection(false);
    setExpandedTaskDetails(false);
    setExpandedProofs(false);
    setCompareProofs(undefined);
    if (returnUrl) {
      history.push(returnUrl);
    }
  };

  const action = (type) => (e) => {
    if (type === "approve") {
      setApprove({ open: true, type, title: "Approve" });
    } else if (type === "reject") {
      fetchAndShowReject(type);
    } else if (type === "delete") {
      deleteLead();
    }
  };

  const fetchAndShowReject = (type) => {
    showProgressDialog("Fetching Details...");
    LeadService.getClosureLeadById(id, false, true)
      .then((data) => {
        console.log(data);
        if (data) {
          let lead = data;
          let documents = [];
          if (lead.editable_fields && lead.editable_fields.documents) {
            lead.editable_fields.documents.forEach((doc) => {
              documents.push({
                document_id: doc.document_id,
                document_label: doc.document_label,
              });
            });
          }
          setApprove({
            open: true,
            type,
            title: "Reject",
            documents: documents.length > 0 ? documents : undefined,
          });
        } else {
          showConfirmDialog1D(
            "Something went wrong. Please try again.",
            undefined,
            () => {
              closeConfirmDialog1D();
            }
          );
        }
      })
      .catch((error) => {
        console.log(error);
        showConfirmDialog1D(
          "Something went wrong. Please try again.",
          undefined,
          () => {
            closeConfirmDialog1D();
          }
        );
      })
      .finally(() => closeProgressDialog());
  };

  const deleteLead = () => {
    showConfirmDialog("Confirm?", undefined, () => {
      closeConfirmDialog();
      showProgressDialog("Deactivating...");
      LeadService.deactivateClosureLead({ field: "lead_id", value: lead.lead_id })
        .then((data) => {
          if (data.success) {
            showSnackBar("Deactivated Account", "info");
            fetchData();
            setHasChanges(true);
          } else {
            showSnackBar("Something went wrong. Please try again.", "error");
          }
        })
        .catch((error) => {
          console.log(error);
          showConfirmDialog1D(
            "Something went wrong. Please try again.",
            undefined,
            () => {
              closeConfirmDialog1D();
            }
          );
        })
        .finally(() => closeProgressDialog());
    });
  };

  const markApproved = (remarks, documents, comments) => {
    showConfirmDialog("Confirm changes?", undefined, () => {
      closeConfirmDialog();
      showProgressDialog("Saving...");
      let body = {
        lead_id: lead.lead_id,
        status: approve.type === "approve" ? STATUS.APPROVED : STATUS.REJECTED,
        remarks: remarks || "",
        comments,
        documents: documents || [],
      };
      LeadService.updateClosureLeadStatus(body)
        .then((data) => {
          if (data.success) {
            showSnackBar("Saved", "info");
            fetchData();
            setApprove({ open: false });
            setHasChanges(true);
          } else {
            showSnackBar("Something went wrong. Please try again.", "error");
          }
        })
        .catch((error) => {
          console.log(error);
          showConfirmDialog1D(
            "Something went wrong. Please try again.",
            undefined,
            () => {
              closeConfirmDialog1D();
            }
          );
        })
        .finally(() => closeProgressDialog());
    });
  };

  const downloadAllDocs = () => {
    showProgressDialog("Requesting Download...");
    LeadService.downloadClosureDocuments(lead.lead_id)
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${lead.client_code || ""}${lead.client_code ? " - " : ""}${lead.pan
          }.zip`
        );
        // 3. Append to html page
        document.body.appendChild(link);
        // 4. Force download
        link.click();
        // 5. Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        console.log(error);
        showSnackBar("Failed to Download. Please try again", "error");
      })
      .finally(() => {
        closeProgressDialog();
      });
  };

  const uploadAdditionalDoc = (type, file) => {
    // not required now
    showProgressDialog("Uploading...");
    let fd = new FormData();
    fd.append("file", file);
    fd.append("dropdown_id", type);
    LeadService.updateReKYCAdditionalDocuments(lead.lead_id, fd)
      .then((data) => {
        if (data.success) {
          showSnackBar('File uploaded', "success")
          fetchData()
        } else {
          showSnackBar(data.error, "error");
        }
      })
      .catch((error) => {
        console.log(error);
        showSnackBar("Failed to Download. Please try again", "error");
      })
      .finally(() => {
        closeProgressDialog();
      });
  }

  const deleteAdditionalDoc = (docId) => {
    // Not required now
    showConfirmDialog("Confirm Remove?", undefined, () => {
      closeConfirmDialog();
      showProgressDialog("Removing document...");
      LeadService.removeReKYCAdditionalDocuments(docId)
        .then(() => {
          showSnackBar("Removed", "success")
          fetchData()
        })
        .catch((error) => {
          console.log(error);
          showConfirmDialog1D(
            "Something went wrong. Please try again.",
            undefined,
            () => {
              closeConfirmDialog1D();
            }
          );
        })
        .finally(() => closeProgressDialog());
    })
  }

  return (
    <LeadDialogContext.Provider
      value={{
        close,
        expandedDetails,
        setExpandedDetails,
        expandedProofs,
        setExpandedProofs,
        expandedApprovalSection,
        setExpandedApprovalSection,
        expandedTaskDetails,
        setExpandedTaskDetails,
        downloadAllDocs,
        deleteAdditionalDoc,
        uploadAdditionalDoc,
        appBarStyle,
        errorList,
        setErrorList,
        handleClose,
        fieldSize,
        fetchData,
        action,
        lead,
        setLead,
        originalLead,
        disabled,
        proofs,
        editMode,
        compareProofs,
        summary,
      }}
    >
      <Dialog
        fullScreen
        open={open}
        disableEscapeKeyDown={editMode}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBarTop />
        <Grid
          container
          className={classes.page}
          style={
            lead.status && lead.status === STATUS.CANCELLED
              ? { backgroundColor: lead.status }
              : {}
          }
          justify="center"
          alignItems="flex-start"
        >
          <Grid
            container
            item
            spacing={1}
            xs={12}
            lg={12}
            xl={10}
            justify={"center"}
            direction="row"
            style={{ marginTop: 16 }}
          >
            {lead.lead_id && (
              <Grid item xs={12} lg={12}>
                <LeadInformation />
              </Grid>
            )}
            {lead.lead_id && (
              <Grid item xs={12} lg={12}>
                <LeadDocuments />
              </Grid>
            )}
            {lead.lead_id && (
              <Grid item xs={12} lg={12}>
                <LeadApprovals />
              </Grid>
            )}
            {lead.lead_id &&
              (lead.status === STATUS.APPROVED ||
                lead.status === STATUS.ACTIVATED) &&
              lead.closure_task_status &&
              lead.closure_task_status.length > 0 && (
                <Grid item xs={12} lg={12}>
                  <TaskStatus />
                </Grid>
              )}
          </Grid>
        </Grid>
        <AppBar position="fixed" className={classes.appBarBottom}>
          <ToolbarBottom />
        </AppBar>
        <MarkApprovedDialog
          fullWidth={true}
          maxWidth={"sm"}
          open={approve.open || false}
          type={approve.type}
          title={approve.title || ""}
          documents={approve.documents}
          close={() => {
            setApprove({ open: false });
          }}
          save={(remarks, documents, comments) => {
            markApproved(remarks, documents, comments);
          }}
          required={approve.type === "reject"}
          options={
            approve.type === "approve"
              ? approveOptions.approve
              : approveOptions.reject
          }
        />
      </Dialog>
    </LeadDialogContext.Provider>
  )
}

const AppBarTop = () => {
  const { originalLead, appBarStyle, handleClose } = useContext(LeadDialogContext);
  const classes = useStyles();

  const notificationsRef = useRef(null)
  const notifications = useMemo(() => originalLead.notifications || [], [originalLead.notifications])
  const [open, setOpen] = useState(false)

  return (
    <AppBar className={classes.appBar} color="transparent" style={appBarStyle}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" color="inherit" className={classes.title}>
          {originalLead.lead_id && originalLead.pan_name
            ? `Lead : ${originalLead.pan_name}`
            : ""}
        </Typography>
        {!isEmpty(notifications) && (
          <Tooltip title="Notifications">
            <IconButton color="inherit" style={{ position: "absolute", right: 120 }} ref={notificationsRef} onClick={() => setOpen(true)}>
              <Badge badgeContent={notifications.length} color={"error"}>
                <NotificationsOutlinedIcon />
              </Badge>
            </IconButton>
          </Tooltip>
        )}
        <Typography variant="h6" color="inherit">
          {typeof originalLead.status === "number"
            ? STATUS_MAP_CLOSURE.find((s) => s.value === originalLead.status).label
            : ""}
        </Typography>
      </Toolbar>
      <NotificationsPopover
        anchorEl={notificationsRef.current}
        notifications={notifications || []}
        open={open}
        onClose={() => setOpen(false)}
      />
    </AppBar>
  );
};

const TaskStatus = () => {
  // Status
  // -2 - Not required
  // -1 - Not initiated
  //  0 - Failed
  //  1 - Success (API)
  //  2 - Initiated (Downloaded)

  const {
    showProgressDialog,
    closeProgressDialog,
    showSnackBar,
    showConfirmDialog,
    closeConfirmDialog,
  } = useContext(AppContext);
  const { lead, expandedTaskDetails, setExpandedTaskDetails, fetchData, } = useContext(LeadDialogContext);
  const classes = useStyles();

  const taskAction = (type, status) => () => {
    showConfirmDialog(
      "Confirm action?",
      undefined,
      () => {
        closeConfirmDialog();
        showProgressDialog("Updating...");
        LeadService.attemptClosureTask(lead.lead_id, type, status)
          .then((data) => {
            if (data.success) {
              lead.closure_task_status.find(
                (task) => task.type === type
              ).status = status;
            }
            if (data.message) {
              showSnackBar(data.message, "info");
              fetchData()
            } else if (data.error) {
              showSnackBar(data.error, "error");
            }
          })
          .catch((error) => {
            console.log(error);
            showSnackBar("Something went wrong. Please try again", "error");
          })
          .finally(() => {
            closeProgressDialog();
          });
      },
      "Cancel",
      "Confirm"
    );
  };

  const taskStatusRow = (task) => {
    return (
      <Grid
        key={task.type}
        item
        container
        justify="flex-start"
        spacing={2}
        alignItems="center"
        xs={12}
      >
        <Grid item>{getStatus(task.status)}</Grid>
        <Grid item xs={12} lg={3}>
          <Typography variant="body1" className={classes.taskHeader}>
            {task.title}
          </Typography>
        </Grid>
        {lead.status === STATUS.APPROVED && [-2, 1].includes(task.status) && (
          <Grid item xs={12} lg={2}>
            <Button
              startIcon={<Undo />}
              variant="outlined"
              onClick={taskAction(task.type, -1)}
            >
              Undo
            </Button>
          </Grid>
        )}
        {lead.status === STATUS.APPROVED && task.status === -1 && (
          <Grid container item xs={12} lg={4} spacing={2}>
            <Grid item>
              <Button
                startIcon={<Check />}
                variant="outlined"
                onClick={taskAction(task.type, 1)}
              >
                Mark Complete
              </Button>
            </Grid>
            <Grid item>
              <Button
                startIcon={<NotInterested />}
                variant="outlined"
                onClick={taskAction(task.type, -2)}
              >
                Skip
              </Button>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <Divider variant="inset" />
        </Grid>
      </Grid>
    );
  };

  return (
    <Accordion
      square
      expanded={expandedTaskDetails}
      onChange={() => setExpandedTaskDetails((prev) => !prev)}
    >
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="task-header"
      >
        <CardHeader
          title={`Closure Tasks`}
          titleTypographyProps={{ variant: "h4" }}
        />
      </AccordionSummary>
      <AccordionDetails>
        <div
          style={{
            width: "100%",
            minHeight: 100,
            marginRight: 16,
            marginLeft: 16,
            marginTop: 8,
            marginBottom: 16,
          }}
        >
          <Grid container justify="flex-start" spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {lead.closure_task_status
              .map((task) => taskStatusRow(task))}
          </Grid>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};


const LeadApprovals = () => {
  // Status
  // -2 - Auto Approve
  // -1 - No action
  //  0 - Reject
  //  1 - Approved

  const {
    showProgressDialog,
    closeProgressDialog,
    showSnackBar,
    showConfirmDialog,
    closeConfirmDialog,
    state,
  } = useContext(AppContext);
  const { lead, expandedApprovalSection, setExpandedApprovalSection, fetchData } = useContext(LeadDialogContext);
  const classes = useStyles();

  const approvals = useMemo(() => {
    return [
      {
        type: 'branch',
        title: "Branch",
        code: lead.branch,
        modified_date: lead.branch_approval_date ? getTimeInIST(lead.branch_approval_date).format(format_display_date) : null,
        status: lead.branch_approval,
      },
      {
        type: 'state_head',
        title: "State Head",
        code: lead.state_head,
        modified_date: lead.state_approval_date ? getTimeInIST(lead.state_approval_date).format(format_display_date) : null,
        status: lead.state_approval,
      }
    ]
  }, [lead.approval_status])

  const taskAction = (type, status) => () => {
    showConfirmDialog(
      "Confirm action?",
      undefined,
      () => {
        closeConfirmDialog();
        showProgressDialog("Updating...");
        LeadService.closureApprovalTask(lead.lead_id, status)
          .then((data) => {
            if (data.success) {
              const approvalKey = `${type}_approval`
              const approvalDateKey = `${type}_approval_date`
              lead[approvalKey] = status;
              lead[approvalDateKey] = currentTimeInIST().format(format_display_date);
            }
            if (data.message) {
              showSnackBar(data.message, "info");
              fetchData()
            } else if (data.error) {
              showSnackBar(data.error, "error");
            }
          })
          .catch((error) => {
            console.log(error);
            showSnackBar("Something went wrong. Please try again", "error");
          })
          .finally(() => {
            closeProgressDialog();
          });
      },
      "Cancel",
      "Confirm"
    );
  };

  const getStatus = (status) => {
    if (status === -1) {
      return (
        <Tooltip title={"Action Pending"}>
          <GreyDot />
        </Tooltip>
      );
    }
    if (status === 1) {
      return (
        <Tooltip title={"Approved"}>
          <GreenTick />
        </Tooltip>
      );
    }
    if (status === 0) {
      return (
        <Tooltip title={"Rejected"}>
          <RedCancelIcon />
        </Tooltip>
      );
    }
    if (status === -2) {
      return (
        <Tooltip title={"Auto Approved"}>
          <GreenSpellCheck />
        </Tooltip>
      );
    }
    return null;
  };

  const approvalStatusRow = (task) => {

    const canUpdate = () => {
      if (task.type === 'branch' && task.code === state.user.EmpCode) return true
      if (task.type === 'state_head' && task.code === state.user.EmpCode && [0, 1, -2].includes(approvals[0].status)) return true
      return false
    }

    return (
      <Grid
        key={task.type}
        item
        container
        justify="space-between"
        spacing={2}
        alignItems="center"
        xs={12}
      >
        <Grid container spacing={2} justify="flex-start" item xs={12} lg={6}>
          <Grid item>
            {getStatus(task.status)}
          </Grid>
          <Grid item xs={12} lg={3}>
            <Typography variant="body1" className={classes.taskHeader}>
              {task.title} - {task.code}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} item xs={12} lg={4}>
          {(task.status === 1 || task.status === 0) ? (
            <Typography>
              Updated on {task.modified_date}
            </Typography>
          ) : null}
          {[-1].includes(task.status) && (lead.status === STATUS.ONGOING || lead.status === STATUS.COMPLETED) ? (
            <>
              <Grid container item xs={12} lg={12} spacing={2}>
                <Grid item>
                  <GreenButton disabled={!canUpdate()} startIcon={<Check />} onClick={taskAction(task.type, 1)}>Approve</GreenButton>
                </Grid>
                <Grid item>
                  <RedButton disabled={!canUpdate()} startIcon={<NotInterested />} onClick={taskAction(task.type, 0)}>Reject</RedButton>
                </Grid>
              </Grid>
            </>
          ) : ([-1].includes(task.status) ? (
            <Typography variant="caption" color="textSecondary">Only assigned person can take an action</Typography>
          ) : null)}
        </Grid>
        <Grid item xs={12}>
          <Divider variant="inset" />
        </Grid>
      </Grid>
    );
  };

  const showAttentionBadge = () => {
    const hasPendingTasks = approvals.map(a => a.status).some(status => status === -1)
    if (hasPendingTasks && (lead.status === STATUS.ONGOING || lead.status === STATUS.COMPLETED)
      && (approvals[0].code === state.user.EmpCode || approvals[1].code === state.user.EmpCode)) {
      return true
    }
    return false
  }

  return (
    <Accordion
      square
      expanded={expandedApprovalSection}
      onChange={() => setExpandedApprovalSection((prev) => !prev)}
    >
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="approval-header"
      >
        <CardHeader
          title="Approvals"
          style={{ width: "100%" }}
          titleTypographyProps={{ variant: "h4" }}
          action={
            showAttentionBadge() ? (
              <Tooltip title="An action is required from your end">
                <div style={{ position: "relative", top: 7 }}>
                  <Error style={{ color: red[500] }} />
                </div>
              </Tooltip>
            ) : null
          }
        />
      </AccordionSummary>
      <AccordionDetails>
        <div
          style={{
            width: "100%",
            minHeight: 100,
            marginRight: 16,
            marginLeft: 16,
            marginTop: 8,
            marginBottom: 16,
          }}
        >
          <Grid container justify="flex-start" spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {approvals.map((task) => approvalStatusRow(task))}
          </Grid>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};


const LeadInformation = () => {
  const {
    expandedDetails,
    setExpandedDetails,
    lead,
    setLead,
    editMode,
    disabled,
    proofs,
    errorList,
    setErrorList,
  } = useContext(LeadDialogContext);
  const {
    state,
    showConfirmDialog,
    closeConfirmDialog,
    showProgressDialog,
    closeProgressDialog,
    showSnackBar,
    showConfirmDialog1D,
    closeConfirmDialog1D,
  } = useContext(AppContext);
  const classes = useStyles();

  const updateTracking = () => {
    showConfirmDialog("Confirm Tracking details?", undefined, () => {
      closeConfirmDialog();
      showProgressDialog("Saving...");
      LeadService.updateClosureTrackingDetails({
        lead_id: lead.lead_id,
        tracking_details: lead.tracking_details,
      })
        .then(() => {
          showSnackBar("Saved", "info");
        })
        .catch((error) => {
          console.log(error);
          showConfirmDialog1D(
            "Something went wrong. Please try again.",
            undefined,
            () => {
              closeConfirmDialog1D();
            }
          );
        })
        .finally(() => closeProgressDialog());
    });
  };

  const handleChange = (e) => {
    e.persist();
    if (['permanent_addr1', 'permanent_addr2', 'permanent_addr3', 'permanent_city',
      'permanent_state', 'permanent_country'].includes(e.target.name) && lead.current_addr_same
      && lead.current_addr_same === "Y") {
      if (e.target.name.includes('addr1')) {
        setLead((old) => ({ ...old, [e.target.name]: e.target.value, current_addr1: e.target.value }));
      } else if (e.target.name.includes('addr2')) {
        setLead((old) => ({ ...old, [e.target.name]: e.target.value, current_addr2: e.target.value }));
      } else if (e.target.name.includes('addr3')) {
        setLead((old) => ({ ...old, [e.target.name]: e.target.value, current_addr3: e.target.value }));
      } else if (e.target.name.includes('city')) {
        setLead((old) => ({ ...old, [e.target.name]: e.target.value, current_city: e.target.value }));
      } else if (e.target.name.includes('state')) {
        setLead((old) => ({ ...old, [e.target.name]: e.target.value, current_state: e.target.value }));
      } else if (e.target.name.includes('country')) {
        setLead((old) => ({ ...old, [e.target.name]: e.target.value, current_country: e.target.value }));
      }
    } else {
      setLead((old) => ({ ...old, [e.target.name]: e.target.value }));
    }
  };

  const handleChangeKV = (k, v) => {
    setLead((old) => ({ ...old, [k]: v }));
  };
  const handleChangeObject = (diff) => {
    setLead((old) => ({ ...old, ...diff }));
  };

  const getReasons = () => {
    return lead.closure_reasons.map(reason => {
      if (reason === 'Others') {
        return <li key={reason}>{reason} - {lead.other_reason}</li>
      }
      return <li key={reason}>{reason}</li>
    })
  }

  return (
    <Accordion
      square
      expanded={expandedDetails}
      onChange={() => setExpandedDetails((prev) => !prev)}
    >
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <CardHeader
          title="Lead Information"
          titleTypographyProps={{ variant: "h4" }}
        />
      </AccordionSummary>
      <AccordionDetails>
        <div style={{ marginRight: 16, marginLeft: 16 }}>
          <Grid container justify="center" spacing={2} alignItems="center">
            {lead.client_code && (
              <Grid
                item
                xs={12}
                lg={12}
                container
                justify="flex-start"
                spacing={1}
                alignItems="center"
              >
                <Grid item xs={12} lg={2}>
                  <Typography variant="h5" className={classes.title1}>
                    Client Code
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={10}>
                  <Typography variant="subtitle2" className={classes.subtitle}>
                    {lead.client_code}
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} lg={12}>
              <Divider />
            </Grid>
            {
              <Grid
                item
                xs={12}
                lg={12}
                container
                direction="row"
                justify="flex-start"
                spacing={1}
                alignItems="flex-start"
              >
                <Grid item xs={12} lg={2} md={2}>
                  <Typography variant="h5" className={classes.title1}>
                    Closure details
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={10}
                  container
                  direction="row"
                  justify="flex-start"
                  spacing={1}
                  alignItems="center"
                >
                  <Grid item xs={12} lg={3} md={3}>
                    <FormControl variant={fieldVariant} fullWidth>
                      <TextField
                        label="Mobile Number"
                        margin={fieldMargin}
                        name="mobile"
                        InputProps={{
                          readOnly: disabled,
                        }}
                        type="number"
                        disabled={
                          lead.editable_fields &&
                          !lead.editable_fields.lead.includes("mobile")
                        }
                        value={lead.mobile || (editMode ? "" : "   ")}
                        error={errorList.mobile}
                        onChange={handleChange}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={3} md={3}>
                    <FormControl variant={fieldVariant} fullWidth>
                      <TextField
                        label="Email ID"
                        margin={fieldMargin}
                        name="email"
                        InputProps={{
                          readOnly: disabled,
                        }}
                        disabled={
                          lead.editable_fields &&
                          !lead.editable_fields.lead.includes("email")
                        }
                        value={lead.email || (editMode ? "" : "   ")}
                        error={errorList.email}
                        onChange={handleChange}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} lg={12} md={12}>
                    <Typography
                      variant="h6"
                      className={classes.title1}
                      component="span"
                      style={{ marginRight: 16, marginLeft: 0 }}
                    >
                      Permanent Address
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={4} md={4}>
                    <FormControl variant={fieldVariant} fullWidth>
                      <TextField
                        label="Address Line 1"
                        margin={fieldMargin}
                        name="permanent_addr1"
                        InputProps={{
                          readOnly: disabled,
                        }}
                        disabled={
                          lead.editable_fields &&
                          !lead.editable_fields.lead.includes("permanent_addr1")
                        }
                        value={lead.permanent_addr1 || (editMode ? "" : "   ")}
                        error={errorList.permanent_addr1}
                        onChange={handleChange}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={4} md={4}>
                    <FormControl variant={fieldVariant} fullWidth>
                      <TextField
                        label="Address Line 2"
                        margin={fieldMargin}
                        name="permanent_addr2"
                        InputProps={{
                          readOnly: disabled,
                        }}
                        disabled={
                          lead.editable_fields &&
                          !lead.editable_fields.lead.includes("permanent_addr2")
                        }
                        value={lead.permanent_addr2 || (editMode ? "" : "   ")}
                        error={errorList.permanent_addr2}
                        onChange={handleChange}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={4} md={4}>
                    <FormControl variant={fieldVariant} fullWidth>
                      <TextField
                        label="Address Line 3"
                        margin={fieldMargin}
                        name="permanent_addr3"
                        InputProps={{
                          readOnly: disabled,
                        }}
                        disabled={
                          lead.editable_fields &&
                          !lead.editable_fields.lead.includes("permanent_addr3")
                        }
                        value={lead.permanent_addr3 || (editMode ? "" : "   ")}
                        error={errorList.permanent_addr3}
                        onChange={handleChange}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={2} md={2}>
                    <FormControl variant={fieldVariant} fullWidth>
                      <TextField
                        label="City"
                        margin={fieldMargin}
                        name="permanent_city"
                        InputProps={{
                          readOnly: true,
                        }}
                        disabled={
                          editMode
                          //lead.editable_fields &&
                          //!lead.editable_fields.lead.includes("permanent_city")
                        }
                        value={lead.permanent_city || (editMode ? "" : "   ")}
                        error={errorList.permanent_city}
                        onChange={handleChange}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={2} md={2}>
                    <FormControl variant={fieldVariant} fullWidth>
                      <TextField
                        label="State"
                        margin={fieldMargin}
                        name="permanent_state"
                        InputProps={{
                          readOnly: true,
                        }}
                        disabled={
                          editMode
                          //lead.editable_fields &&
                          //!lead.editable_fields.lead.includes("permanent_state")
                        }
                        value={lead.permanent_state || (editMode ? "" : "   ")}
                        error={errorList.permanent_state}
                        onChange={handleChange}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={2} md={2}>
                    <FormControl variant={fieldVariant} fullWidth>
                      <TextField
                        label="Country"
                        margin={fieldMargin}
                        name="permanent_country"
                        InputProps={{
                          readOnly: true,
                        }}
                        disabled={
                          editMode
                          //lead.editable_fields &&
                          //!lead.editable_fields.lead.includes(
                          //  "permanent_country"
                          //)
                        }
                        value={
                          lead.permanent_country || (editMode ? "" : "   ")
                        }
                        error={errorList.permanent_country}
                        onChange={handleChange}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={2} md={2}>
                    {editMode ? (
                      lead.nationality === 1 ? (
                        <ItemSelectionAsync
                          disabled={
                            lead.editable_fields &&
                            !lead.editable_fields.lead.includes(
                              "permanent_pincode"
                            )
                          }
                          required
                          size={fieldSize}
                          label="Pincode"
                          value={
                            lead.permanent_pincode
                              ? {
                                pincode: parseInt(lead.permanent_pincode),
                                state: lead.permanent_state,
                                city: lead.permanent_city,
                              }
                              : null
                          }
                          optionLabel="pincode"
                          labelTemplate={(option) => option.pincode}
                          error={errorList.permanent_pincode}
                          style={{ zIndex: 500, marginTop: 4, marginLeft: 0 }}
                          formClass={classes.formAutoClass}
                          selected={(value) => {
                            if (
                              lead.current_addr_same &&
                              lead.current_addr_same === "Y"
                            ) {
                              handleChangeObject({
                                permanent_pincode: value.pincode,
                                permanent_city: value.city,
                                permanent_state: value.state,
                                current_pincode: value.pincode,
                                current_city: value.city,
                                current_state: value.state,
                              });
                              setErrorList((e) => ({
                                ...e,
                                permanent_pincode: !value,
                                permanent_city: !value.city,
                                permanent_state: !value.state,
                                current_pincode: !value,
                                current_city: !value.city,
                                current_state: !value.state,
                              }));
                            } else {
                              handleChangeObject({
                                permanent_pincode: value.pincode,
                                permanent_city: value.city,
                                permanent_state: value.state,
                              });
                              setErrorList((e) => ({
                                ...e,
                                permanent_pincode: !value,
                                permanent_city: !value.city,
                                permanent_state: !value.state,
                              }));
                            }
                          }}
                          renderOption={(option) => {
                            return (
                              <Grid container alignItems="center">
                                <Grid item>
                                  <LocationOnIcon className={classes.icon} />
                                </Grid>
                                <Grid item xs>
                                  <span style={{ fontWeight: 400 }}>
                                    {option.pincode}
                                  </span>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                  >
                                    {`${option.city}, ${option.state}`}
                                  </Typography>
                                </Grid>
                              </Grid>
                            );
                          }}
                        />
                      ) : (
                        <FormControl variant={fieldVariant} fullWidth>
                          <TextField
                            label="Pincode"
                            margin={fieldMargin}
                            name="permanent_pincode"
                            InputProps={{
                              readOnly: disabled,
                            }}
                            disabled={
                              lead.editable_fields &&
                              !lead.editable_fields.lead.includes(
                                "permanent_pincode"
                              )
                            }
                            value={
                              lead.permanent_pincode || (editMode ? "" : "   ")
                            }
                            error={errorList.permanent_pincode}
                            onChange={(e) => {
                              e.persist();
                              if (
                                lead.current_addr_same &&
                                lead.current_addr_same === "Y"
                              ) {
                                handleChangeObject({
                                  permanent_pincode: e.target.value,
                                  current_pincode: e.target.value,
                                });
                                setErrorList((e) => ({
                                  ...e,
                                  permanent_pincode: !e.target.value,
                                  current_pincode: !e.target.value,
                                }));
                              } else {
                                handleChangeObject({
                                  permanent_pincode: e.target.value,
                                });
                                setErrorList((e) => ({
                                  ...e,
                                  permanent_pincode: !e.target.value,
                                }));
                              }
                            }}
                            aria-describedby="outlined-weight-helper-text"
                            variant={fieldVariant}
                          />
                        </FormControl>
                      )
                    ) : (
                      <FormControl variant={fieldVariant} fullWidth>
                        <TextField
                          label="Pincode"
                          margin={fieldMargin}
                          name="permanent_pincode"
                          InputProps={{
                            readOnly: disabled,
                          }}
                          value={
                            lead.permanent_pincode || (editMode ? "" : "   ")
                          }
                          error={errorList.permanent_pincode}
                          onChange={handleChange}
                          aria-describedby="outlined-weight-helper-text"
                          variant={fieldVariant}
                        />
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item xs={12} >
                    <Divider />
                  </Grid>
                  <Grid item xs={12} lg={3} md={3}>
                    <Typography variant="h6">
                      Reason for rejection
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={3} md={3}>
                    <Typography component="div">
                      <ul>{lead.closure_reasons ? getReasons() : '-'}
                      </ul>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} >
                    <Divider />
                  </Grid>
                  <Grid item xs={12} lg={3} md={3}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Client has holdings?</FormLabel>
                      <RadioGroup
                        row
                        aria-label="holdings_exist"
                        name="holdings_exist"
                        value={lead.holdings_exist}
                      >
                        <FormControlLabel
                          disabled={
                            lead.editable_fields &&
                            !lead.editable_fields.lead.includes(
                              "holdings_exist"
                            )
                          }
                          value={true}
                          control={<Radio color="default" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          disabled={
                            lead.editable_fields &&
                            !lead.editable_fields.lead.includes(
                              "holdings_exist"
                            )
                          }
                          value={false}
                          control={<Radio color="default" />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {
                    lead.holdings_exist ? (
                      <Grid item xs={12} lg={3} md={3}>
                        <FormControl component="fieldset" disabled={editMode}>
                          <FormLabel component="legend">
                            Perform the following for the holdings
                          </FormLabel>
                          <RadioGroup
                            aria-label="holdings_to_be"
                            name="holdings_to_be"
                            value={lead.holdings_to_be}
                            onChange={(e) => {
                              if (!disabled)
                                handleChangeKV("holdings_to_be", e.target.value);
                            }}
                          >
                            <FormControlLabel
                              disabled={
                                lead.editable_fields &&
                                !lead.editable_fields.lead.includes("holdings_to_be")
                              }
                              value="remat"
                              control={<Radio color="default" />}
                              label={HOLDINGS_OPTIONS.remat}
                            />
                            <FormControlLabel
                              disabled={
                                lead.editable_fields &&
                                !lead.editable_fields.lead.includes("holdings_to_be")
                              }
                              value="partly"
                              control={<Radio color="default" />}
                              label={HOLDINGS_OPTIONS.partly}
                            />
                            <FormControlLabel
                              disabled={
                                lead.editable_fields &&
                                !lead.editable_fields.lead.includes("holdings_to_be")
                              }
                              value="transfer"
                              control={<Radio color="default" />}
                              label={HOLDINGS_OPTIONS.transfer}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    ) : null}
                  {
                    ["partly", "transfer"].includes(lead.holdings_to_be) ? (
                      <Grid
                        item
                        xs={12}
                        lg={6}
                        md={6}
                        container
                        justify="flex-start"
                        spacing={1}
                        alignItems="center"
                      >
                        <Grid item xs={12} lg={6} md={6}>
                          <FormControl variant={fieldVariant} fullWidth>
                            <TextField
                              label="Target DP Id"
                              margin={fieldMargin}
                              name="target_dp_id"
                              InputProps={{
                                readOnly: disabled,
                              }}
                              value={lead.target_dp_id || (editMode ? "" : "   ")}
                              error={errorList.target_dp_id}
                              onChange={handleChange}
                              aria-describedby="outlined-weight-helper-text"
                              variant={fieldVariant}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={6} md={6}>
                          <FormControl variant={fieldVariant} fullWidth>
                            <TextField
                              label="Target Client ID"
                              margin={fieldMargin}
                              name="target_dp_client_id"
                              InputProps={{
                                readOnly: disabled,
                              }}
                              value={lead.target_dp_client_id || (editMode ? "" : "   ")}
                              error={errorList.target_dp_client_id}
                              onChange={handleChange}
                              aria-describedby="outlined-weight-helper-text"
                              variant={fieldVariant}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    ) : null}
                </Grid>
              </Grid>
            }
            <Grid item xs={12} lg={12}>
              <Divider />
            </Grid>
            {
              <Grid
                item
                xs={12}
                lg={12}
                container
                justify="flex-start"
                spacing={1}
                alignItems="flex-start"
              >
                <Grid item xs={12} lg={2} md={2}>
                  <Typography variant="h5" className={classes.title1}>
                    E-Sign
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={10}
                  container
                  direction="row"
                  justify="flex-start"
                  spacing={1}
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={12}
                    lg={12}
                    container
                    direction="row"
                    justify="flex-start"
                    spacing={2}
                    alignItems="center"
                  >
                    <Grid item>
                      <FormControl component="fieldset" disabled={editMode}>
                        <FormLabel component="legend">E-Signed?</FormLabel>
                        <RadioGroup
                          row
                          aria-label="aadhaar_mobile_linked"
                          name="aadhaar_mobile_linked"
                          value={lead.aadhaar_mobile_linked}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            disabled={editMode || !disabled}
                            value={1}
                            control={<Radio color="default" />}
                            label="Yes"
                          />
                          <FormControlLabel
                            disabled={editMode || !disabled}
                            value={0}
                            control={<Radio color="default" />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    {proofs.has("application_form") && (
                      <Grid item>
                        <Button
                          style={{ color: blue[500], marginTop: 8 }}
                          onClick={() =>
                            openLink(proofs.get("application_form").url)
                          }
                        >
                          View Application Form
                        </Button>
                      </Grid>
                    )}
                    {lead.status === STATUS.ESIGN_REQUESTED &&
                      [USERTYPES.KYC].includes(state.user.EmpUtype) &&
                      lead.esign_url && (
                        <Grid item>
                          <div style={{ marginTop: 12 }}>
                            <Typography
                              variant="caption"
                              component="p"
                              style={{
                                border: "1px dotted black",
                                padding: 4,
                                borderRadius: 4,
                                width: 200,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "inline-block",
                              }}
                            >
                              {lead.esign_url}
                            </Typography>

                            <FileCopyIcon
                              style={{ marginBottom: 2, marginLeft: 8 }}
                              fontSize="small"
                              onClick={() => {
                                if ("clipboard" in navigator) {
                                  navigator.clipboard.writeText(lead.esign_url);
                                } else {
                                  document.execCommand(
                                    "copy",
                                    true,
                                    lead.esign_url
                                  );
                                }
                                showSnackBar("Copied", "success");
                              }}
                            />
                          </div>
                        </Grid>
                      )}
                  </Grid>
                  {lead.aadhaar_mobile_linked === 0 &&
                    ![
                      STATUS.ESIGN_REQUESTED,
                      STATUS.ONGOING,
                      STATUS.APPROVED,
                      STATUS.ACTIVATED,
                    ].includes(lead.status) && (
                      <Grid
                        container
                        item
                        xs={12}
                        lg={6}
                        md={6}
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item xs={12} lg={8} md={8}>
                          <FormControl variant={fieldVariant} fullWidth>
                            <TextField
                              label="Tracking Details"
                              margin={fieldMargin}
                              style={{ marginTop: 4 }}
                              name="tracking_details"
                              value={lead.tracking_details || ""}
                              error={errorList.tracking_details}
                              onChange={handleChange}
                              aria-describedby="outlined-weight-helper-text"
                              variant={fieldVariant}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item>
                          {!editMode && (
                            <Button variant="outlined" onClick={updateTracking}>
                              Update
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    )}
                </Grid>
              </Grid>
            }
          </Grid>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

ViewLeadDialog.prototype = {
  open: PropTypes.bool.isRequired,
  id: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

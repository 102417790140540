import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Doughnut } from 'react-chartjs-2';
import { makeStyles, useTheme } from '@material-ui/styles';
import { formatQty, formatNumber } from '../../Utils';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative'
  }
}));

const Chart = props => {
  const { data: dataProp, type, onClick, className, ...rest } = props;

  const classes = useStyles();
  const theme = useTheme();

  const data = {
    datasets: [
      {
        data: [],
        backgroundColor: [],
        borderWidth: 6,
        borderColor: theme.palette.white,
        hoverBorderColor: theme.palette.white
      }
    ],
    labels: []
  };

  for (const item of dataProp) {
    if(item.value > 0){
      data.datasets[0].data.push(item.value);
      data.datasets[0].backgroundColor.push(item.color);
      data.labels.push(item.label);
    }
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    cutoutPercentage: 80,
    legend: {
      display: false
    },
    layout: {
      padding: 0
    },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      caretSize: 10,
      yPadding: 20,
      xPadding: 20,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.black,
      titleFontColor: theme.palette.white,
      bodyFontColor: theme.palette.white,
      footerFontColor: theme.palette.white,
      callbacks: {
        label: function(tooltipItem, data) {
          const label = data['labels'][tooltipItem['index']];
          const value = data['datasets'][0]['data'][tooltipItem['index']];

          // return `${label}: ${value}`;

          let l = `${label}: ${value}`;
          if(type === 'units'){
            l = `${label}: ${formatQty(value)} Units`;
          } else if(type === 'sales'){
            l = `${label}: ${formatNumber(value)}`;
          }
          return l;
        }
      }
    }
  };

  const onChartClick = (elems) => {
    if(elems.length > 0){
      if(onClick){
        onClick(elems[0]._index);
      }
    }
  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Doughnut
        data={data}
        options={options}
        onElementsClick={onChartClick}
      />
    </div>
  );
};

Chart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired
};

export default Chart;

import React from "react";
import { useContext } from "react";
import { AppContext } from "../AppContextProvider";
import { makeStyles, Grid, Paper, Table, TableRow, TableBody, Typography, Divider, IconButton, Fab, Checkbox } from "@material-ui/core";
import { blue, green, red } from "@material-ui/core/colors";
import { DROPDOWN_TYPES } from "../Constants";
import EnhancedTableHead from "../components/EnhancedTableHead";
import { StyledTableCell, BootstrapTooltip } from "../Utils";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ItemSelection from "../components/ItemSelection";
import { MasterService } from "../Services/MasterService";
import EditIcon from '@material-ui/icons/Edit';
import AddDropdown from "./AddDropdown";

const useStyles = makeStyles(theme => ({
    extendedIcon: {
        position: 'fixed',
        right: 30,
        bottom: '5%',
    },
    eIcon: {
        marginRight: theme.spacing(1),
    },
    section: {
        width: '100%',
        height: '100%',
        marginTop: theme.spacing(1),
        paddingTop : theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    qtyField: {
        width: "40%",
        position: "relative",
        [theme.breakpoints.up('md')]: {
            right: '100px',
            width: "10%",
            marginRight: 10
        },
        right: "22px"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom : '20px',
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px'
    },
    icon: {
        padding: '0px',
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
    },
    tablecell: {
        fontSize: '16pt',
        padding: theme.spacing(4)
    },
    container: {
        paddingTop : theme.spacing(1)
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    blueAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: blue[500],
    },
    instructions: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(1),
        // height: theme.spacing(7),
        // float: 'right',
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            // float: 'inherit',
            marginLeft: theme.spacing(1)
        },
        marginRight: theme.spacing(4),
        
    }, 
    tableWrapper: {
        maxHeight: 407,
        overflow: 'auto',
    },
    list: {
        width: '100%',
        height: 250,
        overflow: "auto"
    },
    summary: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    title: {
        fontSize: 18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subtitle: {
        fontSize:18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    selectRoot: {
        fontSize: 12,
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            marginRight: 0
        },
    },
    green: {
        color: green[700],
    },
    red: {
        color: red[500],
    }
}));

export default function Dropdowns(props){
    const { setTitle, showConfirmDialog, closeConfirmDialog, showSnackBar, showProgressDialog, closeProgressDialog, showConfirmDialog1D, closeConfirmDialog1D } = useContext(AppContext);
    const classes = useStyles();
    const [data, setData] = React.useState([]);
    const [selectedType, setSelectedType] = React.useState(null);
    const [selectedOption, setSelectedOption] = React.useState(null);
    const [addOpen, setAddOpen] = React.useState(false);
    const columns = [
        { id: 'sl_no', align: 'left', numeric: false, disablePadding: false, label: 'S.No.', sortable: false },
        { id: 'description', align: 'justify', numeric: false, disablePadding: false, label: 'Label', sortable: false },
        { id: 'created_date', align: 'justify', numeric: false, disablePadding: false, label: 'Value', sortable: false },
        { id: 'status', align: 'center', numeric: false, disablePadding: true, label: 'Default Option', sortable: false },
        { id: 'action', align: 'left', numeric: false, disablePadding: true, label: '', sortable: false },
    ];

    
    React.useEffect(() => {
        setTitle('Manage Dropdown Options');
    }, [true]);

    React.useEffect(() => {
        if(selectedType && selectedType.value){
            setData([]);
            fetchData()
        } else {
            setData([]);
        }
    }, [selectedType]);

    const fetchData = (showProgress = true) => {
        if(showProgress){
            showProgressDialog();
        }
        MasterService.getMasterDropdowns(selectedType.value)
        .then(data => {
            console.log(data);
            if(data[selectedType.value]){
                setData(data[selectedType.value]);
            } else {
                setData([]);
            }
        })
        .catch(error => {
            console.log(error);
            showConfirmDialog1D('Something Went wrong. Please try again.', undefined, () => {
                closeConfirmDialog1D();
            }, 'OK')
        })
        .finally(() => {
            if(showProgress) {
                closeProgressDialog(false)
            }
        });
    }

    const removeDropdownOption = (id) => {
        showConfirmDialog('Confirm Remove', undefined, () => {
            closeConfirmDialog()
            MasterService.removeDropdownOption(id)
            .then(() => {
                showSnackBar('Removed Option', 'info')
                fetchData()
            })
            .catch(error => {
                console.log(error)
                showSnackBar('Something went wrong. Please try again.', 'error')
            })
        })
    }
    
    const handleDefaultChange = (id, checked) => {
        showConfirmDialog(`Confirm changes?`, undefined, () => {
            closeConfirmDialog()
            MasterService.markDefault({id: id, is_default: checked})
            .then(() => {
                showSnackBar('Saved Changes!!', 'info')
                fetchData(false)
            })
            .catch(error => {
                console.log(error)
                showSnackBar('Something went wrong. Please try again.', 'error')
            })
        })
    }

    const add = (option) => {
        setAddOpen(true);
        setSelectedOption(option ? {type: selectedType.value, ...option} : {type: selectedType.value});
    }

    return (
        <div className="Home">
            <div className="lander">
                <Grid container direction="row" spacing={1} justify="center">
                    <Grid item xl={10} lg={12} xs={12}>
                        <Paper className={classes.section}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={12} md={4}>
                                    <ItemSelection
                                        clearable={true} 
                                        label='Dropdown Type' 
                                        value={selectedType} 
                                        optionLabel='label' 
                                        options={DROPDOWN_TYPES} 
                                        style={{marginBottom: 16, marginRight: 16 }}
                                        selected={(property) => {
                                            setSelectedType(property);
                                        }}/>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <Divider style={{marginBottom: 0, marginTop: 10}}/>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <div style={{minHeight: 100, overflowY: 'auto', display: 'block'}}>
                                        <Table className={classes.table} stickyHeader>
                                            <EnhancedTableHead
                                                order={"asc"}
                                                orderBy={"label"}
                                                onRequestSort={() => {}}
                                                rows={columns} />
                                            <TableBody>
                                                {
                                                    data && data.map((element, index) => (
                                                        <TableRow 
                                                            key={index} 
                                                            hover
                                                            style={{cursor: 'pointer'}}>
                                                            <StyledTableCell scope="row" align="center" padding='none' style={{width: 130}}>
                                                                {index+1}
                                                            </StyledTableCell>
                                                            <StyledTableCell scope="row" align="left" padding='none'>
                                                                {element.label || '-'}
                                                            </StyledTableCell>
                                                            <StyledTableCell scope="row" align="left" padding='none'>
                                                                {element.value || '-'}
                                                            </StyledTableCell>
                                                            <StyledTableCell scope="row" align="center" padding='none'>
                                                                <Checkbox 
                                                                    color="default" 
                                                                    checked={element ? element.is_default : false} 
                                                                    onChange={(event) => handleDefaultChange(element.dropdown_id, event.target.checked)} 
                                                                    name="is_default" />
                                                            </StyledTableCell>
                                                            <StyledTableCell scope="row" align="left" padding='none' style={{width: '10vw'}}>
                                                                <BootstrapTooltip title="Edit Option" arrow>
                                                                    <IconButton size="small" style={{marginRight: 8}} onClick={() => element && add(element)}>
                                                                        <EditIcon />
                                                                    </IconButton>
                                                                </BootstrapTooltip>
                                                                <BootstrapTooltip title="Remove Option" arrow>
                                                                    <IconButton size="small" style={{marginRight: 8}} onClick={() => element && removeDropdownOption(element.dropdown_id)}>
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </BootstrapTooltip>
                                                            </StyledTableCell>
                                                        </TableRow>
                                                    )
                                                )}
                                            </TableBody>
                                        </Table>
                                    </div>
                                    {
                                        data.length === 0 && 
                                            <Typography color="error" variant="subtitle2">No Options</Typography>
                                    }
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
            <Fab variant="extended" color="primary" className={classes.extendedIcon} onClick={() => add(null)} disabled={!selectedType}>
                <AddIcon className={classes.eIcon} />
                Add Option
            </Fab>
            <AddDropdown
                {...props} 
                onError={() => {
                    //setAddProductOpen(false);
                    //setSelectedProduct(null);
                }} 
                onSuccess={() => {
                    setAddOpen(false);
                    setSelectedOption(null);
                    fetchData()
                }}
                onCancel={() => setAddOpen(false)} 
                open={addOpen} 
                option={selectedOption} />
        </div>
    )
}
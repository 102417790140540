import { Button, Card, CardContent, CardHeader, colors, Divider, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CloudDownload } from "@material-ui/icons";
import PublishIcon from '@material-ui/icons/Publish';
import clsx from 'clsx';
import { upperCase } from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../AppContextProvider';
import ItemSelection from "../components/ItemSelection";
import SingleDate from "../components/SingleDate";
import { LeadService } from "../Services/LeadService";
import { MasterService } from '../Services/MasterService';
import { format, openLink } from "../Utils";

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        float: 'left'
    },
    root: {},
    saveButton: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    actions: {
        float: 'right',
    },
    stateSelection: {
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1),
        },
    }
}));

const TYPES = {
    DEPOSITORY: "DEPOSITORY"
}

const DEPOSITORY_TYPES = [
    {
        label: "CDSL",
        value: "CDSL"
    },
    {
        label: "NSDL",
        value: "NSDL"
    },
]

const SERVICE_TYPES = [
    {
        label: "CKYC",
        value: "ckyc",
        type: 'kyc'
    },
    {
        label: "KRA",
        value: "kra",
        type: 'kyc'
    },
    {
        label: "KRA",
        value: "kra",
        type: 'rekyc'
    }
]

function Utility(props) {

    const classes = useStyles()
    const { setTitle, showProgressDialog, showSnackBar, closeProgressDialog, showConfirmDialog, closeConfirmDialog } = useContext(AppContext);
    const hiddenFileInput = React.useRef(null);
    const [selectedType, setSelectedType] = useState(DEPOSITORY_TYPES[0])
    const [selectedExportType, setSelectedExportType] = useState(SERVICE_TYPES[0])
    const [startdate, setStartDate] = React.useState(moment().add(-1, "month").startOf('day'));
    const [enddate, setEndDate] = React.useState(moment().endOf('day'));

    useEffect(() => {
        setTitle("Import / Export Utility")

        return () => {
            setTitle("")
        }
    }, []);

    const uploadFile = type => (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            showConfirmDialog("Confirm Upload?", undefined, () => {
                closeConfirmDialog();
                if (type === TYPES.DEPOSITORY) {
                    const fileUploaded = files[0];
                    const formData = new FormData();
                    formData.append('file', fileUploaded || null);
                    formData.append('dp', selectedType.value)
                    showProgressDialog('Uploading...');
                    MasterService.uploadDepository(formData, {})
                        .then(data => {
                            if (data.success) {
                                showSnackBar(data.message, "success")
                            } else {
                                showSnackBar(data.error, "error")
                            }
                        })
                        .catch(error => {
                            showSnackBar('Something went wrong. Please try again later.', 'error');
                        })
                        .finally(() => closeProgressDialog())
                }
            });
        }
    }

    const exportFile = () => {
        console.log(selectedExportType)
        showProgressDialog('Requesting...');
        LeadService.activationTaskReport(moment(startdate).startOf('d').utc().format(format), moment(enddate).endOf('d').utc().format(format), selectedExportType.type, selectedExportType.value)
            .then((data) => {
                if (data.success) {
                    if (data.file_url) {
                        openLink(data.file_url)
                    }
                    showSnackBar(data.message, "success")
                } else {
                    showSnackBar(data.error, "error")
                }
            })
            .catch(error => {
                console.log(error);
                showSnackBar('Failed to Download. Please try again', 'error')
            })
            .finally(() => {
                closeProgressDialog();
            });
    }

    return (
        <div className="Home">
            <div className="lander">
                <div >
                    <Grid container direction="row" spacing={4} justify="center">
                        <Grid item xl={10} lg={10} xs={12}>
                            <Card className={clsx(classes.root)}>
                                <CardHeader
                                    title="Import Depository IDs"
                                    titleTypographyProps={{ align: 'left' }} />
                                <Divider />
                                <CardContent>
                                    <Grid container justify="space-between" alignItems="center" spacing={1}>
                                        <Grid item xs={12} md={3}>
                                            <Typography variant="body1" align="left" style={{ padding: 12, color: 'black' }}>Select a file to upload</Typography>
                                        </Grid>
                                        <Grid item>
                                            <div style={{ width: '20vw', marginTop: -8 }}>
                                                <ItemSelection
                                                    label='Type'
                                                    value={selectedType}
                                                    optionLabel='label'
                                                    autoCompleteClass={classes.autoComplete}
                                                    options={DEPOSITORY_TYPES}
                                                    selected={(s) => {
                                                        setSelectedType(s);
                                                    }} />
                                            </div>
                                        </Grid>
                                        <Grid item xs/>
                                        <Grid item xs/>
                                        <Grid item xs>
                                            <input accept="*/*" type="file" onChange={uploadFile(TYPES.DEPOSITORY)} ref={hiddenFileInput} onClick={(event) => event.target.value = null} style={{ display: 'none' }} />
                                            <Button startIcon={<PublishIcon />} variant="contained" onClick={() => hiddenFileInput.current.click()} color="secondary">Upload</Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xl={10} lg={10} xs={12}>
                            <Card className={clsx(classes.root)}>
                                <CardHeader
                                    title="Export Logs"
                                    titleTypographyProps={{ align: 'left' }} />
                                <Divider />
                                <CardContent>
                                    <Grid container justify="space-between" alignItems="center" spacing={1}>
                                        <Grid item xs={12} md={3}>
                                            <Typography variant="body1" align="left" style={{ padding: 12, color: 'black' }}>Select type</Typography>
                                        </Grid>
                                        <Grid item>
                                            <div style={{ width: '20vw', marginTop: -8 }}>
                                                <ItemSelection
                                                    label='Type'
                                                    value={selectedExportType}
                                                    groupBy={(option) => upperCase(option.type)}
                                                    optionLabel='label'
                                                    autoCompleteClass={classes.autoComplete}
                                                    options={SERVICE_TYPES}
                                                    selected={(s) => {
                                                        setSelectedExportType(s);
                                                    }} />
                                            </div>
                                        </Grid>
                                        <Grid item>
                                            <div style={{ maxWidth: 200, paddingLeft: 8 }}>
                                                <SingleDate
                                                    label="From"
                                                    date={startdate}
                                                    margin="dense"
                                                    maxDate={moment()}
                                                    setDate={(date) => {
                                                        setStartDate(date);
                                                    }} />
                                            </div>
                                        </Grid>
                                        <Grid item>
                                            <div style={{ maxWidth: 200, marginLeft: 4 }}>
                                                <SingleDate
                                                    label="To"
                                                    date={enddate}
                                                    margin="dense"
                                                    minDate={startdate}
                                                    maxDate={moment()}
                                                    setDate={(date) => {
                                                        setEndDate(date);
                                                    }} />
                                            </div>
                                        </Grid>
                                        <Grid item xs>
                                            <Button startIcon={<CloudDownload />} variant="contained" onClick={exportFile} color="secondary">Export</Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    )
}

export default Utility;
import React, { useContext, useState } from "react";
import { Card, CardHeader, Divider, CardContent, makeStyles, Select, MenuItem, Input, CircularProgress, Grid, List, ListItem, ListItemText, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import clsx from 'clsx';
import { DonutChart } from '../components/Chart';
import moment from "moment";
import { colorGenerator, format, getFinancialYearDates } from '../Utils';
import { LeadService } from "../Services/LeadService";
import { STATUS, USERTYPES } from "../Constants";
import { AppContext } from "../AppContextProvider";

const useStyles = makeStyles(theme => ({
    root: {
        margin: 5,
        minHeight: 190,
    },
    icon: {
        padding: 2
    },
    content: {
        paddingTop: 0,
        paddingBottom: 0,
        minHeight: 200,
        marginTop: 0,
        paddingRight: 0,
        paddingLeft: 0,
    },
    itemDivider: {
        borderBottomColor: 'rgba(0,0,0,0.1)'
    },
    actions: {
        paddingTop: 0,
        justifyContent: 'flex-end'
    },
    arrowForwardIcon: {
        marginLeft: theme.spacing(1)
    },
    select: {
        marginTop: 8,
        marginLeft: 8
    },
    loader: {
        marginTop: 40
    },
    chartContainer: {
        padding: theme.spacing(3)
    },
    chart: {
        height: 170
    },
    nodata: {
        position: 'relative',
        top: 40
    }
}));

const TopRankingCities = ({ forwardedRef, ...props }) => {
    const { state } = useContext(AppContext);
    const classes = useStyles();
    const count = 5;
    const { report, className, count: counter } = props;
    // const [isShown, setIsShown] = useState(false);
    const [data, setData] = useState([]);
    const [range, setRange] = React.useState(props.range || 'today');
    const [loading, setLoading] = useState(false);
    const colors = colorGenerator(count);
    const rangeOptions = [
        {
            label: 'Today',
            value: 'today',
            startdate: moment().startOf('d'),
            enddate: moment().endOf('d')
        },
        {
            label: 'Last 7 Days',
            value: 'last_7',
            startdate: moment().startOf('d').add(-7, 'days'),
            enddate: moment().endOf('d')
        },
        {
            label: 'Last 30 Days',
            value: 'last_30',
            startdate: moment().startOf('d').add(-30, 'days'),
            enddate: moment().endOf('d')
        },
        {
            label: 'This Financial Year',
            value: 'this_year',
            ...getFinancialYearDates()
        }
    ];

    React.useEffect(() => {
        let selectedRange = rangeOptions.filter(r => r.value === range)[0];
        setLoading(true);
        let referral_ids = null;
        if ([USERTYPES.FRAN, USERTYPES.RM, USERTYPES.BRANCH].includes(state.user.EmpUtype)) {
            referral_ids = state.user.EmpCode
        }
        const controller = new AbortController()
        LeadService.getTopLocations('city', moment(selectedRange.startdate).utc().format(format), moment(selectedRange.enddate).utc().format(format), STATUS.ACTIVATED, count, referral_ids, controller.signal)
            .then(data => {
                let chartData = [];
                if (data.stats) {
                    data.stats.forEach((d, i) => {
                        chartData.push({
                            id: i,
                            label: d.location,
                            value: d.count,
                            state: d.state,
                            color: colors[i]
                        });
                    })
                }
                setData(chartData);
                setLoading(false);
            })
            .catch(error => {
                if (!controller.signal.aborted) {
                    console.log(error);
                }
                setLoading(false);
            })
        return () => controller.abort()
    }, [range, counter]);


    return (
        <Grid item xl={4} lg={6} xs={12}>
            <Card
                ref={forwardedRef}
                className={clsx(classes.root, className)}
            >
                <CardHeader
                    title={report.name}
                    className={classes.griditem}
                    titleTypographyProps={{ align: 'left' }}
                    action={
                        <>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select-2"
                                value={range}
                                onChange={(e) => setRange(e.target.value)}
                                className={classes.select}
                                input={<Input disableUnderline={true} />}>
                                {rangeOptions.map(r => <MenuItem key={r.value} value={r.value}>{r.label}</MenuItem>)}
                            </Select>
                        </>
                    } />
                <Divider />
                <CardContent className={classes.content}>
                    {
                        loading ?
                            <div className={classes.loader}>
                                <CircularProgress />
                            </div>
                            :
                            <div className={classes.chartContainer}>
                                {
                                    data.length === 0 ?
                                        (
                                            <div className={classes.nodata}>
                                                No Data
                                            </div>
                                        )
                                        :
                                        (
                                            <div>
                                                <DonutChart
                                                    className={classes.chart}
                                                    data={data}
                                                />
                                                <List>
                                                    {data.map((data, i) => (
                                                        <ListItem
                                                            classes={{ divider: classes.itemDivider }}
                                                            divider
                                                            dense
                                                            key={data.id}
                                                        >
                                                            <ListItemText
                                                                primary={data.label}
                                                                primaryTypographyProps={{ color: 'inherit', variant: 'subtitle2' }}
                                                                secondary={data.state || ''}
                                                            />
                                                            <Typography color="inherit">{data.value}</Typography>
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </div>
                                        )
                                }
                            </div>
                    }
                </CardContent>
            </Card>
        </Grid>
    );
};

TopRankingCities.propTypes = {
    report: PropTypes.object.isRequired,
};

export default TopRankingCities;
import React, { useContext } from 'react'
import { Toolbar, Typography, makeStyles } from '@material-ui/core'
import { AppContext } from '../AppContextProvider'
import { LeadDialogContext } from './ViewLeadDialog'
import { GreenButton, RedButton } from '../components/ColorButton'
import { STATUS, USERTYPES } from '../Constants'

const useStyles = makeStyles(() => ({
    grow: {
        flexGrow: 1
    },
}))


const ToolbarBottom = () => {
    const { mobileView, state } = useContext(AppContext)
    const { summary, editMode, action, lead } = useContext(LeadDialogContext)
    const classes = useStyles()
    return (
        <Toolbar style={{ minHeight: 80 }}>
            <div style={{ marginLeft: mobileView ? 0 : 16 }}>
                {summary && (
                    <>
                        {summary.title && (
                            <Typography variant="h6">
                                {summary.title}
                            </Typography>
                        )}
                        {summary.remarks && (
                            <Typography variant="body2">
                                {summary.remarks}
                            </Typography>
                        )}
                        {summary.subtitle && (
                            <Typography variant="caption">
                                {summary.subtitle}
                            </Typography>
                        )}
                    </>

                )}
            </div>
            <div className={classes.grow} />
            {!editMode && state.user && ([USERTYPES.KYCHO, USERTYPES.KYC].includes(state.user.EmpUtype)) && summary.btnReject && (
                <RedButton onClick={action('reject')} style={{ marginRight: 8 }}>Reject</RedButton>
            )}
            {!editMode && state.user && ([USERTYPES.KYCHO, USERTYPES.KYC].includes(state.user.EmpUtype)) && summary.btnApprove && (
                <GreenButton onClick={action('approve')}>Approve</GreenButton>
            )}
            {!editMode && state.user && ([USERTYPES.KYCHO, USERTYPES.KYC].includes(state.user.EmpUtype)) && lead.status === STATUS.APPROVED && lead.can_activate && (
                <GreenButton onClick={action('activate')}>Activate</GreenButton>
            )}
        </Toolbar>
    );
}

export default ToolbarBottom;
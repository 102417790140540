import Config from '../Config';
import { RestService } from './RestService';

export const MasterService = {

    getSegments: async function () {
        return RestService.get(Config.segments_url, {})
            .then(response => response.json());
    },
    importantLinks: async function () {
        return RestService.get(Config.important_links_url, {})
            .then(response => response.json());
    },
    fetchPANName: async function (pan = null) {
        return RestService.get(`${Config.fetch_pan_name_url}?pan_no=${pan}`, {})
            .then(response => response.json());
    },
    fetchDataInconsistency: async function (code = null) {
        return RestService.get(Config.data_inconsistency.replace('{code}', code), {})
            .then(response => response.json());
    },
    aadhaarPANLinked: async function (pan = null, aadhaar = null) {
        return RestService.get(`${Config.verify_aadhaar_pan_linked}?pan=${pan}&aadhaar=${aadhaar}`, {})
            .then(response => response.json());
    },
    getDropdownOptions: async function (type = null) {
        return RestService.get(`${Config.master_dropdown_url}?type=${type}`, {})
            .then(response => response.json());
    },
    getPincodeList: async function (filter) {
        return RestService.get(`${Config.pincode_url}?filter=${filter}`, {})
            .then(response => response.json());
    },
    getCityList: async function (filter) {
        return RestService.get(`${Config.city_url}?filter=${filter}`, {})
            .then(response => response.json());
    },
    getLocations: async function () {
        return RestService.get(Config.state_mapping_url, {})
            .then(response => response.json());
    },
    getReferralTypes: async function () {
        return RestService.get(Config.referral_types_url, {})
            .then(response => response.json());
    },
    getTransactionTypes: async function () {
        return RestService.get(Config.transaction_types_url, {})
            .then(response => response.json());
    },
    getJobTypes: async function () {
        return RestService.get(Config.job_types_url, {})
            .then(response => response.json());
    },
    getPennydropTxnCount: async function (filter = '', fromDate = null, toDate = null) {
        return RestService.get(`${Config.pennydrop_logs_url}?count=true${fromDate ? `&from_date=${fromDate}` : ''}${toDate ? `&to_date=${toDate}` : ''}${filter ? `&filter=${filter}` : ''}`, {})
            .then(response => response.json());
    },
    getPennydropTxn: async function (filter = '', fromDate = null, toDate = null, from = 0, offset = 20, order = null, direction = null) {
        return RestService.get(`${Config.pennydrop_logs_url}?${fromDate ? `&from_date=${fromDate}` : ''}${toDate ? `&to_date=${toDate}` : ''}${filter ? `&filter=${filter}` : ''}${from ? `&from=${from}` : ''}${offset ? `&offset=${offset}` : ''}${order ? `&order=${order}` : ''}${direction ? `&direction=${direction}` : ''}`, {})
            .then(response => response.json());
    },
    getPennydropTxnExport: async function (filter = '', fromDate = null, toDate = null, from = 0, offset = 20, order = null, direction = null) {
        return RestService.get(`${Config.pennydrop_logs_url}?export=true${fromDate ? `&from_date=${fromDate}` : ''}${toDate ? `&to_date=${toDate}` : ''}${filter ? `&filter=${filter}` : ''}${from ? `&from=${from}` : ''}${offset ? `&offset=${offset}` : ''}${order ? `&order=${order}` : ''}${direction ? `&direction=${direction}` : ''}`, {})
            .then(response => {
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json().then(data => {
                        return Promise.reject()
                    });
                } else {
                    return response.blob()
                }
            });
    },
    getLogsCount: async function (type = null, source = null, filter = '', fromDate = null, toDate = null) {
        return RestService.get(`${Config.transaction_logs_url}?count=true${type !== null && type !== undefined ? `&type=${type}` : ''}${source !== null && source !== undefined ? `&source=${source}` : ''}${fromDate ? `&from_date=${fromDate}` : ''}${toDate ? `&to_date=${toDate}` : ''}${filter ? `&filter=${filter}` : ''}`, {})
            .then(response => response.json());
    },
    getLogs: async function (type = null, source = null, filter = '', fromDate = null, toDate = null, from = 0, offset = 20, order = null, direction = null) {
        return RestService.get(`${Config.transaction_logs_url}?${type !== null && type !== undefined ? `&type=${type}` : ''}${source !== null && source !== undefined ? `&source=${source}` : ''}${fromDate ? `&from_date=${fromDate}` : ''}${toDate ? `&to_date=${toDate}` : ''}${filter ? `&filter=${filter}` : ''}${from ? `&from=${from}` : ''}${offset ? `&offset=${offset}` : ''}${order ? `&order=${order}` : ''}${direction ? `&direction=${direction}` : ''}`, {})
            .then(response => response.json());
    },
    getJobLogsCount: async function (type = null, filter = '', fromDate = null, toDate = null) {
        return RestService.get(`${Config.job_logs_url}?count=true${type !== null && type !== undefined ? `&type=${type}` : ''}${fromDate ? `&from_date=${fromDate}` : ''}${toDate ? `&to_date=${toDate}` : ''}${filter ? `&filter=${filter}` : ''}`, {})
            .then(response => response.json());
    },
    getJobLogs: async function (type = null, filter = '', fromDate = null, toDate = null, from = 0, offset = 20, order = null, direction = null) {
        return RestService.get(`${Config.job_logs_url}?${type !== null && type !== undefined ? `&type=${type}` : ''}${fromDate ? `&from_date=${fromDate}` : ''}${toDate ? `&to_date=${toDate}` : ''}${filter ? `&filter=${filter}` : ''}${from ? `&from=${from}` : ''}${offset ? `&offset=${offset}` : ''}${order ? `&order=${order}` : ''}${direction ? `&direction=${direction}` : ''}`, {})
            .then(response => response.json());
    },
    getLogsExport: async function (type = null, source = null, filter = '', fromDate = null, toDate = null, from = 0, offset = 20, order = null, direction = null) {
        return RestService.get(`${Config.transaction_logs_url}?export=true${type !== null && type !== undefined ? `&type=${type}` : ''}${source !== null && source !== undefined ? `&source=${source}` : ''}${fromDate ? `&from_date=${fromDate}` : ''}${toDate ? `&to_date=${toDate}` : ''}${filter ? `&filter=${filter}` : ''}${from ? `&from=${from}` : ''}${offset ? `&offset=${offset}` : ''}${order ? `&order=${order}` : ''}${direction ? `&direction=${direction}` : ''}`, {})
            .then(response => {
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json().then(data => {
                        return Promise.reject()
                    });
                } else {
                    return response.blob()
                }
            });
    },
    getJobLogsExport: async function (type = null, filter = '', fromDate = null, toDate = null, from = 0, offset = 20, order = null, direction = null) {
        return RestService.get(`${Config.job_logs_url}?export=true${type !== null && type !== undefined ? `&type=${type}` : ''}${fromDate ? `&from_date=${fromDate}` : ''}${toDate ? `&to_date=${toDate}` : ''}${filter ? `&filter=${filter}` : ''}${from ? `&from=${from}` : ''}${offset ? `&offset=${offset}` : ''}${order ? `&order=${order}` : ''}${direction ? `&direction=${direction}` : ''}`, {})
            .then(response => {
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json().then(data => {
                        return Promise.reject()
                    });
                } else {
                    return response.blob()
                }
            });
    },
    getCouponsCount: async function (status = null, filter = '', fromDate = null, toDate = null) {
        return RestService.get(`${Config.coupons_url}?count=true${status !== null && status !== undefined ? `&status=${status}` : ''}${fromDate ? `&from_date=${fromDate}` : ''}${toDate ? `&to_date=${toDate}` : ''}${filter ? `&filter=${filter}` : ''}`, {})
            .then(response => response.json());
    },
    getCoupons: async function (status = null, filter = '', fromDate = null, toDate = null, from = 0, offset = 20, order = null, direction = null) {
        return RestService.get(`${Config.coupons_url}?${status !== null && status !== undefined ? `&status=${status}` : ''}${fromDate ? `&from_date=${fromDate}` : ''}${toDate ? `&to_date=${toDate}` : ''}${filter ? `&filter=${filter}` : ''}${from ? `&from=${from}` : ''}${offset ? `&offset=${offset}` : ''}${order ? `&order=${order}` : ''}${direction ? `&direction=${direction}` : ''}`, {})
            .then(response => response.json());
    },
    removeCoupon: async function (id, headers = {}) {
        return RestService.delete(Config.coupon_id_url.replace("{id}", id), headers);
    },
    updateCoupon: async function (body, header = {}) {
        return RestService.post(Config.coupons_url, header, body)
            .then(response => response.json());
    },
    getTerms: async function () {
        return RestService.get(Config.terms_url, {})
            .then(response => response.json());
    },
    updateTerm: async function (body, header = {}) {
        return RestService.post(Config.terms_url, header, body)
            .then(response => response.json());
    },
    removeTerm: async function (id, headers = {}) {
        return RestService.delete(Config.terms_id_url.replace("{id}", id), headers);
    },
    uploadDepository: async function (formData, header = {}) {
        return RestService.postFormData(Config.upload_depository_url, header, formData)
            .then(response => response.json());
    },
    createReferral: async function (body, headers = {}) {
        return RestService.post(Config.referral_url, {}, body)
            .then(response => response.json());
    },


    getAceUsers: async function (type = null) {
        return RestService.get(`${Config.ace_users_url}?type=${type}`, {})
            .then(response => response.json());
    },
    getConfig: async function () {
        return RestService.get(Config.config_url, {})
            .then(response => response.json());
    },
    getStepConfig: async function () {
        return RestService.get(Config.step_config_url, {})
            .then(response => response.json());
    },
    updatePreference: async function (body, header = {}) {
        return RestService.post(Config.config_url, header, body)
            .then(response => response.json());
    },
    updateStepPreference: async function (body, header = {}) {
        return RestService.post(Config.step_config_url, header, body)
            .then(response => response.json());
    },


    getDashboard: async function (headers = {}) {
        return RestService.get(Config.get_dashboard_url, headers)
            .then(response => response.json());
    },
    changeVisibility: async function (id, visibility = false, headers = {}) {
        return RestService.post(`${Config.change_dashboard_report_visibility_url}id=${id}&visible=${visibility}`, headers, {})
            .then(response => response.json());
    },
    getMasterDropdowns: async function (type) {
        return RestService.get(`${Config.dropdowns_url}?type=${type}`, {})
            .then(response => response.json());
    },
    createOption: async function (body, headers = {}) {
        return RestService.post(Config.dropdowns_url, headers, body)
            .then(response => response.json());
    },
    markDefault: async function (body, headers = {}) {
        return RestService.post(Config.mark_dropdown_default_url, headers, body)
            .then(response => response.json());
    },
    removeDropdownOption: async function (id) {
        return RestService.delete(Config.remove_dropdown_option_url.replace('{id}', id), {})
    },
    getBranches: async function () {
        return RestService.get(Config.branches_url, {})
            .then(response => response.json());
    },
};
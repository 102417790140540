import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Grid, Fade, LinearProgress } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { LeadService } from '../Services/LeadService';
import PropTypes from 'prop-types';
import { getHighlightedText } from '../Utils';

const useStyles = makeStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    sectionHeading: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightMedium,
    },
    heading: {
        fontSize: theme.typography.pxToRem(12),
        fontWeight: theme.typography.fontWeightMedium,
    },
    subheading: {
        fontSize: theme.typography.pxToRem(12),
        fontWeight: theme.typography.fontWeightRegular,
        textAlign: "left"
    }
}));

const DialogTitle = (props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function ViewLogs(props) {
    const { isRekyc = false } = props
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const [title, setTitle] = React.useState(undefined);
    const [logs, setLogs] = React.useState("");

    const classes = useStyles();

    React.useEffect(() => {
        setOpen(props.open)
    }, [props.id && props.open]);

    React.useEffect(() => {
        if (open && props.id) {
            setLoading(true);
            setTitle(`${props.label}`);
            const p = isRekyc ? LeadService.viewReKYCLogs(props.type, props.id, props.lead_id) : LeadService.viewLogs(props.type, props.id, props.lead_id)
            p
                .then((data) => {
                    console.log(data);
                    if (data) {
                        setLogs(data.logs)
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        }
    }, [open]);


    const handleClose = () => {
        if (props.close) {
            props.close();
        }
        setOpen(false);
    };

    return (
        <Dialog fullWidth maxWidth={'sm'} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose} classes={classes}>
                {title}
            </DialogTitle>
            <DialogContent dividers>
                <Grid container justify="flex-start" alignItems="center" direction="row">
                    <Grid item xs={12} lg={12}>
                        <Fade
                            in={loading}
                            style={{
                                transitionDelay: loading ? '10ms' : '0ms'
                            }}
                            unmountOnExit
                        >
                            <LinearProgress style={{ height: 5, width: "100%" }} color="secondary" />
                        </Fade>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <div style={{ padding: 20 }}>
                            {logs && getHighlightedText(logs, "status|error|response")}
                        </div>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ViewLogs.propTypes = {
    id: PropTypes.string,
    open: PropTypes.bool,
    label: PropTypes.string,
    type: PropTypes.string,
    close: PropTypes.func,
};
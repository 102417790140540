import React, { useContext, useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { MasterService } from '../Services/MasterService';
import { AppContext } from '../AppContextProvider';
import ItemSelection from '../components/ItemSelection';
import { orderBy } from 'lodash';

const useStyles = makeStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
	dialogContent: {
		paddingTop: 0
	},
	file: {
		border: '1px solid #d7d7d7',
		width: "100%",
		marginLeft: theme.spacing(1),
		borderRadius: 4,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingLeft: 8,
		paddingRight: 8,
		height: 37.625,
	}
}));

const DialogTitle = (props => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6" style={{ paddingRight: 24 }}>{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles(theme => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(1),
	},
}))(MuiDialogActions);

export default function AddAdditionalDocDialog(props) {
	const { closeConfirmDialog1D, showConfirmDialog1D, showSnackBar } = useContext(AppContext)
	const { confirm, max = 20, min = 0 } = props;
	const [dropdodwnOptions, setDropdownOptions] = React.useState([]);
	const [selectedOption, setSelectedOption] = React.useState(null);
	const [open, setOpen] = React.useState(false);
	const [error, setError] = React.useState(false);
	const [fileError, setFileError] = React.useState(false);
	const [errorMessage, setErrorMessage] = React.useState(undefined);
	const [file, setFile] = React.useState(null);
	const classes = useStyles();
	const hiddenFileInput = useRef(null);

	React.useEffect(() => {
		if (props.open) {
			setErrorMessage(undefined)
			setError(false)
			setOpen(props.open);
			MasterService.getDropdownOptions("additional_documents")
				.then(data => {
					setDropdownOptions(data.additional_documents ? orderBy(data.additional_documents, "label", "asc") : []);
				})
				.catch((data) => {
					showConfirmDialog1D(
						"Something went wrong. Please try again.",
						undefined,
						() => {
							handleClose();
							closeConfirmDialog1D();
						}
					)
				})
		} else {
			setOpen(props.open);
		}
	}, [props.open]);

	const handleClose = () => {
		props.close();
		setSelectedOption(null)
		setOpen(false);
		setError(false)
		setFileError(false)
		setErrorMessage(undefined)
		setFile(undefined)
	};

	const handleSave = () => {
		let hasFileError = false, hasError = false
		if (!selectedOption) {
			hasError = true
			setErrorMessage('Select a file type')
		}
		if (!file) {
			hasFileError = true
			setErrorMessage('Please select a file')
		}
		setFileError(hasFileError)
		setError(hasError)

		if (!hasError && !fileError) {
			confirm && confirm(selectedOption ? selectedOption.dropdown_id : null, file)
			handleClose()
		}
	}

	const onChange = event => {
		const fileUploaded = event.target.files[0];
		if (
			fileUploaded.size <= max * 1024 * 1024 &&
			fileUploaded.size >= min * 1024 * 1024
		) {
			setFile(fileUploaded);
		} else {
			event.target.value = null;
			showSnackBar(`File size should be between ${min}MB and ${max}MB`, "info");
		}
	}

	return (
		<Dialog fullWidth={props.fullWidth !== undefined ? props.fullWidth : true} maxWidth={props.maxWidth !== undefined ? props.maxWidth : "lg"} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
			<DialogTitle id="customized-dialog-title" onClose={handleClose} classes={classes}>
				<Typography variant="h6" component="div">
					Add document
				</Typography>
			</DialogTitle>
			<DialogContent dividers style={{ paddingTop: 0 }}>
				<Grid container spacing={1}>
					<Grid item xs={12} lg={9}>
						{
							dropdodwnOptions && dropdodwnOptions.length > 0 && (
								<ItemSelection
									required={true}
									label='Type'
									error={error}
									value={selectedOption || null}
									optionLabel='label'
									options={dropdodwnOptions}
									style={{ zIndex: 500, marginTop: 0, marginLeft: 0 }}
									formClass={classes.formAutoClass}
									selected={(option) => {
										setSelectedOption(option);
									}} />
							)
						}
					</Grid>
					<Grid item xs={12} lg={9}>
						<div className={classes.file} style={fileError ? { border: '1px solid red' } : null}>
							<div>
								<Typography variant='caption'>{file ? file.name : ""}</Typography>
							</div>
							<div style={{ borderLeft: "1px solid #d7d7d7", height: '100%' }}>
								<Button
									size='small'
									variant='text'
									onClick={() => hiddenFileInput && hiddenFileInput.current.click()}
									style={{ marginTop: 3 }}
									disableRipple
									disableFocusRipple
								>
									Select file
								</Button>
								<input
									accept={"image/*,application/pdf"}
									type="file"
									onChange={onChange}
									required={true}
									ref={hiddenFileInput}
									style={{ display: "none" }}
								/>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} lg={9}>
						<Typography variant='caption' style={{ marginLeft: 8 }} color="error">{errorMessage || ""}</Typography>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button autoFocus onClick={handleClose} color="inherit">
					Cancel
				</Button>
				<Button autoFocus onClick={handleSave} color="primary">
					Upload
				</Button>
			</DialogActions>
		</Dialog>
	);
}

AddAdditionalDocDialog.prototype = {
	open: PropTypes.bool.isRequired,
	close: PropTypes.func.isRequired,
	confirm: PropTypes.func.isRequired,
	maxWidth: PropTypes.any,
	fullWidth: PropTypes.bool
};
export default {
    LOGIN: '/login',
    DASHBOARD: '/',
    VIEW_LEAD: '/lead/:id',
    LEADS: '/leads/kyc',
    REKYC_LEADS: '/leads/rekyc',
    CLOSURE_LEADS: '/leads/closure',
    DROPDOWNS: '/manage/dropdowns',
    DOCUMENTS: '/manage/documents',
    TERMS: '/manage/terms',
    LOGS: '/view/logs/transactions',
    PENNY_DROP_LOGS: '/view/transactions/pennydrop',
    UTILITY: '/tools/utility',
    UPLOAD: '/tools/upload/:type',
    REKYC_UPLOAD: '/tools/rekyc/upload/:type',
    JOB_LOGS: '/job/logs',
    COUPON: '/manage/coupons',
    IMP_LINKS: '/general/links',
    NOT_FOUND: '/not/found',
}
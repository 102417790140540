import React, { useContext, useState } from "react";
import { Card, CardHeader, Divider, CardContent, makeStyles, CircularProgress, Grid, List, ListItem, ListItemText, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import clsx from 'clsx';
import { DonutChart } from '../components/Chart';
import { colorGenerator } from '../Utils';
import { LeadService } from "../Services/LeadService";
import { USERTYPES } from "../Constants";
import { AppContext } from "../AppContextProvider";

const useStyles = makeStyles(theme => ({
    root: {
        margin: 5,
        minHeight: 190,
    },
    icon: {
        padding: 2
    },
    content: {
        paddingTop: 0,
        paddingBottom: 0,
        minHeight: 200,
        marginTop: 0,
        paddingRight: 0,
        paddingLeft: 0,
    },
    itemDivider: {
        borderBottomColor: 'rgba(0,0,0,0.1)'
    },
    actions: {
        paddingTop: 0,
        justifyContent: 'flex-end'
    },
    arrowForwardIcon: {
        marginLeft: theme.spacing(1)
    },
    select: {
        marginTop: 8,
        marginLeft: 8
    },
    loader: {
        marginTop: 40
    },
    chartContainer: {
        padding: theme.spacing(3),
    },
    chart: {
        height: 170
    },
    nodata: {
        position: 'relative',
        top: 40
    }
}));

const StepwiseStats = ({ forwardedRef, ...props }) => {
    const classes = useStyles();
    const { state } = useContext(AppContext)
    const count = 50;
    const { report, className, count: counter } = props;
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showChart, setShowChart] = useState(false);
    const colors = colorGenerator(count);

    React.useEffect(() => {
        setLoading(true);
        setShowChart(false)
        let referral_ids = null;
        if ([USERTYPES.FRAN, USERTYPES.RM, USERTYPES.BRANCH].includes(state.user.EmpUtype)) {
            referral_ids = state.user.EmpCode
        }
        const controller = new AbortController()
        LeadService.getLeadStepwiseStats(referral_ids, controller.signal)
            .then(data => {
                let chartData = [];
                if (data.stepwise_count && typeof Array.isArray(data.stepwise_count)) {
                    data.stepwise_count.forEach((d, i) => {
                        chartData.push({
                            id: i,
                            label: d.label,
                            value: d.count,
                            color: colors[i]
                        });
                    })
                }
                if (chartData.find(c => c.value > 0) !== undefined) {
                    setShowChart(true)
                } else {
                    setShowChart(false)
                }
                setData(chartData);
                setLoading(false);
            })
            .catch(error => {
                if (!controller.signal.aborted) {
                    console.log(error);
                }
                setLoading(false);
            })
        return () => {
            controller.abort()
        }
    }, [counter]);

    return (
        <Grid item xl={6} lg={6} xs={12}>
            <Card
                ref={forwardedRef}
                className={clsx(classes.root, className)}
            >
                <CardHeader
                    title={report.name}
                    className={classes.griditem}
                    titleTypographyProps={{ align: 'left' }} />
                <Divider />
                <CardContent className={classes.content}>
                    {
                        loading ?
                            <div className={classes.loader}>
                                <CircularProgress />
                            </div>
                            :
                            <div className={classes.chartContainer}>
                                {
                                    data.length === 0 ?
                                        (
                                            <div className={classes.nodata}>
                                                No Data
                                            </div>
                                        )
                                        :
                                        (
                                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                                {
                                                    showChart && (
                                                        <div style={{ flex: 1 }}>
                                                            <DonutChart
                                                                className={classes.chart}
                                                                data={data}
                                                            />
                                                        </div>
                                                    )
                                                }
                                                <div style={{ flex: 2 }}>
                                                    <List
                                                        style={{
                                                            overflow: 'auto',
                                                            maxHeight: 440,
                                                        }}
                                                    >
                                                        {data.map((data, i) => (
                                                            <ListItem
                                                                classes={{ divider: classes.itemDivider }}
                                                                divider
                                                                dense
                                                                key={data.id}
                                                            >
                                                                <ListItemText
                                                                    primary={data.label}
                                                                    primaryTypographyProps={{ color: 'inherit', variant: 'subtitle2' }}
                                                                />
                                                                <Typography color="inherit">{data.value}</Typography>
                                                            </ListItem>
                                                        ))}
                                                    </List>
                                                </div>

                                            </div>
                                        )
                                }
                            </div>
                    }
                </CardContent>
            </Card>
        </Grid >
    );
};

StepwiseStats.propTypes = {
    report: PropTypes.object.isRequired,
};

export default StepwiseStats;
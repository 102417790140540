import React, { createContext, useState } from "react";
import theme from "./theme";
import { useMediaQuery } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import path from "./path";

const AppContext = createContext(null);

const defaultTheme = theme;
const smallTheme = theme;
// const smallTheme = createMuiTheme({
//     palette: {
//       type: 'light',
//     },
//     typography: {
//         subtitle1: {
//             fontSize: '0.8rem',
//         },
//         subtitle2: {
//             fontSize: '0.8rem',
//         },
//         // body1: {
//         //     fontWeight: 500,
//         // },
//         // button: {
//         //     fontStyle: 'italic',
//         // },
//     },
// });

let user = JSON.parse(localStorage.getItem("acumen.kyc.user"));
let locations = localStorage.getItem('kyc.locations')
const AppContextProvider = ({ props, children }) => {
  const history = useHistory();
  let [state, setState] = useState({
    isAuthenticated: false,
    isAuthenticating: true,
    user: user,
    masterLocations: locations ? JSON.parse(locations) : [],
    loading: false,
    linearLoading: false,
    show: false,
    sb: {
      open: false,
      autohide: 6000,
    },
    openDialog: false,
    openDialog_1D: false,
    open: false,
    title: "",
    progressOpen: false,
    progressMessage: null,
    theme: defaultTheme,
    isDisconnected: false,
    openNotifications: false,
    notifications: [],
    userIsTouring: false,
    tourSteps: [],
    openNewLead: false,
    openNewLeadId: null,
    openNewLeadCallback: null,
    openLead: false,
    openLeadCallback: null,
    openRekycLead: false,
    openRekycLeadCallback: null,
    openClosureLead: false,
    openClosureLeadId: null,
    openClosureLeadCallback: null,
    openViewTenant: false,
    openViewTenantCallback: null,
    openModifyTenant: false,
    openModifyTenantId: null,
    openModifyTenantCallback: null,
    openViewPA: false,
    openViewPACallback: null,
    openNewForm: false,
    openNewFormId: null,
    openNewFormType: null,
    openNewFormCallback: null,
  });

  const setAuthenticating = (isAuthenticating) => {
    setState((prevState) => ({
      ...prevState,
      isAuthenticating: isAuthenticating,
    }));
  };

  const setTheme = (theme) => {
    localStorage.setItem("acumen.kyc.theme", theme);
    initializeTheme();
  };

  const getTheme = () => {
    return localStorage.getItem("acumen.kyc.theme");
  };

  const initializeTheme = () => {
    let theme = defaultTheme;
    if (localStorage.getItem("acumen.kyc.theme") !== null) {
      switch (localStorage.getItem("acumen.kyc.theme") || "default") {
        case "small":
          theme = smallTheme;
          break;
        case "default":
          theme = defaultTheme;
          break;
        default:
          theme = defaultTheme;
          break;
      }
      setState((prevState) => ({
        ...prevState,
        theme: theme,
      }));
    }
  };

  const setLinearLoading = (loading) => {
    setState((prevState) => ({
      ...prevState,
      linearLoading: loading,
    }));
  };

  const handleDrawerOpen = () => {
    setState((prevState) => ({
      ...prevState,
      open: true,
    }));
  };

  const handleDrawerClose = () => {
    setState((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  const closeConfirmDialog = () => {
    setState((prevState) => ({
      ...prevState,
      openDialog: false,
      showfield: false,
      fieldHint: "",
      fieldValue: "",
      dialog_field_text: undefined,
    }));
  };
  
  const closeConfirmDialog1D = () => {
    setState((prevState) => ({
      ...prevState,
      openDialog_1D: false,
    }));
  };

  const handleSBClose = () => {
    setState((prevState) => ({
      ...prevState,
      sb: {
        ...prevState.sb,
        open: false,
        message: "",
        varient: "info",
        autohide: 6000
      },
    }));
  };

  const showSnackBar = (message, variant, autohide) => {
    setState((prevState) => ({
      ...prevState,
      sb: {
        ...prevState.sb,
        open: true,
        message: message,
        varient: variant,
        autohide: autohide || 6000
      },
    }));
  };

  const showConfirmDialog1D = (
    dialog_message,
    dialog_title,
    confirmAction,
    button_title = "OK"
  ) => {
    setState((prevState) => ({
      ...prevState,
      dialog_message_1D: dialog_message,
      dialog_title_1D: dialog_title,
      openDialog_1D: true,
      confirmAction_1D: confirmAction,
      button_title_1D: button_title
    }));
  };

  const showConfirmDialog = (
    dialog_message,
    dialog_title,
    confirmAction,
    cancel_title = "Cancel",
    accept_title = "Confirm",
    showfield = false,
    fieldHint = "",
    fieldValue = ""
  ) => {
    setState((prevState) => ({
      ...prevState,
      dialog_message: dialog_message,
      dialog_title: dialog_title,
      openDialog: true,
      confirmAction: confirmAction,
      cancel_title: cancel_title,
      accept_title: accept_title,
      showfield: showfield,
      fieldHint: fieldHint,
      fieldValue: fieldValue,
      dialog_field_text: fieldValue,
    }));
  };

  const setConfirmDialogError = (
    fieldError = undefined
  ) => {
    setState((prevState) => ({
      ...prevState,
      fieldError: fieldError
    }));
  };
  

  const setDialogFieldText = (text) => {
    setState((prevState) => ({
      ...prevState,
      dialog_field_text: text,
    }));
  };

  const setTitle = (title) => {
    setState((prevState) => ({
      ...prevState,
      title: title,
    }));
  };

  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      show: false,
    }));
  };

  const handleShow = () => {
    setState((prevState) => ({
      ...prevState,
      show: true,
    }));
  };

  const handleConnectionChange = () => {
    const condition = navigator.onLine ? "online" : "offline";
    if (condition === "online") {
      const webPing = setInterval(() => {
        fetch("//google.com", {
          mode: "no-cors",
        })
          .then(() => {
            setState((prevState) => ({
              ...prevState,
              isDisconnected: false,
            }));
            clearInterval(webPing);
          })
          .catch(() => {
            setState((prevState) => ({
              ...prevState,
              isDisconnected: true,
            }));
          });
      }, 2000);
      return;
    }
    setState((prevState) => ({
      ...prevState,
      isDisconnected: true,
    }));
  };

  const isLoading = (loading) => {
    setState((prevState) => ({
      ...prevState,
      loading: loading,
    }));
  };

  const userHasAuthenticated = (authenticated) => {
    setState((prevState) => ({
      ...prevState,
      isAuthenticated: authenticated,
    }));
  };

  const handleLogout = (event) => {
    userHasAuthenticated(false);
    localStorage.removeItem("acumen.kyc.user");
    localStorage.removeItem("acumen.kyc.session");
    history.push(path.LOGIN);
  };

  const showProgressDialog = (message = null) => {
    setState((prevState) => ({
      ...prevState,
      progressOpen: true,
      progressMessage: message,
    }));
  };

  const updateProgressMessage = (message = null) => {
    setState((prevState) => ({
      ...prevState,
      progressMessage: message,
    }));
  };

  const closeProgressDialog = () => {
    setState((prevState) => ({
      ...prevState,
      progressOpen: false,
      progressMessage: null,
    }));
  };

  const updateUser = (user) => {
    setState((prevState) => ({
      ...prevState,
      user: user,
    }));
  };

  const setNotifications = (notifications) => {
    setState((prevState) => ({
      ...prevState,
      notifications,
    }));
  };

  const handleNotificationsClose = () => {
    setState((prevState) => ({
      ...prevState,
      openNotifications: false,
    }));
  };

  const showNotifications = (e) => {
    setState((prevState) => ({
      ...prevState,
      openNotifications: true,
    }));
  };

  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  // return useMediaQuery('(max-width:750px)');
  // return useMediaQuery(theme.breakpoints.down('md'));

  const userWantsToTour = (tour) => {
    setState((prevState) => ({
      ...prevState,
      userIsTouring: tour,
    }));
  };

  const setTourSteps = (steps) => {
    setState((prevState) => ({
      ...prevState,
      tourSteps: steps,
    }));
  };

  const openNewLead = (id = null, callback = null) => {
    setState((prevState) => ({
      ...prevState,
      openNewLead: true,
      openNewLeadId: id,
      openNewLeadCallback: callback
    }));
  };

  const closeNewLead = (callback = false) => {
    if(callback && state.openNewLeadCallback) {
      state.openNewLeadCallback();
    }
    setState((prevState) => ({
      ...prevState,
      openNewLead: false,
      openNewLeadId: null,
      openNewLeadCallback: null
    }));
  };
  
  const openModifyTenant = (id = null, callback = null) => {
    setState((prevState) => ({
      ...prevState,
      openModifyTenant: true,
      openModifyTenantId: id,
      openModifyTenantCallback: callback
    }));
  };

  const closeModifyTenant = () => {
    if(state.openModifyTenantCallback) {
      state.openModifyTenantCallback();
    }
    setState((prevState) => ({
      ...prevState,
      openModifyTenant: false,
      openModifyTenantId: null,
      openModifyTenantCallback: null
    }));
  };
  
  const openNewForm = (id = null, type = null, callback = null) => {
    setState((prevState) => ({
      ...prevState,
      openNewForm: true,
      openNewFormId: id,
      openNewFormType: type,
      openNewFormCallback: callback
    }));
  };

  const closeNewForm = (callback = false) => {
    if(callback && state.openNewFormCallback) {
      state.openNewFormCallback();
    }
    setState((prevState) => ({
      ...prevState,
      openNewForm: false,
      openNewFormId: null,
      openNewFormType: null,
      openNewFormCallback: null
    }));
  };
  
  const openLead = (id = null, callback = null) => {
    setState((prevState) => ({
      ...prevState,
      openLead: true,
      openLeadId: id,
      openLeadCallback: callback
    }));
  };

  const closeLead = (callback = false) => {
    if(callback && state.openLeadCallback) {
      state.openLeadCallback(callback);
    }
    setState((prevState) => ({
      ...prevState,
      openLead: false,
      openLeadId: null,
      openLeadCallback: null
    }));
  };
  
  const openRekycLead = (id = null, callback = null) => {
    setState((prevState) => ({
      ...prevState,
      openRekycLead: true,
      openRekycLeadId: id,
      openRekycLeadCallback: callback
    }));
  };

  const closeRekycLead = (callback = false) => {
    if(callback && state.openRekycLeadCallback) {
      state.openRekycLeadCallback(callback);
    }
    setState((prevState) => ({
      ...prevState,
      openRekycLead: false,
      openRekycLeadId: null,
      openRekycLeadCallback: null
    }));
  };
  
  const openClosureLead = (id = null, callback = null) => {
    setState((prevState) => ({
      ...prevState,
      openClosureLead: true,
      openClosureLeadId: id,
      openClosureLeadCallback: callback
    }));
  };

  const closeClosureLead = (callback = false) => {
    if(callback && state.openClosureLeadCallback) {
      state.openClosureLeadCallback(callback);
    }
    setState((prevState) => ({
      ...prevState,
      openClosureLead: false,
      openClosureLeadId: null,
      openClosureLeadCallback: null
    }));
  };
 
  const openViewTenant = (id = null, callback = null) => {
    setState((prevState) => ({
      ...prevState,
      openViewTenant: true,
      openViewTenantId: id,
      openViewTenantCallback: callback
    }));
  };

  const closeViewTenant = (callback = false) => {
    if(callback && state.openViewTenantCallback) {
      state.openViewTenantCallback();
    }
    setState((prevState) => ({
      ...prevState,
      openViewTenant: false,
      openViewTenantId: null,
      openViewTenantCallback: null
    }));
  };

  const openViewPA = (id = null, callback = null) => {
    setState((prevState) => ({
      ...prevState,
      openViewPA: true,
      openViewPAId: id,
      openViewPACallback: callback
    }));
  };

  const closeViewPA = (callback = false) => {
    if(callback && state.openViewPACallback) {
      state.openViewPACallback();
    }
    setState((prevState) => ({
      ...prevState,
      openViewPA: false,
      openViewPAId: null,
      openViewPACallback: null
    }));
  };

  const setMasterLocations = (locations = []) => {
    localStorage.setItem('kyc.locations', JSON.stringify(locations))
  };

  const tourAvailable = state.tourSteps.length > 0;

  return (
    <AppContext.Provider
      value={{
        state,
        setTheme,
        getTheme,
        initializeTheme,
        handleClose,
        handleSBClose,
        handleShow,
        handleDrawerClose,
        setTitle,
        showConfirmDialog,
        setConfirmDialogError,
        showSnackBar,
        closeConfirmDialog,
        setLinearLoading,
        handleDrawerOpen,
        handleConnectionChange,
        isLoading,
        userHasAuthenticated,
        handleLogout,
        updateUser,
        handleNotificationsClose,
        showNotifications,
        setNotifications,
        setAuthenticating,
        showProgressDialog,
        updateProgressMessage,
        closeProgressDialog,
        setDialogFieldText,
        mobileView,
        userWantsToTour,
        setTourSteps,
        tourAvailable,
        openNewLead,
        closeNewLead,
        openModifyTenant,
        closeModifyTenant,
        openLead,
        closeLead,
        openRekycLead,
        closeRekycLead,
        openViewTenant,
        closeViewTenant,
        openViewPA,
        closeViewPA,
        openNewForm,
        closeNewForm,
        showConfirmDialog1D,
        closeConfirmDialog1D,
        masterLocations: state.masterLocations,
        setMasterLocations,
        openClosureLead,
        closeClosureLead,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider };

import React from "react";
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core/styles";
import { Grid, FormControl, TextField, FormHelperText, Typography } from "@material-ui/core";
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
    formControlAutoComplete: {
        zIndex: 3,
        margin: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1),
        },
    },
}));

export default function ItemSelection(props) {
    const classes = useStyles();

    const { label, optionLabel, options, selected, multiple, limitTags, labelTemplate, disableCloseOnSelect, helperText, size, style, getOptionSelected, formClass, autoCompleteClass, renderOption } = props;
    const [value, setValue] = React.useState(multiple || false ? [] : null);

    React.useEffect(() => {
        setValue(props.value ? props.value : null);
    }, [props.value]);

    const getOptionLabelTemplate = (option) => {
        if (labelTemplate) {
            return labelTemplate(option);
        }
        return option[optionLabel];
    }

    return (
        <Grid container justify="flex-start">
            <Grid item xs={12} lg={12}>
                <FormControl variant={props.variant || "outlined"} className={formClass ? formClass : classes.formControlAutoComplete} fullWidth>
                    <Autocomplete
                        groupBy={props.groupBy || null}
                        size={size || 'small'}
                        disableClearable={props.clearable ? !props.clearable : true}
                        disabled={props.disabled || false}
                        disableCloseOnSelect={disableCloseOnSelect}
                        options={options || []}
                        className={autoCompleteClass}
                        getOptionLabel={option => `${getOptionLabelTemplate(option)}`}
                        value={value}
                        multiple={multiple || false}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                            selected(newValue);
                        }}
                        style={style ? style : { zIndex: 500, marginTop: 8, marginRight: 10 }}
                        renderOption={renderOption}
                        renderInput={params => (
                            <TextField
                                {...params}
                                error={props.error || false}
                                label={label}
                                variant={props.variant || "outlined"}
                                fullWidth
                                required={props.required || false}
                            />
                        )}
                        limitTags={limitTags}
                        getOptionSelected={getOptionSelected}
                    />
                    {helperText &&
                        <FormHelperText><Typography variant="caption" color={props.error ? "error" : "textPrimary"}>{helperText}</Typography></FormHelperText>
                    }
                </FormControl>
            </Grid>
        </Grid>
    );
};

ItemSelection.propTypes = {
    options: PropTypes.array,
    optionLabel: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
    size: PropTypes.oneOf(['small', 'medium']),
    selected: PropTypes.func.isRequired,
    required: PropTypes.bool,
    multiple: PropTypes.bool,
    disabled: PropTypes.bool,
    clearable: PropTypes.bool,
    error: PropTypes.bool,
    groupBy: PropTypes.any,
    limitTags: PropTypes.any,
    helperText: PropTypes.string,
    getOptionSelected: PropTypes.func,
    renderOption: PropTypes.any
};

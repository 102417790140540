import Config from '../Config';
import {RestService} from './RestService';

export const UserService = {
    authenticate: async function(body, headers = {}){
        return RestService.post(Config.authenticate_url, headers, body)
                .then(response => response.json());
    },
    authenticateToken: async function(userType, userCode, headers = {}){
        return RestService.post(`${Config.authenticate_token_url}?${userType ? `user_type=${userType}` : ''}${userCode ? `&user_code=${userCode}`: ''}`, headers, null)
                .then(response => response.json());
    },
};
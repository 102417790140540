import { Fab, Grid, IconButton, makeStyles, Paper, Table, TableBody, TableRow, Typography } from "@material-ui/core";
import { blue, green, red } from "@material-ui/core/colors";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useContext } from "react";
import { AppContext } from "../AppContextProvider";
import EnhancedTableHead from "../components/EnhancedTableHead";
import { MasterService } from "../Services/MasterService";
import { BootstrapTooltip, StyledTableCell } from "../Utils";
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles(theme => ({
    extendedIcon: {
        position: 'fixed',
        right: 30,
        bottom: 30,
    },
    eIcon: {
        marginRight: theme.spacing(1),
    },
    section: {
        width: '100%',
        height: '100%',
        marginTop: theme.spacing(1),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    qtyField: {
        width: "40%",
        position: "relative",
        [theme.breakpoints.up('md')]: {
            right: '100px',
            width: "10%",
            marginRight: 10
        },
        right: "22px"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom: '20px',
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px'
    },
    icon: {
        padding: '0px',
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
    },
    tablecell: {
        fontSize: '16pt',
        padding: theme.spacing(4)
    },
    container: {
        paddingTop: theme.spacing(1)
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    blueAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: blue[500],
    },
    instructions: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(1),
        // height: theme.spacing(7),
        // float: 'right',
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            // float: 'inherit',
            marginLeft: theme.spacing(1)
        },
        marginRight: theme.spacing(4),

    },
    tableWrapper: {
        maxHeight: 407,
        overflow: 'auto',
    },
    list: {
        width: '100%',
        height: 250,
        overflow: "auto"
    },
    summary: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    title: {
        fontSize: 18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subtitle: {
        fontSize: 18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    selectRoot: {
        fontSize: 12,
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            marginRight: 0
        },
    },
    green: {
        color: green[700],
    },
    red: {
        color: red[500],
    }
}));

export default function TermsPage(props) {
    const { setTitle, setLinearLoading, showConfirmDialog, closeConfirmDialog, showSnackBar } = useContext(AppContext);
    const classes = useStyles();
    // const [term, setSelectedTerm] = React.useState(undefined);
    const [data, setData] = React.useState([]);
    const [generate, setGenerate] = React.useState(true);
    const columns = [
        { id: 'sl_no', align: 'left', numeric: false, disablePadding: false, label: 'Sl.No.', sortable: false },
        { id: 'condition', align: 'justify', numeric: false, disablePadding: false, label: 'Terms and Conditions', sortable: false },
        { id: 'action', align: 'left', numeric: false, disablePadding: true, label: '', sortable: false },
    ];

    React.useEffect(() => {
        setTitle('Manage Terms');
        setGenerate(true);
    }, [true]);

    React.useEffect(() => {
        if (generate) {
            setLinearLoading(true);
            MasterService.getTerms()
                .then((data) => {
                    console.log(data);
                    setData(data && data.terms ? data.terms : []);
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    setLinearLoading(false);
                });
        }
        setGenerate(false);
    }, [generate]);

    const removeTerm = (id) => {
        showConfirmDialog('Confirm remove?', undefined, () => {
            closeConfirmDialog()
            MasterService.removeTerm(id, {})
                .then(data => {
                    showSnackBar('Removed Term', 'info')
                    setGenerate(true)
                })
                .catch(error => {
                    console.log(error)
                    showSnackBar('Something went wrong. Please try again.', 'error')
                })
        })
    }

    const add = (sTerm) => {
        // setSelectedTerm(sTerm ? sTerm : { condition: "" })
        showConfirmDialog('Enter Terms and Condition', undefined, (message) => {
            if (message.length > 0) {
                closeConfirmDialog();
                MasterService.updateTerm({
                    "id": sTerm ? sTerm.tnc_id : null, // Not Mandatory
                    "condition": message, //can update
                    "status": 1 // Can update 1 => enabled, 0 => disabled 
                }, {})
                    .then(data => {
                        if (data.success) {
                            showSnackBar('Term Created', 'info');
                        }
                        setGenerate(true);
                    })
                    .catch(error => {
                        console.log(error)
                        showSnackBar('Something went wrong. Please try again later.', 'error');
                    })
            }
        }, 'Cancel', 'Save', true, 'Term & Condition', sTerm ? sTerm.condition : "")
    }

    return (
        <div className="Home">
            <div className="lander">
                <Grid container direction="row" spacing={1} justify="center">
                    <Grid item xl={10} lg={12} xs={12}>
                        <Paper className={classes.section}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={12} lg={12}>
                                    <div style={{ minHeight: 100, overflowY: 'auto', display: 'block' }}>
                                        <Table className={classes.table} stickyHeader>
                                            <EnhancedTableHead
                                                order={""}
                                                orderBy={""}
                                                onRequestSort={() => {}}
                                                rows={columns} />
                                            <TableBody>
                                                {
                                                    data && data.map((element, index) => (
                                                        <TableRow
                                                            key={index}
                                                            hover
                                                            style={{ cursor: 'pointer' }}>
                                                            <StyledTableCell scope="row" align="center" padding='none' style={{ width: 130 }}>
                                                                {index + 1}
                                                            </StyledTableCell>
                                                            <StyledTableCell scope="row" align="left" padding='none'>
                                                                {element.condition || '-'}
                                                            </StyledTableCell>
                                                            <StyledTableCell scope="row" align="left" padding='none' style={{ width: '8vw' }}>
                                                                <BootstrapTooltip title="Edit" arrow>
                                                                    <IconButton size="small" style={{ marginRight: 8 }} onClick={() => element && add(element)}>
                                                                        <EditIcon fontSize="small"/>
                                                                    </IconButton>
                                                                </BootstrapTooltip>
                                                                <BootstrapTooltip title="Remove" arrow>
                                                                    <IconButton size="small" style={{ marginRight: 8 }} onClick={() => element && removeTerm(element.tnc_id)}>
                                                                        <DeleteIcon  fontSize="small"/>
                                                                    </IconButton>
                                                                </BootstrapTooltip>
                                                            </StyledTableCell>
                                                        </TableRow>
                                                    )
                                                    )}
                                            </TableBody>
                                        </Table>
                                    </div>
                                    {
                                        data.length === 0 &&
                                        <Typography color="error" variant="subtitle2">No Terms and Conditions</Typography>
                                    }
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
            <Fab variant="extended" color="primary" className={classes.extendedIcon} onClick={() => add(null)}>
                <AddIcon className={classes.eIcon} />
                Add New Terms
            </Fab>
        </div>
    )
}
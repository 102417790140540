import palette from '../palette';

export default {
  root: {
    borderRadius: 3,
    overflow: 'hidden'
  },
  colorPrimary: {
    backgroundColor: palette.loader
  }
};

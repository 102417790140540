const host = process.env.NODE_ENV === 'production' ? "https://api.kyc.acumengroup.in" : "https://ekycdev.acumengroup.in/acumen";

const Config = {

    save_lead_url: `${host}/lead`,
    save_step_url: `${host}/lead/step/{lead}`,
    config_url: `${host}/app/config`,
    step_config_url: `${host}/step/config`,
    resend_email_url: `${host}/lead/step/resend/email/{lead}/{step}`,
    get_sub_steps_url: `${host}/step/substep/{step}`,
    form_template_url: `${host}/form/{type}`,
    save_form_data_url: `${host}/lead/form`,
    retrieve_form_data_url: `${host}/lead/form/{lead}/{type}`,
    check_form_status_url: `${host}/check/lead/form/{lead}/{type}`,
    document_url: `${host}/document/{type}`,
    document_only_url: `${host}/document`,
    remove_document_url: `${host}/document/{type}/{docId}`,
    add_lead_step_documents_url: `${host}/lead/step/document/{lead}/{step}`,
    remove_lead_step_documents_url: `${host}/lead/step/document/{lead}/{step}/{id}`,
    ace_users_url: `${host}/ace/users`,
    charges_url: `${host}/charges`,
    get_heirarchy_url: `${host}/hierarchy/type`,
    get_heirarchy_list_url: `${host}/hierarchy/list`,

    // API's
    authenticate_url: `${host}/authenticate`,
    authenticate_token_url: `${host}/token/authenticate`,
    leads_url: `${host}/leads`,
    rekyc_leads_url: `${host}/rekyc/leads`,
    transaction_logs_url: `${host}/transactions`,
    job_logs_url: `${host}/job/logs`,
    pennydrop_logs_url: `${host}/pennydrop/logs`,
    coupons_url: `${host}/coupon`,
    coupon_id_url: `${host}/coupon/{id}`,
    get_lead_detail_url: `${host}/lead/{id}`,
    get_rekyc_lead_detail_url: `${host}/rekyc/lead/{id}`,
    pennydrop_transactions_url: `${host}/lead/{id}/transactions/{type}`,
    update_tracking_details_url: `${host}/lead/tracking/details`,
    update_rekyc_tracking_details_url: `${host}/rekyc/lead/tracking/details`,
    update_lead_status_url: `${host}/lead/status`,
    update_rekyc_lead_status_url: `${host}/rekyc/lead/status`,
    lead_status_count_url: `${host}/lead/status/count`,
    get_dashboard_url: `${host}/dashboard/preferences?`,
    change_dashboard_report_visibility_url: `${host}/dashboard/preferences?`,
    segments_url: `${host}/segments`,
    branches_url: `${host}/branches`,
    important_links_url: `${host}/general/links`,
    fetch_pan_name_url: `${host}/pan/name`,
    verify_aadhaar_pan_linked: `${host}/aadhaar/pan/linked`,
    master_dropdown_url: `${host}/dropdown/options`,
    verify_lead_status_url: `${host}/lead/{id}/verify/status`,
    refetch_pan_name_url: `${host}/lead/refetch/pan/{id}`,
    request_esign_url: `${host}/lead/{id}/request/esign`,
    request_ipv_url: `${host}/lead/{id}/request/ipv/{type}`,
    mark_lead_activated_url: `${host}/activate/lead`,
    mark_lead_rekyc_activated_url: `${host}/rekyc/activate/lead`,
    pincode_url: `${host}/pincode`,
    city_url: `${host}/city`,
    state_mapping_url: `${host}/state/mapping`,
    referral_types_url: `${host}/referral/type/list`,
    referral_url: `${host}/referral`,
    transaction_types_url: `${host}/transaction/types`,
    job_types_url: `${host}/job/types`,
    update_lead_url: `${host}/lead/edit/{id}`,
    update_lead_docs_url: `${host}/lead/document/edit/{id}`,
    upload_additional_lead_docs_url: `${host}/lead/{id}/additional/document`,
    remove_additional_lead_docs_url: `${host}/lead/additional/document/{docId}`,
    upload_rekyc_additional_lead_docs_url: `${host}/rekyc/lead/{id}/additional/document`,
    remove_rekyc_additional_lead_docs_url: `${host}/rekyc/lead/additional/document/{docId}`,
    confirm_lead_docs_url: `${host}/lead/documents/save/{id}`,
    lead_stats_1_url: `${host}/lead/stats/1`,
    total_amount_collected_url: `${host}/lead/amount/collected`,
    tat_url: `${host}/lead/average/tat`,
    lead_stats_demographic_url: `${host}/lead/{type}/stats`,
    lead_status_stats_url: `${host}/lead/status/stats`,
    tat_stats_url: `${host}/lead/report/tat`,
    lead_stats_location_url: `${host}/lead/location/stats/{type}`,
    lead_stepwise_count_url: `${host}/lead/steps/count`,
    lead_download_documents_url: `${host}/download/{id}/documents`,
    lead_rekyc_download_documents_url: `${host}/rekc/download/{id}/documents`,
    dropdowns_url: `${host}/dropdown/options`,
    mark_dropdown_default_url: `${host}/dropdown/default`,
    remove_dropdown_option_url: `${host}/dropdown/options/{id}`,
    search_url: `${host}/search`,
    task_view_logs_url: `${host}/task/logs/{type}/{id}`,
    rekyc_task_view_logs_url: `${host}/rekyc/task/logs/{type}/{id}`,
    task_stats_url: `${host}/task/stats/{type}`,
    rekyc_task_stats_url: `${host}/rekyc/task/stats/{type}`,
    attempt_task_url: `${host}/request/upload`,
    reattempt_task_url: `${host}/rerequest/upload/{type}/{id}`,
    attempt_rekyc_task_url: `${host}/rekyc/request/upload`,
    reattempt_rekyc_task_url: `${host}/rekyc/rerequest/upload/{type}/{id}`,
    task_instances_url: `${host}/task/instances/{type}`,
    rekyc_task_instances_url: `${host}/rekyc/task/instances/{type}`,
    terms_url: `${host}/terms`,
    terms_id_url: `${host}/terms/{id}`,
    upload_depository_url: `${host}/depository`,
    activation_task_report_url: `${host}/task/report`,
    attempt_mf_task_url: `${host}/mf/task/status`,
    
    // Closure
    closure_leads_url: `${host}/closure/leads`,
    get_closure_lead_detail_url: `${host}/closure/lead/{id}`,
    update_closure_tracking_details_url: `${host}/closure/lead/tracking/details`,
    lead_closure_download_documents_url: `${host}/closure/download/{id}/documents`,
    update_closure_lead_status_url: `${host}/closure/lead/status`,
    mark_lead_closure_deactivate_url: `${host}/closure/deactivate/lead`,
    attempt_closure_task_url: `${host}/closure/task/status`,
    closure_stats_accounts_closed_url: `${host}/closure/lead/count/stats`,
    closure_reasons_count_url: `${host}/closure/lead/reason/stats`,
    closure_status_stats_url: `${host}/closure/lead/status/stats`,
    closure_approval_status_url: `${host}/closure/approval/status`,

    // external
    data_inconsistency: `${host}/utils/inconsistent/fields/{code}`,


    REFRESH_INTERVAL: 900000,
    SHORT_REFRESH_INTERVAL: 60000,
    marvel: "f9e35b81f6bf37ee42520761e6176cf6",
    GOOGLE_MAP_API_KEY: "AIzaSyBajeoM-4V9Lz_SOEdNskDSlRVZGgIN7Vo",
}

export default Config;

export const ADD_LEAD_MODULE = false;
export const ACCOUNT_CLOSURE_MODULE = true;
export const REFERRAL_MODULE = true;

export const RowCountOptions = [10, 25, 50, 100];
import React, { useContext } from "react";
import "../css/login.css";
import { UserService } from "../Services/UserService";
import {
  Divider,
  Grid,
  Hidden,
  makeStyles
} from "@material-ui/core";
import { FormControl, Button, InputLabel, IconButton, InputAdornment, OutlinedInput } from "@material-ui/core";
import clsx from 'clsx';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useHistory } from "react-router-dom";
import { AppContext } from "../AppContextProvider";
import { useState } from "react";
import path from "../path";
import { USERTYPES } from "../Constants"

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: 300,
  },
}));

export default function Login(props) {

  const classes = useStyles();
  const history = useHistory();
  const {
    mobileView,
    setTitle,
    showSnackBar,
    setAuthenticating,
    setLinearLoading,
    updateUser,
    userHasAuthenticated,
  } = useContext(AppContext);

  const [showPassword, setShowPassword] = useState(false);
  const [userid, setUserId] = useState("");
  const [password, setPassword] = useState("");

  React.useEffect(() => {
    setTitle('');
    const user = localStorage.getItem('acumen.kyc.user') !== null ? JSON.stringify(localStorage.getItem('acumen.kyc.user')) : null;
    const session = localStorage.getItem('acumen.kyc.session');
    if (user !== null && session !== null) {
      history.push(path.DASHBOARD);
    } else {
      setAuthenticating(false);
    }
  }, [true])

  const validateForm = () => {
    return userid.length >= 1 && password.length >= 1;
  }

  const handleChange = event => {
    if (event.target.name === 'password') {
      setPassword(event.target.value)
    } else if (event.target.name === 'userid') {
      setUserId(event.target.value)
    }
  }

  const handleSubmit = event => {
    if (validateForm()) {
      event.preventDefault();
      try {
        setLinearLoading(true);
        UserService.authenticate({ user_id: userid, password })
          .then((data) => {
            if (data.EmpCode && data.EmpUtype in USERTYPES && data) {
              updateUser(data);
              // console.log("Logged in");
              // console.log(data);
              localStorage.setItem('acumen.kyc.user', JSON.stringify(data));
              localStorage.setItem('acumen.kyc.session', data['touch-token']);
              userHasAuthenticated(true);
              history.push(path.DASHBOARD);
            } else if (data && data.message) {
              showSnackBar(data.message, 'error');
            }
            else{
              showSnackBar("Login Failed", 'error');
            }
          })
          .catch((error) => {
            showSnackBar("Something went wrong. Please check connection and try again.", 'error');
          })
          .finally(() => setLinearLoading(false));
      } catch (e) {
        alert(e.message);
        setLinearLoading(false);
      }
    } else {
      showSnackBar("Please fill in required fields", 'error');
    }
  }

  const handleClickShowPassword = () => {
    setShowPassword(prevState => !prevState);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <div className="Login" style={{ height: '100vh' }}>
      <Grid container direction="row" justify="center" alignItems="center" style={{ height: '50vh' }}>
        <Grid item xs={12} lg={7}>
          <img src={process.env.PUBLIC_URL + '/images/logo.png'} alt="Logo" height={mobileView ? 50 : 150} />
        </Grid>
        <Hidden mdDown>
          <Grid item style={{ height: '50vh' }}>
            <Divider orientation="vertical" variant="middle" />
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={4}>
          {/* <img src={process.env.PUBLIC_URL + '/images/icon.png'} alt="Logo" height={50} style={{marginTop: 20, marginBottom: 30}}/> */}
          <form onSubmit={handleSubmit}>
            <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
              <InputLabel htmlFor="standard-adornment-userid">User ID</InputLabel>
              <OutlinedInput
                id="standard-adornment-userid"
                type={'userid'}
                value={userid}
                name="userid"
                onChange={handleChange}
                labelWidth={40}
              />
            </FormControl>
            <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
              <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
              <OutlinedInput
                id="standard-adornment-password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                name="password"
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
            </FormControl>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <Button type="submit" variant="contained" color="primary" onClick={handleSubmit} size="large">
                Login
              </Button>
            </FormControl>
          </form>
        </Grid>
      </Grid>
    </div>
  );
}
import React from 'react'
import PropTypes from 'prop-types';
import { CartesianGrid, Legend, LineChart, Tooltip, XAxis, YAxis, Line, ResponsiveContainer, Label } from 'recharts';
import { isEmpty } from 'lodash';

const LChart = props => {
    const { data, onClick } = props;

    if (isEmpty(data) || isEmpty(data.lineConfig)) {
        return null;
    }

    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart
                data={data.data}
                width={730}
                height={250}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="tat" type='category' unit="hr" >
                    <Label value="TAT in hours" offset={0} position="insideBottom" />
                </XAxis>
                <YAxis
                    allowDecimals={false}
                    label={{ value: 'Applications', angle: -90, position: 'insideLeft' }}
                />
                <Tooltip
                    labelFormatter={(value) => `TAT: ${value} hrs`}
                    formatter={(value, name) => {
                        return `${value} Application${(value || 0) > 1 ? 's' : ''}`;
                    }}
                />
                <Legend />
                {data.lineConfig.map((s) => (
                    <Line
                        dataKey={s.dataKey}
                        // data={s.data}
                        name={s.label}
                        key={s.dataKey}
                        stroke={s.stokeColor}
                        strokeWidth={3}
                        connectNulls={true}
                        onClick={(data, index) => onClick(data, index)}
                    />
                ))}
                    {/* <Line
                        dataKey="count"
                        data={s.data}
                        name={s.name}
                        key={s.name}
                        stroke={s.stokeColor}
                        strokeWidth={3}
                        onClick={(data, index) => onClick(data, index)}
                    /> */}
            </LineChart>
        </ResponsiveContainer>
    )
}

LChart.propTypes = {
    className: PropTypes.string,
    data: PropTypes.any.isRequired
};

export default LChart
import { Button, Divider, Fab, Grid, Icon, makeStyles, Paper, Table, TableBody, TablePagination, TableRow, Typography } from "@material-ui/core";
import { blue, red } from "@material-ui/core/colors";
import moment from "moment";
import React, { useContext } from "react";
import { AppContext } from "../AppContextProvider";
import EnhancedTableHead from "../components/EnhancedTableHead";
import ItemSelection from "../components/ItemSelection";
import SearchBar from "../components/SearchBar";
import SingleDate from "../components/SingleDate";
import { RowCountOptions } from "../Config";
import { COUPON_STATUS_MAP, TABLE_COUNTS } from "../Constants";
import { MasterService } from "../Services/MasterService";
import { BlueTick, BootstrapTooltip, format, format_display_fancy_notime, getCouponStatusBadge, getTimeInIST, StyledTableCell, titleCase, toInt } from "../Utils";
import AddCoupon from "./AddCoupon";
import AddIcon from '@material-ui/icons/Add';

const STATUS = [
    {
        value: "99",
        label: "All"
    },
]

const useStyles = makeStyles(theme => ({
    extendedIcon: {
        position: 'fixed',
        right: 30,
        bottom: '5%',
    },
    eIcon: {
        marginRight: theme.spacing(1),
    },
    section: {
        width: '100%',
        height: '100%',
        marginTop: theme.spacing(1),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    qtyField: {
        width: "40%",
        position: "relative",
        [theme.breakpoints.up('md')]: {
            right: '100px',
            width: "10%",
            marginRight: 10
        },
        right: "22px"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom: '20px',
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px'
    },
    icon: {
        padding: '0px',
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
    },
    tablecell: {
        fontSize: '16pt',
        padding: theme.spacing(4)
    },
    container: {
        paddingTop: theme.spacing(1)
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    blueAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: blue[500],
    },
    instructions: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: 0,
        // height: theme.spacing(7),
        // float: 'right',
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            // float: 'inherit',
            marginLeft: theme.spacing(1)
        },
        marginRight: theme.spacing(4),

    },
    tableWrapper: {
        maxHeight: 407,
        overflow: 'auto',
    },
    list: {
        width: '100%',
        height: 250,
        overflow: "auto"
    },
    summary: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    title: {
        fontSize: 18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subtitle: {
        fontSize: 18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    selectRoot: {
        fontSize: 12,
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            marginRight: 0
        },
    },
    autoComplete: {
        // zIndex: 3,
        // [theme.breakpoints.up('md')]: {
        //     marginLeft: theme.spacing(1),
        // },
        marginTop: '0px !important',
    },
}));

export default function CouponsPage(props) {
    const { setTitle, setLinearLoading, showProgressDialog, closeProgressDialog, showSnackBar, showConfirmDialog, closeConfirmDialog } = useContext(AppContext);
    const classes = useStyles();
    const [startdate, setStartDate] = React.useState(moment().add(-1, "month").startOf('day'));
    const [enddate, setEndDate] = React.useState(moment().endOf('day'));
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('end_date');
    const [currentRows, setCurrentRows] = React.useState([]);
    const [generate, setGenerate] = React.useState(true);
    const [selectedStatus, setSelectedStatus] = React.useState(STATUS[0]);
    const [filter, setFilter] = React.useState('');
    const [showProgress, setShowProgress] = React.useState(true);
    const [selectedOption, setSelectedOption] = React.useState(null);
    const [addOpen, setAddOpen] = React.useState(false);

    const columns = [
        { id: 'sl_no', align: 'left', numeric: false, disablePadding: false, label: 'S.No.', sortable: false },
        { id: 'coupon_code', align: 'left', numeric: false, disablePadding: true, label: 'Coupon Code', sortable: true },
        { id: 'description', align: 'left', numeric: false, disablePadding: true, label: 'Description', sortable: false },
        { id: 'value', align: 'right', numeric: true, disablePadding: true, label: 'Waiver', sortable: true },
        { id: 'maximum_discount', align: 'right', numeric: true, disablePadding: true, label: 'Maximum Discount', sortable: true },
        { id: 'use_count', align: 'left', numeric: false, disablePadding: true, label: 'Limited Count Enabled', sortable: true },
        { id: 'max_count', align: 'right', numeric: true, disablePadding: true, label: 'Remaining Count', sortable: true },
        { id: 'start_date', align: 'center', numeric: false, disablePadding: true, label: 'Start Date', sortable: true },
        { id: 'end_date', align: 'center', numeric: false, disablePadding: true, label: 'End Date', sortable: true },
        { id: 'status', align: 'center', numeric: false, disablePadding: true, label: 'Status', sortable: true },
        { id: 'action', align: 'left', numeric: false, disablePadding: true, label: '', sortable: false },
    ];

    //Pagination
    const [totalItems, setTotalItems] = React.useState(0);
    const [numItemsPerPage, setNumItemsPerPage] = React.useState(localStorage.getItem(TABLE_COUNTS.coupons_tab_count) ? toInt(localStorage.getItem(TABLE_COUNTS.coupons_tab_count)) : RowCountOptions[0]);
    const [currentPageNumber, setCurrentPageNumber] = React.useState(0);
    const [fetchPage, setFetchPage] = React.useState(false);

    const handleChangeRowsPerPage = event => {
        setNumItemsPerPage(event.target.value);
        setCurrentPageNumber(0);
        setFetchPage(true);
        localStorage.setItem(TABLE_COUNTS.coupons_tab_count, event.target.value);
    }

    const handleChangePage = (event, page) => {
        if (currentPageNumber > page) {
            fetchRow(currentPageNumber - 1);
        } else {
            fetchRow(currentPageNumber + 1);
        }
    };

    const fetchRow = (newCurrentPage) => {
        setCurrentPageNumber(newCurrentPage);
        setFetchPage(true);
    }

    React.useEffect(() => {
        setTitle("Manage Coupons");
    }, [true]);

    React.useEffect(() => {
        if (generate) {
            setCurrentRows([]);
            setLinearLoading(true);
            setCurrentPageNumber(0);
            setTotalItems(0);
            if (showProgress) {
                showProgressDialog();
            }
            let fc = filter && filter.length > 0 ? filter : '';
            MasterService.getCouponsCount(selectedStatus && selectedStatus.value !== "99" ? selectedStatus.value : undefined, fc, moment(startdate).startOf('d').utc().format(format), moment(enddate).endOf('d').utc().format(format))
                .then(data => {
                    setTotalItems(toInt(data.count));
                    if (toInt(data.count) === 0) {
                        setCurrentRows([]);
                        closeProgressDialog();
                    } else {
                        setFetchPage(true);
                    }
                    setLinearLoading(false);
                })
                .catch(error => {
                    console.log(error);
                    setLinearLoading(false);
                    //TODO remove the below line
                    setFetchPage(true);
                });
        }
        setGenerate(false);
    }, [generate]);

    const handleRemove = (id) => {
        showConfirmDialog('Confirm removal of Coupon code?', undefined, () => {
            closeConfirmDialog();
            setLinearLoading(true);
            MasterService.removeCoupon(id, {})
                .then((data) => {
                    showSnackBar('Removed Coupon', 'info')
                    setGenerate(true);
                })
                .catch(error => {
                    showSnackBar('Something went wrong. Please try again.', 'error')
                    console.log(error);
                })
                .finally(() => {
                    setLinearLoading(false);
                    if (showProgress) {
                        closeProgressDialog();
                        setShowProgress(true)
                    }
                });
        })
    }

    const add = (option) => {
        setAddOpen(true);
        setSelectedOption(option);
    }


    React.useEffect(() => {
        let rows = [];
        if (fetchPage) {
            setLinearLoading(true);
            let fc = filter && filter.length > 0 ? filter : '';
            MasterService.getCoupons(selectedStatus && selectedStatus.value !== "99" ? selectedStatus.value : undefined, fc, moment(startdate).startOf('d').utc().format(format), moment(enddate).endOf('d').utc().format(format), currentPageNumber * numItemsPerPage, numItemsPerPage, orderBy, order)
                .then((data) => {
                    if (data && data.coupons) {
                        data.coupons.forEach((element, index) => {
                            let waiver = (element.type === "percentage" ? `${element.value} %` : `${titleCase(element.type)} ${element.value}`)
                            rows.push(
                                <TableRow
                                    key={index}
                                    hover
                                    style={{ cursor: 'pointer' }}>
                                    <StyledTableCell scope="row" align="center" padding='none' style={{ width: '5vw' }}>
                                        {index + 1}
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="justify" padding='none'>
                                        {element.coupon_code}
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="justify" padding='none'>
                                        {element.description || '-'}
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="right" padding='none'>
                                        {element.value ? waiver : '-'}
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="right" padding='none'>
                                        {element.maximum_discount || '-'}
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="center" padding='none' style={{ width: 175 }}>
                                        <span>{element.use_count ? <BlueTick fontSize="small" /> : ""}</span>
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="right" padding='none' style={{ width: 50 }}>
                                        {element.max_count || '-'}
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="left" padding='none'>
                                        {element.start_date ? getTimeInIST(element.start_date).format(format_display_fancy_notime) : "-"}
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="left" padding='none'>
                                        {element.end_date ? getTimeInIST(element.end_date).format(format_display_fancy_notime) : "-"}
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="center" padding='none'>
                                        {element ? getCouponStatusBadge(element.status) : '-'}
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="left" padding='none' style={{ width: 75 }}>
                                        {
                                            true && (
                                                <BootstrapTooltip arrow title="Edit">
                                                    <Icon
                                                        fontSize="small"
                                                        style={{ marginTop: 8, marginRight: 16 }}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            add(element)
                                                        }}>edit</Icon>
                                                </BootstrapTooltip>
                                            )
                                        }
                                        {
                                            true && (
                                                <BootstrapTooltip arrow title="Remove">
                                                    <Icon
                                                        fontSize="small"
                                                        style={{ marginTop: 8, marginRight: 16 }}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleRemove(element.coupon_id)
                                                        }}>delete</Icon>
                                                </BootstrapTooltip>
                                            )
                                        }
                                    </StyledTableCell>
                                </TableRow>
                            );
                        });
                    }
                    setCurrentRows(rows);
                    setLinearLoading(false);
                })
                .catch(error => {
                    console.log(error);
                    setLinearLoading(false);
                })
                .finally(() => {
                    if (showProgress) {
                        closeProgressDialog();
                        setShowProgress(true)
                    }
                });
        }
        setFetchPage(false);
    }, [fetchPage]);

    const sortBy = (event, property) => {
        let o = 'desc';
        if (orderBy === property && order === 'desc') {
            o = 'asc';
        }
        setOrder(o);
        setOrderBy(property);
        setGenerate(true);
    }

    return (
        <div className="Home">
            <div className="lander">
                <Grid container direction="row" spacing={1} justify="center">
                    <Grid item xl={10} lg={12} xs={12}>
                        <Paper className={classes.section}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item container xs={12} md={8} spacing={1} alignItems="center">
                                    <Grid item xs={12} md={2} lg={2}>
                                        <ItemSelection
                                            label='Status'
                                            value={selectedStatus}
                                            optionLabel='label'
                                            autoCompleteClass={classes.autoComplete}
                                            options={[...STATUS, ...COUPON_STATUS_MAP]}
                                            selected={(s) => {
                                                setSelectedStatus(s);
                                                setGenerate(true);
                                            }} />
                                    </Grid>
                                    <Grid item>
                                        <div style={{ maxWidth: 200, paddingLeft: 8 }}>
                                            <SingleDate
                                                label="From"
                                                date={startdate}
                                                margin="dense"
                                                maxDate={moment()}
                                                setDate={(date) => {
                                                    setStartDate(date);
                                                }} />
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <div style={{ maxWidth: 200, marginLeft: 4 }}>
                                            <SingleDate
                                                label="To"
                                                date={enddate}
                                                margin="dense"
                                                minDate={startdate}
                                                maxDate={moment()}
                                                setDate={(date) => {
                                                    setEndDate(date);
                                                }} />
                                        </div>
                                    </Grid>
                                    <Grid item lg={1}>
                                        <Button variant="contained" className={classes.button} color="primary" onClick={() => { !generate && setGenerate(true) }}>
                                            Search
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} md={4} spacing={1} alignItems="center" justify="flex-end">
                                    <Grid item xs={7} md={2} lg={2} xl={3}>
                                        {
                                            (
                                                <div style={{ float: 'right', marginLeft: 8, marginRight: 8 }}>
                                                    <SearchBar
                                                        hint="Search..."
                                                        searchText={filter}
                                                        size="small"
                                                        onTextChange={(e) => {
                                                            setShowProgress(false);
                                                            setFilter(e.target.value);
                                                            setGenerate(true);
                                                        }
                                                        } />
                                                </div>
                                            )
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <Divider style={{ marginBottom: 0, marginTop: 10 }} />
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <div style={{ minHeight: 100, overflowY: 'auto', display: 'block' }}>
                                        <Table className={classes.table} stickyHeader>
                                            <EnhancedTableHead
                                                order={order}
                                                orderBy={orderBy}
                                                onRequestSort={sortBy}
                                                rows={columns} />
                                            <TableBody>
                                                {currentRows}
                                            </TableBody>
                                        </Table>
                                    </div>
                                    {
                                        totalItems === 0 &&
                                        <Typography color="error" variant="subtitle2">No Coupons</Typography>
                                    }
                                    <TablePagination
                                        rowsPerPageOptions={RowCountOptions}
                                        component="div"
                                        count={totalItems}
                                        rowsPerPage={numItemsPerPage}
                                        page={currentPageNumber}
                                        backIconButtonProps={{
                                            'aria-label': 'Previous Page',
                                        }}
                                        nextIconButtonProps={{
                                            'aria-label': 'Next Page',
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        classes={{ selectRoot: classes.selectRoot }} />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
            <Fab variant="extended" color="primary" className={classes.extendedIcon} onClick={() => add(null)}>
                <AddIcon className={classes.eIcon} />
                Add Coupon
            </Fab>
            <AddCoupon
                {...props}
                onError={() => {
                    //setAddProductOpen(false);
                    //setSelectedProduct(null);
                }}
                onSuccess={() => {
                    setAddOpen(false);
                    setSelectedOption(null);
                    setGenerate(true)
                }}
                onCancel={() => setAddOpen(false)}
                open={addOpen}
                option={selectedOption} />
        </div>
    )
}
import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./HomePage";
import Login from "./LoginPage/Login";
import NotFound from "./NotFound/NotFound";
import ViewLeads from "./LeadPage/ViewLeads";
import ViewRekycLeads from "./RekycLeadPage/ViewLeads";
import ViewClosureLeads from "./ClosureLeadPage/ViewLeads";
import ViewLead from "./LeadPage/ViewLead";
import path from "./path";
import Dropdowns from "./DropdownsPage";
import ViewLogs from "./LogsPage";
import TermsPage from "./TermsPage";
import Utility from "./Tools/Utility";
import CouponsPage from "./CouponsPage";
import PennyDropLogsPage from "./PennyDropLogsPage";
import UploadPage from "./Tools/Upload";
import ImportantLinks from "./ImportantLinks";
import JobLogs from "./JobLogs";

export default () =>
  <Switch>
    <Route path={path.LOGIN} exact component={Login} />
    <Route path={path.DASHBOARD} exact component={Home} />
    <Route path={path.VIEW_LEAD} exact component={ViewLead} />
    <Route path={path.LEADS} exact component={ViewLeads} />
    <Route path={path.DROPDOWNS} exact component={Dropdowns} />
    <Route path={path.COUPON} exact component={CouponsPage} />
    <Route path={path.TERMS} exact component={TermsPage} />
    <Route path={path.LOGS} exact component={ViewLogs} />
    <Route path={path.PENNY_DROP_LOGS} exact component={PennyDropLogsPage} />
    <Route path={path.UTILITY} exact component={Utility} />
    <Route path={path.UPLOAD} exact component={UploadPage} />
    <Route path={path.REKYC_UPLOAD} exact component={UploadPage} />
    <Route path={path.IMP_LINKS} exact component={ImportantLinks} />
    <Route path={path.JOB_LOGS} exact component={JobLogs} />

    {/* ReKYC */}
    <Route path={path.REKYC_LEADS} exact component={ViewRekycLeads} />

    {/* Account Closure */}
    <Route path={path.CLOSURE_LEADS} exact component={ViewClosureLeads} />

    {/* Not found */}
    <Route path={path.NOT_FOUND} component={NotFound} />
    <Route component={NotFound} />
  </Switch>;
import React, { useContext, useState } from "react";
import { Card, CardHeader, Divider, CardContent, makeStyles, Select, MenuItem, Input, CircularProgress, Grid, List, ListItem, ListItemText, Typography, TextField, Hidden } from "@material-ui/core";
import PropTypes from "prop-types";
import clsx from 'clsx';
import { DonutChart } from '../components/Chart';
import moment from "moment";
import { format, colorGenerator, getFinancialYearDates } from '../Utils';
import { LeadService } from "../Services/LeadService";
import { AppContext } from "../AppContextProvider";
import { MasterService } from "../Services/MasterService";
import { Autocomplete } from "@material-ui/lab";
import { STATUS_MAP, USERTYPES } from "../Constants";

const useStyles = makeStyles(theme => ({
    root: {
        margin: 5,
        minHeight: 190,
    },
    icon: {
        padding: 2
    },
    content: {
        paddingTop: 0,
        paddingBottom: 0,
        minHeight: 200,
        marginTop: 0,
        paddingRight: 0,
        paddingLeft: 0,
    },
    itemDivider: {
        borderBottomColor: 'rgba(0,0,0,0.1)'
    },
    actions: {
        paddingTop: 0,
        justifyContent: 'flex-end'
    },
    arrowForwardIcon: {
        marginLeft: theme.spacing(1)
    },
    select: {
        marginTop: 8,
        marginLeft: 8
    },
    loader: {
        marginTop: 40
    },
    chartContainer: {
        padding: theme.spacing(3),
    },
    chart: {
        height: 170
    },
    nodata: {
        position: 'relative',
        top: 40
    }
}));

const AdminStats = ({ forwardedRef, ...props }) => {
    const classes = useStyles();
    const { state, masterLocations, setMasterLocations } = useContext(AppContext)
    const count = 50;
    const { report, className, count: counter } = props;
    // const [isShown, setIsShown] = useState(false);
    const [data, setData] = useState([]);
    const [type, setType] = React.useState(-1);
    const [referralTypes, setReferralTypes] = React.useState([]);
    const [range, setRange] = React.useState(props.range || 'today');
    const [selectedMapping, setSelectedMapping] = React.useState([]);
    const [loading, setLoading] = useState(false);
    const [showChart, setShowChart] = useState(false);
    const colors = colorGenerator(count);
    const rangeOptions = [
        {
            label: 'Today',
            value: 'today',
            startdate: moment().startOf('d'),
            enddate: moment().endOf('d')
        },
        {
            label: 'Last 7 Days',
            value: 'last_7',
            startdate: moment().startOf('d').add(-7, 'days'),
            enddate: moment().endOf('d')
        },
        {
            label: 'Last 30 Days',
            value: 'last_30',
            startdate: moment().startOf('d').add(-30, 'days'),
            enddate: moment().endOf('d')
        },
        {
            label: 'This Financial Year',
            value: 'this_year',
            ...getFinancialYearDates()
        }
    ];

    React.useEffect(() => {
        if (masterLocations && masterLocations.length === 0) {
            MasterService.getLocations()
                .then(data => {
                    if (data.length > 0) {
                        let states = new Set(data.map(d => d.state));
                        let mapping = [{ state: 'All States', city: 'All Cities' }, ...data];
                        states.forEach(s => mapping.push({ state: s, city: 'All Cities' }));
                        mapping = mapping.map(m => ({ ...m, label: `${m.state} - ${m.city}` }));
                        mapping.sort((a, b) => (a.label > b.label) ? 1 : -1);
                        setMasterLocations(mapping)
                        setSelectedMapping(mapping[0]);
                    }
                })
        } else {
            setSelectedMapping(masterLocations[0]);
        }
        MasterService.getReferralTypes()
            .then(data => {
                if (data.referral_types) {
                    let refs = [{ label: "All Sources", value: -1 }]
                    for (const key in data.referral_types) {
                        refs.push({ label: data.referral_types[key].label, value: key })
                    }
                    setReferralTypes(refs)
                }
            })
    }, [true])

    React.useEffect(() => {
        let selectedRange = rangeOptions.filter(r => r.value === range)[0];
        setLoading(true);
        setShowChart(false)
        let statee = selectedMapping.state !== 'All States' ? selectedMapping.state : null
        let city = selectedMapping.city !== 'All Cities' ? selectedMapping.city : null
        let referral_ids = null;
        if ([USERTYPES.FRAN, USERTYPES.RM, USERTYPES.BRANCH].includes(state.user.EmpUtype)) {
            referral_ids = state.user.EmpCode
        }
        const controller = new AbortController()
        LeadService.getLeadStatusStats(type === -1 ? undefined : type, statee, city, moment(selectedRange.startdate).utc().format(format), moment(selectedRange.enddate).utc().format(format), referral_ids, controller.signal)
            .then(data => {
                let chartData = [];
                if (data.stats && typeof Array.isArray(data.stats)) {
                    data.stats.forEach((d, i) => {
                        let s = STATUS_MAP.find(s => s.value === d.status)
                        chartData.push({
                            id: i,
                            label: s.label,
                            value: d.count,
                            color: colors[i]
                        });
                    })
                }
                if (chartData.find(c => c.value > 0) !== undefined) {
                    setShowChart(true)
                } else {
                    setShowChart(false)
                }
                setData(chartData);
                setLoading(false);
            })
            .catch(error => {
                if (!controller.signal.aborted) {
                    console.log(error);
                }
                setLoading(false);
            })

        return () => controller.abort()
    }, [range, type, selectedMapping, counter]);

    const Options = () => (
        <Grid container alignItems="center" justify="flex-start">
            {
                masterLocations && masterLocations.length > 0 && (
                    <Grid item>
                        <Autocomplete
                            size='small'
                            disableClearable={true}
                            options={masterLocations || []}
                            getOptionLabel={option => {
                                if (option.hasOwnProperty('label')) {
                                    return option.label;
                                }
                                return "";
                            }}
                            value={selectedMapping}
                            multiple={false}
                            onChange={(event, newValue) => {
                                setSelectedMapping(newValue);
                            }}
                            style={{ display: 'inline-flex', minWidth: 250 }}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    fullWidth
                                //InputProps={{...params.InputProps, disableUnderline: true}}
                                />
                            )}
                        />
                    </Grid>
                )
            }
            {
                referralTypes && referralTypes.length > 0 && (
                    <Grid item>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select-222222222"
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                            className={classes.select}
                            input={<Input disableUnderline={true} />}>
                            {referralTypes.map(r => <MenuItem key={r.value} value={r.value}>{r.label}</MenuItem>)}
                        </Select>
                    </Grid>
                )
            }
            <Grid item>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select-2"
                    value={range}
                    onChange={(e) => setRange(e.target.value)}
                    className={classes.select}
                    input={<Input disableUnderline={true} />}>
                    {rangeOptions.map(r => <MenuItem key={r.value} value={r.value}>{r.label}</MenuItem>)}
                </Select>
            </Grid>
        </Grid>
    )

    return (
        <Grid item xl={6} lg={6} xs={12}>
            <Card
                ref={forwardedRef}
                className={clsx(classes.root, className)}
            >
                <CardHeader
                    title={report.name}
                    className={classes.griditem}
                    titleTypographyProps={{ align: 'left' }}
                    action={
                        <>
                            <Hidden smDown={true}>
                                <Options />
                            </Hidden>
                        </>
                    }
                />
                <Hidden smUp={true}>
                    <div style={{ marginLeft: 8 }}>
                        <Options />
                    </div>
                </Hidden>
                <Divider />
                <CardContent className={classes.content}>
                    {
                        loading ?
                            <div className={classes.loader}>
                                <CircularProgress />
                            </div>
                            :
                            <div className={classes.chartContainer}>
                                {
                                    data.length === 0 ?
                                        (
                                            <div className={classes.nodata}>
                                                No Data
                                            </div>
                                        )
                                        :
                                        (
                                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                                {
                                                    showChart && (
                                                        <div style={{ flex: 1 }}>
                                                            <DonutChart
                                                                className={classes.chart}
                                                                data={data}
                                                                type={type}
                                                            />
                                                        </div>
                                                    )
                                                }
                                                <div style={{ flex: 2 }}>
                                                    <List>
                                                        {data.map((data, i) => (
                                                            <ListItem
                                                                classes={{ divider: classes.itemDivider }}
                                                                divider
                                                                dense
                                                                key={data.id}
                                                            >
                                                                <ListItemText
                                                                    primary={data.label}
                                                                    primaryTypographyProps={{ color: 'inherit', variant: 'subtitle2' }}
                                                                />
                                                                <Typography color="inherit">{data.value}</Typography>
                                                            </ListItem>
                                                        ))}
                                                    </List>
                                                </div>

                                            </div>
                                        )
                                }
                            </div>
                    }
                </CardContent>
            </Card>
        </Grid>
    );
};

AdminStats.propTypes = {
    report: PropTypes.object.isRequired,
};

export default AdminStats;
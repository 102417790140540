import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types';
import { FormControl, Grid, makeStyles, } from '@material-ui/core';
import { AppContext } from '../AppContextProvider';
import { MasterService } from '../Services/MasterService';

const useStyles = makeStyles((theme) => ({
    action: {
        marginTop: 0
    },
    rootCard: {
        padding: '8px 0px'
    },
    root: {
        minHeight : 90,
        // '& .MuiTextField-root': {
        //     margin: theme.spacing(1),
        // },
    },
}));

export default function AddDropdown(props) {
    const {showProgressDialog, showSnackBar, closeProgressDialog, showConfirmDialog, closeConfirmDialog} = useContext(AppContext);
    const { open, option, onSuccess, onError, onCancel } = props;
    const classes = useStyles();
    const [newOption, setNewOption] = React.useState(null);
    const [error, setError] = React.useState({
        label: false,
        value: false,
    });

    React.useEffect(() => {
        if(open){
            if(option){
                setNewOption(option);
            }
        }
    }, [open]);

    const handleChange = event => {
        event.persist()
        if(event.target.type === 'checkbox'){
            setNewOption(p => ({...p, [event.target.name]: event.target.checked}))
        } else {
            setNewOption(p => ({...p, [event.target.name]: event.target.value}))
        }
    };

    const validate = () => {
        let isValid = true;
        if(newOption.label.length === 0){
            isValid = false;
        }
        setError(e => ({...e, label: newOption.label.length === 0}))
        if(newOption.value.length === 0){
            isValid = false;
        }
        setError(e => ({...e, value: newOption.value.length === 0}))
        return isValid;
    }

    const save = () => {
        if(validate()){
            showConfirmDialog('Confirm Save?', undefined, () => {
                closeConfirmDialog();
                showProgressDialog(`Saving Option...`);
                
                let body = {
                    id: newOption.dropdown_id || null, // Not Mandatory
                    type: newOption.type,
                    label: newOption.label || null, //can update
                    value: newOption.value || null,
                    file_name : newOption.file_name, //can update
                    status: newOption.status || 1, // Can update 1 => enabled, 0 => disabled,
                    // is_default: newOption.is_default || false
                }

                MasterService.createOption(body)
                .then((data) => {
                    if(data.success){
                        showSnackBar('Option Saved', "info");
                        onSuccess();
                        closeProgressDialog();
                    } else {
                        showSnackBar('Something went wrong. Please try again.', "error");
                        closeProgressDialog();
                    }
                })
                .catch(error => {
                    console.log(error);
                    onError();
                    showSnackBar('Something went wrong. Please try again later', "error");
                    closeProgressDialog();
                })
            })
        }
    }

    const handleClose = () => {
        resetForm();
        onCancel();
    }

    const resetForm = () => {
        setError({
            label: false,
            value: false,
        })
        setNewOption(null);
    }
    
    return (
        <Dialog fullWidth onClose={handleClose} maxWidth="sm" open={open} aria-labelledby="form-dialog-title">
        <DialogContent>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <FormControl fullWidth className={classes.root}>
                        <TextField
                            autoFocus
                            label='Label'
                            name="label"
                            defaultValue={newOption ? newOption.label : null}
                            type="text"
                            required
                            fullWidth
                            helperText={`${(newOption && newOption.label ? newOption.label.length : 0)} / 100`}
                            error={error.label}
                            variant="outlined"
                            onChange={handleChange}
                            InputProps={{
                                inputProps: { min: 1, maxLength: 100 }
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth className={classes.root}>
                        <TextField
                            label='Value'
                            type="text"
                            required
                            name="value"
                            disabled={newOption ? newOption.dropdown_id : true}
                            defaultValue={newOption ? newOption.value : null}
                            fullWidth
                            error={error.value}
                            variant="outlined"
                            onChange={handleChange}
                            helperText={`${(newOption && newOption.value ? newOption.value.length : 0)} / 45`}
                            InputProps={{
                                inputProps: { min: 0, maxLength: 45 }
                            }}
                        />
                    </FormControl>
                </Grid>
                {/* <Grid item xs={12}>
                    <FormControlLabel
                        control={<Checkbox color="default" checked={newOption && newOption.is_default ? newOption.is_default : false} onChange={handleChange} name="is_default" />}
                        label="Is Default Option"
                        labelPlacement="start"
                        />
                </Grid> */}
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary">
                Cancel
            </Button>
            <Button onClick={save} color="primary">
                Save
            </Button>
        </DialogActions>
        </Dialog>
    );
}

AddDropdown.propTypes = {
    open: PropTypes.bool.isRequired,
    placeholder: PropTypes.string,
    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    option: PropTypes.object
  };
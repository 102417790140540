import palette from '../palette';


export default {
  root: {
    '&$selected': {
      backgroundColor: palette.background.default
    },
    '&$hover': {
      '&:hover': {
        backgroundColor: '#bfbfbf'
      }
    },
    '&:nth-of-type(even)': {
      backgroundColor: palette.background.default,
    },
  }
};

import React from 'react';
import ReactDOM from 'react-dom';
import { 
    BrowserRouter as Router 
} from "react-router-dom";
import './css/index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import packageJson from '../package.json';
import { AppContextProvider } from './AppContextProvider';
global.appVersion = packageJson.version;

ReactDOM.render((
    <Router basename={process.env.PUBLIC_URL}>
        <AppContextProvider>
            <App />
        </AppContextProvider>
    </Router>
), document.getElementById('root'));
registerServiceWorker();
  
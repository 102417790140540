import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { ListSubheader, ListItemSecondaryAction, Icon, Typography, withStyles, IconButton, DialogContent } from '@material-ui/core';
import { BootstrapTooltip, getStatusBadge, getStatusBadgeClosure } from '../Utils';
import { AppContext } from '../AppContextProvider';
import { LeadService } from '../Services/LeadService';
import { Skeleton } from '@material-ui/lab';
import Highlight from 'react-highlighter';
import CloseIcon from '@material-ui/icons/Close';
import { USERTYPES } from '../Constants';
import { isEmpty } from 'lodash';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


export default function SearchDialog(props) {
  const { state, openLead, openRekycLead, openClosureLead, showSnackBar } = useContext(AppContext);
  const { onClose, text, open } = props;
  const [items, setItems] = useState({ rekyc: [], kyc: [], closure: [] });
  const [loading, setLoading] = useState(true);

  React.useEffect(() => {
    if (open && text.length > 0) {
      let referral_ids = null;
      if ([USERTYPES.FRAN, USERTYPES.RM, USERTYPES.BRANCH].includes(state.user.EmpUtype)) {
        referral_ids = state.user.EmpCode
      }
      LeadService.searchLead(text, 0, 20, referral_ids)
        .then(data => {
          console.log(data);
          setItems({ rekyc: data.leads.rekyc, kyc: data.leads.kyc, closure: data.leads.closure });
          setLoading(false);
        })
        .catch(error => {
          console.log(error);
          showSnackBar('Something weng wrong. Please try Again', 'error');
          setLoading(false);
          handleClose();
        })
    }
    return () => {
      setItems({ rekyc: [], kyc: [], closure: [] });
    }
  }, [open, text])

  const handleClose = () => {
    setLoading(true);
    setItems({ rekyc: [], kyc: [], closure: [] });
    onClose();
  };

  const viewClick = (value, type) => {
    if (type === "rekyc") {
      openRekycLead(value.lead_id);
    } else if (type === "closure") {
      openClosureLead(value.lead_id);
    } else {
      openLead(value.lead_id);
    }
    handleClose();
  };

  return (
    <Dialog fullWidth maxWidth="md" onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} scroll="paper">
      <DialogTitle id="simple-dialog-title" onClose={handleClose}>{`Searching for ${text}...`}</DialogTitle>
      <DialogContent dividers>
        {
          !loading ? (
            <List subheader={<ListSubheader></ListSubheader>}>
              {
                !loading && isEmpty(items.kyc) && isEmpty(items.rekyc) && isEmpty(items.closure) ? (
                  <Typography color="error" style={{ marginLeft: 32 }}>No matching results..</Typography>
                ) : null
              }
              {!isEmpty(items.kyc) ?
                <div>
                  <Typography variant='h4'>KYC Results</Typography>
                  {items.kyc.map((item, index) => (
                    <ListItem key={index} divider>
                      <ListItemAvatar>
                        <Avatar>
                          {
                            index + 1
                          }
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={<>Name: <Highlight search={text}>{item.pan_name || '-'}</Highlight> Client Code: <Highlight search={text}>{item.client_code || '-'}</Highlight></>}
                        secondary={
                          <>
                            <span>{<>Aadhaar: <Highlight search={text}>{item.aadhaar || '-'}</Highlight> PAN: <Highlight search={text}>{item.pan || '-'}</Highlight> Email: <Highlight search={text}>{item.email || '-'}</Highlight> Mobile: <Highlight search={text}>{item.mobile || '-'}</Highlight></>}</span><br />
                          </>
                        }
                      />
                      {getStatusBadge(item.status)}
                      <ListItemSecondaryAction>
                        <BootstrapTooltip arrow title="View">
                          <Icon
                            fontSize="small"
                            style={{ marginTop: 8, marginRight: 8, cursor: 'pointer' }}
                            onClick={(e) => {
                              e.stopPropagation();
                              viewClick(item)
                            }}>library_books</Icon>
                        </BootstrapTooltip>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </div>
                : null}
              {!isEmpty(items.rekyc) ?
                <div style={{ marginTop: 16 }}>
                  <Typography variant='h4'>Re-KYC Results</Typography>
                  {items.rekyc.map((item, index) => (
                    <ListItem key={index} divider>
                      <ListItemAvatar>
                        <Avatar>
                          {
                            index + 1
                          }
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={<>Name: <Highlight search={text}>{item.pan_name || '-'}</Highlight> Client Code: <Highlight search={text}>{item.client_code || '-'}</Highlight></>}
                        secondary={
                          <>
                            <span>{<>Aadhaar: <Highlight search={text}>{item.aadhaar || '-'}</Highlight> PAN: <Highlight search={text}>{item.pan || '-'}</Highlight> Email: <Highlight search={text}>{item.email || '-'}</Highlight> Mobile: <Highlight search={text}>{item.mobile || '-'}</Highlight></>}</span><br />
                          </>
                        }
                      />
                      {getStatusBadge(item.status)}
                      <ListItemSecondaryAction>
                        <BootstrapTooltip arrow title="View">
                          <Icon
                            fontSize="small"
                            style={{ marginTop: 8, marginRight: 8, cursor: 'pointer' }}
                            onClick={(e) => {
                              e.stopPropagation();
                              viewClick(item, "rekyc")
                            }}>library_books</Icon>
                        </BootstrapTooltip>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </div>
                : null}
              {!isEmpty(items.closure) ?
                <div style={{ marginTop: 16 }}>
                  <Typography variant='h4'>Account Closure Results</Typography>
                  {items.closure.map((item, index) => (
                    <ListItem key={index} divider>
                      <ListItemAvatar>
                        <Avatar>
                          {
                            index + 1
                          }
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={<>Name: <Highlight search={text}>{item.pan_name || '-'}</Highlight> Client Code: <Highlight search={text}>{item.client_code || '-'}</Highlight></>}
                        secondary={
                          <>
                            <span>{<>Aadhaar: <Highlight search={text}>{item.aadhaar || '-'}</Highlight> PAN: <Highlight search={text}>{item.pan || '-'}</Highlight> Email: <Highlight search={text}>{item.email || '-'}</Highlight> Mobile: <Highlight search={text}>{item.mobile || '-'}</Highlight></>}</span><br />
                          </>
                        }
                      />
                      {getStatusBadgeClosure(item.status)}
                      <ListItemSecondaryAction>
                        <BootstrapTooltip arrow title="View">
                          <Icon
                            fontSize="small"
                            style={{ marginTop: 8, marginRight: 8, cursor: 'pointer' }}
                            onClick={(e) => {
                              e.stopPropagation();
                              viewClick(item, "closure")
                            }}>library_books</Icon>
                        </BootstrapTooltip>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </div>
                : null}
            </List>
          ) : (
            <div>
              <Skeleton height={50} width={300} />
              {
                Array(3).fill(1).map((i, index) => (
                  <div key={index} style={{ width: '100%' }}>
                    <Skeleton height={68} />
                  </div>
                ))
              }
              <Skeleton height={50} width={300} />
              {
                Array(3).fill(1).map((i, index) => (
                  <div key={index} style={{ width: '100%' }}>
                    <Skeleton height={68} />
                  </div>
                ))
              }
            </div>
          )
        }
        {
          (items.kyc.length >= 20 || items.rekyc.length >= 20 || items.closure.length >= 20) &&
          <Typography variant="h5">Showing first 20 matching results...</Typography>
        }
      </DialogContent>
    </Dialog>
  );
}

SearchDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired
};
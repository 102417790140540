import React from "react";
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core/styles";
import { Grid, FormControl, TextField, FormHelperText, Typography } from "@material-ui/core";
import { Autocomplete } from '@material-ui/lab';
import throttle from 'lodash/throttle';
import { MasterService } from "../Services/MasterService";

const useStyles = makeStyles(theme => ({
    formControlAutoComplete: {
        zIndex: 3,
        margin: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1),
        },
    },
}));

export default function ItemSelectionAsync(props) {
    const classes = useStyles();
    const { label, optionLabel, selected, multiple, limitTags, labelTemplate, helperText, size, style, formClass, isCity } = props;
    const [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState([]);

    React.useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const getOptionLabelTemplate = (option) => {
        if (labelTemplate) {
            return labelTemplate(option);
        }
        return option[optionLabel];
    }

    const fetch = React.useMemo(
        () =>
            throttle((request, callback) => {
                if (isCity) {
                    MasterService.getCityList(request.input)
                        .then(data => callback(data));
                } else {
                    MasterService.getPincodeList(request.input)
                        .then(data => callback(data));
                }
            }, 200),
        [],
    );

    React.useEffect(() => {
        let active = true;

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch({ input: inputValue }, (results) => {
            if (active) {
                let newOptions = [];

                // if (value) {
                //   newOptions = [value];
                // }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }
                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    return (
        <Grid container justify="flex-start">
            <Grid item xs={12} lg={12}>
                <FormControl variant="outlined" className={formClass ? formClass : classes.formControlAutoComplete} fullWidth>
                    <Autocomplete
                        groupBy={props.groupBy || null}
                        size={size || 'small'}
                        filterOptions={(x) => x}
                        disableClearable={props.clearable ? !props.clearable : true}
                        disabled={props.disabled || false}
                        options={options || []}
                        getOptionLabel={option => `${getOptionLabelTemplate(option)}`}
                        value={value}
                        multiple={multiple || false}
                        onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                        }}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                            selected(newValue);
                        }}
                        style={style ? style : { zIndex: 500, marginTop: 8, marginRight: 10 }}
                        renderInput={params => (
                            <form noValidate>
                                <TextField {...params}
                                    //autoComplete='new-password'
                                    error={props.error || false}
                                    label={label}
                                    variant="outlined"
                                    fullWidth
                                    required={props.required || false} />
                            </form>
                        )}
                        renderOption={(option) => props.renderOption ? props.renderOption(option) : `${getOptionLabelTemplate(option)}`}
                        limitTags={limitTags}
                        getOptionSelected={(option, value) => {
                            if (!option || !value) {
                                return false
                            }
                            if (isCity) {
                                return option.city === value.city
                            }
                            return option.pincode === value.pincode
                        }}
                    />
                    {helperText &&
                        <FormHelperText><Typography variant="caption" color="textPrimary">{helperText}</Typography></FormHelperText>
                    }
                </FormControl>
            </Grid>
        </Grid>
    );
};

ItemSelectionAsync.propTypes = {
    options: PropTypes.array,
    optionLabel: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
    size: PropTypes.oneOf(['small', 'medium']),
    selected: PropTypes.func.isRequired,
    required: PropTypes.bool,
    multiple: PropTypes.bool,
    disabled: PropTypes.bool,
    clearable: PropTypes.bool,
    error: PropTypes.bool,
    groupBy: PropTypes.any,
    limitTags: PropTypes.any,
    helperText: PropTypes.string,
};

import React, { createContext, useState } from "react";
import { MasterService } from "./Services/MasterService";

const DashboardContext = createContext(null);

const DashboardContextProvider = ({ props, children }) => {
    let [reportBlocks, setReportBlocks] = useState([]);

    const hideReport = (reportBlockId) => {
        let index = reportBlocks.findIndex(r => r.id === reportBlockId);
        let newArr = [...reportBlocks];
        newArr[index].visible = false;
        setReportBlocks(newArr);
        MasterService.changeVisibility(reportBlockId, false)
        .then(data => {
            console.log(data);
        })
        .catch(error => console.log(error));
    }
    
    const showReport = (reportBlockId) => {
        let index = reportBlocks.findIndex(r => r.id === reportBlockId);
        let newArr = [...reportBlocks];
        newArr[index].visible = true;
        setReportBlocks(newArr);
        MasterService.changeVisibility(reportBlockId, true)
        .then(data => {
            console.log(data);
        })
        .catch(error => console.log(error));
    }

    return (
      <DashboardContext.Provider 
        value={{
            reportBlocks, 
            setReportBlocks,
            hideReport,
            showReport,
        }}>
        {children}
      </DashboardContext.Provider>
    );
  };

export { DashboardContext, DashboardContextProvider };


import React, { useState } from "react";
import {
  makeStyles,
  Paper,
  Typography,
  Grid,
  IconButton,
  Divider,
  GridList,
  GridListTile,
  GridListTileBar,
  ButtonBase,
  Accordion,
  AccordionSummary,
  CardHeader,
  Icon,
  AccordionDetails,
} from "@material-ui/core";
import { useContext } from "react";
import ItemSelection from "../components/ItemSelection";
import GetAppIcon from "@material-ui/icons/GetApp";
import { AppContext } from "../AppContextProvider";
import { LeadDialogContext } from "./ViewLeadDialog";
import { BootstrapTooltip, linkPreventCaching, openLink } from "../Utils";
import EditIcon from "@material-ui/icons/Edit";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { isEmpty } from "lodash";
import { Delete } from "@material-ui/icons";
import { STATUS, USERTYPES } from "../Constants";
import AddAdditionalDocDialog from "./AddAdditionalDocDialog";

const useStyles = makeStyles((theme) => ({
  gridList: {
    margin: 16,
    width: "90%",
  },
  accordionSummary: {
    backgroundColor: theme.palette.white,
    color: theme.palette.white,
  },
  image: {
    margin: 12,
    position: "relative",
    height: 200,
    width: 200,
    [theme.breakpoints.down("xs")]: {
      width: "100% !important", // Overrides inline-style
      height: 100,
    },
    "&:hover,  &Mui-focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.7,
      },
      "& $imageMarked": {
        opacity: 0,
      },
      "& $imageTitle": {
        border: "4px solid currentColor",
        marginLeft: 8,
        marginRight: 8,
      },
    },
  },
  imageBackdrop: {
    borderRadius: 8,
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.3,
    transition: theme.transitions.create("opacity"),
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(0)}px ${theme.spacing(4)}px ${theme.spacing(
      0
    )}px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  },
  imageButtonBottom: {
    position: "absolute",
    left: 0,
    right: 0,
    // top: 0,
    // bottom: 0,
    bottom: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  formAutoClass: {
    zIndex: 3,
  },
  icon: {
    color: theme.palette.white,
  },
  titleBar: {
    fontSize: 14,
  },
  subtitleBar: {
    fontSize: 12,
  },
  titleBarRoot: {
    background: 'rgba(186, 0, 13, 0.5)'
  }
}));

const LeadDocuments = () => {
  const { mobileView, state } = useContext(AppContext);
  const {
    fieldSize,
    editMode,
    lead,
    setLead,
    expandedProofs,
    setExpandedProofs,
    downloadAllDocs,
    deleteAdditionalDoc,
    uploadAdditionalDoc,
  } = useContext(LeadDialogContext);
  const classes = useStyles();
  const [showAddDoc, setShowAddDoc] = useState(false)

  const handleDocumentChange = (document_code, field, value) => {
    let docs = lead.documents;
    let doc = docs.find((d) => d.document_code === document_code);
    if (doc) {
      doc[field] = value;
      doc.has_modifications = true;
      doc.error = undefined;
      setLead((lead) => ({ ...lead, documents: docs }));
    }
  }

  const canRemove = !editMode && [STATUS.COMPLETED, STATUS.REJECTED, STATUS.ESIGN_REQUESTED, STATUS.ESIGN_PENDING].includes(lead.status)
    && state.user && [USERTYPES.KYC].includes(state.user.EmpUtype)

    // TODO Disabled
  const canAddDocs = false && [STATUS.COMPLETED, STATUS.REJECTED, STATUS.ESIGN_REQUESTED, STATUS.ESIGN_PENDING].includes(lead.status)
    && state.user && [USERTYPES.KYCHO, USERTYPES.HO, USERTYPES.KYC, USERTYPES.BRANCH, USERTYPES.FRAN, USERTYPES.RM].includes(state.user.EmpUtype)

  const totalDocuments = (lead.documents ? lead.documents.filter((d) => d.document_code !== "ipv").length : 0) + (lead.additional_documents ? lead.additional_documents.filter((d) => d.document_code !== "ipv").length : 0)

  return (
    <Accordion
      square
      expanded={expandedProofs}
      onChange={() => setExpandedProofs((prev) => !prev)}
    >
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="proofs-header"
      >
        <CardHeader
          title={`Proofs (${totalDocuments})`}
          style={{ width: "100%" }}
          titleTypographyProps={{ variant: "h4" }}
          action={
            <>
              {canAddDocs ? (
                <BootstrapTooltip title="Add document">
                  <IconButton
                    aria-label="Add document"
                    onClick={() => setShowAddDoc(true)}
                    style={{ position: "relative", top: 5 }}
                  >
                    <Icon>add_box</Icon>
                  </IconButton>
                </BootstrapTooltip>
              ) : null}
              <IconButton
                aria-label="Download All"
                disabled={totalDocuments === 0}
                onClick={downloadAllDocs}
                style={{ position: "relative", top: 5 }}
              >
                <BootstrapTooltip title="Download All">
                  <Icon>save_alt</Icon>
                </BootstrapTooltip>
              </IconButton>
            </>
          }
        />
      </AccordionSummary>
      <AccordionDetails>
        <div
          style={{
            minHeight: 100,
            marginRight: 16,
            marginLeft: 16,
            marginTop: 8,
            marginBottom: 16,
            width: "100%",
          }}
        >
          <Grid container justify="flex-start" spacing={2} alignItems="center">
            <Grid item xs={12} lg={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} lg={12}>
              {editMode ? (
                <Grid container spacing={3}>
                  {lead.documents &&
                    lead.documents.map((document, key) => {
                      let acceptedFormats =
                        document.allowed_formats.includes("pdf") &&
                          document.allowed_formats.some(
                            (v) => ["png", "jpg", "jpeg"].indexOf(v) >= 0
                          )
                          ? "both"
                          : document.allowed_formats.includes("pdf")
                            ? "document"
                            : "image";
                      return (
                        <Grid key={key} item xs={12} md={4} lg={3}>
                          <Paper>
                            <Grid
                              key={key}
                              container
                              spacing={1}
                              direction="column"
                              style={{ paddingLeft: 8, paddingRight: 8 }}
                            >
                              <Grid item container>
                                <Typography
                                  variant="h5"
                                  style={{ marginLeft: 12 }}
                                  align="center"
                                >
                                  {document.document_label}
                                  {document.is_required && <sup>*</sup>}
                                  {document.download_url && (
                                    <IconButton
                                      onClick={() =>
                                        openLink(document.download_url)
                                      }
                                      size="small"
                                    >
                                      <OpenInNewIcon />
                                    </IconButton>
                                  )}
                                </Typography>
                              </Grid>
                              <Grid item>
                                {document.is_dropdown ? (
                                  <ItemSelection
                                    required
                                    size={fieldSize}
                                    label="Type"
                                    value={
                                      document.dropdown_value
                                        ? document.dropdown_options.find(
                                          (d) =>
                                            d.value ===
                                            document.dropdown_value
                                        )
                                        : null
                                    }
                                    optionLabel="label"
                                    options={document.dropdown_options}
                                    style={{
                                      zIndex: 500,
                                      marginTop: 4,
                                      marginLeft: 0,
                                    }}
                                    formClass={classes.formAutoClass}
                                    selected={(value) => {
                                      handleDocumentChange(
                                        document.document_code,
                                        "dropdown_value",
                                        value.value
                                      );
                                    }}
                                  />
                                ) : (
                                  <div style={{ height: 42 }}></div>
                                )}
                              </Grid>
                              <Grid item style={{ textAlign: "center" }}>
                                {
                                  <ImageHolder
                                    title={`Change`}
                                    link={document.url || ""}
                                    documentType={document.type}
                                    min={document.min_size || 0}
                                    max={document.max_size || 20}
                                    accept={
                                      acceptedFormats === "both"
                                        ? "image/*,application/pdf"
                                        : acceptedFormats === "image"
                                          ? "image/*"
                                          : "application/pdf"
                                    }
                                    change={(file) => {
                                      handleDocumentChange(
                                        document.document_code,
                                        "file",
                                        file
                                      );
                                    }}
                                  />
                                }
                              </Grid>
                              <Grid item style={{ textAlign: "center" }}>
                                <Typography color="error" variant="caption">
                                  {document.error}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                      );
                    })}
                </Grid>
              ) : (
                <GridList
                  cellHeight={200}
                  className={classes.gridList}
                  cols={mobileView ? 1 : 4}
                  spacing={2}
                >
                  {!isEmpty(lead.documents) ? (
                    lead.documents
                      .filter((d) => d.document_code !== "ipv")
                      .map((data, key) => {
                        return (
                          <GridListTile key={key}>
                            <embed
                              width="200"
                              height="200"
                              name="plugin"
                              src={linkPreventCaching(data.url)}
                              type={data.type}
                            />
                            <GridListTileBar
                              classes={{
                                title: classes.titleBar,
                                subtitle: classes.subtitleBar,
                                root: data.status === -1 ? classes.titleBarRoot : undefined
                              }}
                              title={
                                <BootstrapTooltip
                                  arrow
                                  title={`${data.document_label}`}
                                >
                                  <div>
                                    {data.document_label}
                                    {data.is_required && <sup>*</sup>}
                                  </div>
                                </BootstrapTooltip>
                              }
                              subtitle={
                                <React.Fragment>
                                  {data.source || null}
                                  {data.status === -1 ? (
                                    <Typography style={{ marginLeft: 8, color: 'white' }} variant="caption">(Rejected)</Typography>
                                  ) : null}
                                </React.Fragment>
                              }
                              actionIcon={
                                <>
                                  <IconButton
                                    aria-label={`download`}
                                    className={classes.icon}
                                    size="small"
                                    onClick={() => openLink(data.url)}
                                  >
                                    <GetAppIcon />
                                  </IconButton>
                                </>
                              }
                            />
                          </GridListTile>
                        );
                      })
                  ) : null}
                  {!isEmpty(lead.additional_documents) ? (
                    lead.additional_documents
                      .map((data, key) => {
                        return (
                          <GridListTile key={key}>
                            <embed
                              width="200"
                              height="200"
                              name="plugin"
                              src={linkPreventCaching(data.url)}
                              type={data.type}
                            />
                            <GridListTileBar
                              classes={{
                                title: classes.titleBar,
                                subtitle: classes.subtitleBar,
                                root: data.status === -1 ? classes.titleBarRoot : undefined
                              }}
                              title={
                                <BootstrapTooltip
                                  arrow
                                  title={`${data.document_label}`}
                                >
                                  <div>
                                    {data.document_label}
                                    {data.is_required && <sup>*</sup>}
                                  </div>
                                </BootstrapTooltip>
                              }
                              subtitle={
                                <React.Fragment>
                                  {data.source || null}
                                  {data.status === -1 ? (
                                    <Typography style={{ marginLeft: 8, color: 'white' }} variant="caption">(Rejected)</Typography>
                                  ) : null}
                                </React.Fragment>
                              }
                              actionIcon={
                                <>
                                  {canRemove ? (
                                    <IconButton
                                      aria-label={`Delete`}
                                      className={classes.icon}
                                      size="small"
                                      onClick={() => deleteAdditionalDoc && deleteAdditionalDoc(data.document_id)}
                                    >
                                      <Delete />
                                    </IconButton>
                                  ) : null}
                                  <IconButton
                                    aria-label={`download`}
                                    className={classes.icon}
                                    size="small"
                                    onClick={() => openLink(data.url)}
                                  >
                                    <GetAppIcon />
                                  </IconButton>
                                </>
                              }
                            />
                          </GridListTile>
                        );
                      })
                  ) : null}
                </GridList>
              )}
            </Grid>
          </Grid>
          <AddAdditionalDocDialog
            fullWidth={true}
            maxWidth={"sm"}
            open={showAddDoc}
            close={() => {
              setShowAddDoc(false)
            }}
            confirm={(type, file) => {
              uploadAdditionalDoc(type, file)
              setShowAddDoc(false)
            }}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

function ImageHolder(props) {
  const { showSnackBar } = useContext(AppContext);
  const hiddenFileInput = React.useRef(null);
  const [link, setLink] = React.useState(linkPreventCaching(props.link));
  const { min, max } = props;

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const change = (event) => {
    const fileUploaded = event.target.files[0];
    if (
      fileUploaded.size <= max * 1024 * 1024 &&
      fileUploaded.size >= min * 1024 * 1024
    ) {
      setLink(URL.createObjectURL(fileUploaded));
      props.change(fileUploaded);
    } else {
      event.target.value = null;
      showSnackBar(`File size should be between ${min}MB and ${max}MB`, "info");
    }
  };

  let classes = useStyles();

  return (
    <ButtonBase focusRipple className={classes.image}>
      <embed height="200" width="200" src={link} type={props.documentType} />
      <span className={classes.imageBackdrop} />
      <span className={classes.imageButtonBottom} onClick={handleClick}>
        <Typography
          component="span"
          variant="subtitle1"
          color="inherit"
          className={classes.imageTitle}
        >
          <p>
            <EditIcon fontSize="small" />
          </p>
          {props.title}
          <span className={classes.imageMarked} />
        </Typography>
        <input
          accept={props.accept || "image/*"}
          type="file"
          onChange={change}
          ref={hiddenFileInput}
          style={{ display: "none" }}
        />
      </span>
    </ButtonBase>
  );
}

export default LeadDocuments;

import React, { useContext } from 'react';
import { Link } from "react-router-dom";
// import ExpandLess from '@material-ui/icons/ExpandLess';
// import ExpandMore from '@material-ui/icons/ExpandMore';
import HomeIcon from '@material-ui/icons/Home';
import LinkIcon from '@material-ui/icons/Link';
import {
    Typography,
    Divider,
    ListItem,
    ListItemIcon,
    ListItemText,
    Drawer,
    IconButton,
    Container,
    Collapse,
    makeStyles,
    List,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import classNames from 'classnames';
import { AppContext } from './AppContextProvider';
import PeopleIcon from '@material-ui/icons/People';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import DescriptionIcon from '@material-ui/icons/Description';
import StoreOutlinedIcon from '@material-ui/icons/StoreOutlined';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { USERTYPES } from './Constants';
import path from './path';
import BuildIcon from '@material-ui/icons/Build';
import CloudUpload from '@material-ui/icons/CloudUpload';
import { ACCOUNT_CLOSURE_MODULE, ADD_LEAD_MODULE } from './Config';
import { REKYC_UPLOAD_TYPES, UPLOAD_TYPES } from './Tools/Upload';

const drawerWidth = 300;
const useStyles = makeStyles((theme) => ({
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    sectionDesktop: {
        display: 'flex',
        fontSize: 16,
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    menuIcon: {
        minWidth: 32
    },
    subMenuTitle: {
        // paddingLeft: 32, 
        paddingTop: 0,
        paddingBottom: 0
    },
    subMenuItem: {
        color: 'black',
        // paddingTop: 0, 
        // paddingBottom: 0
    },
    nested: {
        paddingLeft: 48,
        // color: 'black',
        paddingTop: 0,
        paddingBottom: 0,
    },
    link: {
        textDecoration: 'none !important',
        color: 'black'
    },
}));

export default function AppDrawer(props) {

    const classes = useStyles();
    const { state, handleDrawerClose, showConfirmDialog1D, closeConfirmDialog1D } = useContext(AppContext);

    const [openDrawer, setOpenDrawer] = React.useState({
        masters: false,
        transactions: false,
        tools: false,
        uploads: false,
        rekycUploads: false,
    })

    const handleClick = (type) => {
        // openDrawer[type] = !openDrawer[type];
        setOpenDrawer(openDrawer => ({ ...openDrawer, [type]: !openDrawer[type] }))
    }

    return (
        <Drawer
            anchor="left"
            className={classNames(classes.drawer, {
                [classes.drawerOpen]: state.open,
                [classes.drawerClose]: !state.open,
            })}
            classes={{
                paper: classNames({
                    [classes.drawerOpen]: state.open,
                    [classes.drawerClose]: !state.open,
                }),
            }}
            open={state.open}
            onClose={handleDrawerClose}
        >
            <div>
                <div className={classes.toolbar}>
                    <div style={{ float: "left", width: "100%", marginLeft: 2 }}>
                        <img
                            src={process.env.PUBLIC_URL + "/images/icon.png"}
                            alt="icon"
                            height={30}
                        />
                    </div>
                    <IconButton onClick={handleDrawerClose}>
                        {/* <AccountCircleIcon className={classes.accountCircle}/> */}
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <div style={{ height: "85vh", overflow: "auto" }}>
                    <Divider />
                    <Link
                        to={path.DASHBOARD}
                        style={{ textDecoration: "none", color: "black" }}
                        onClick={handleDrawerClose}
                    >
                        <ListItem button key="Dashboard">
                            <ListItemIcon className={classes.menuIcon}>
                                <HomeIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={<Typography variant="subtitle2">Dashboard</Typography>}
                            />
                        </ListItem>
                    </Link>
                    <Divider />
                    {
                        state.user && ([USERTYPES.KYC, USERTYPES.MD, USERTYPES.KYCHO, USERTYPES.HO, USERTYPES.FRAN, USERTYPES.RM, USERTYPES.BRANCH, USERTYPES.SALEHD, USERTYPES.STATEMG].includes(state.user.EmpUtype)) && (
                            <div>
                                <Link to={path.LEADS} style={{ textDecoration: "none", color: "black" }} onClick={handleDrawerClose}>
                                    <ListItem button key="View KYC Leads">
                                        <ListItemIcon className={classes.menuIcon}>
                                            <PeopleIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography variant="subtitle2">
                                                    View KYC Leads
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                </Link>
                                <Divider />
                            </div>
                        )
                    }
                    {
                        state.user && ([USERTYPES.KYC, USERTYPES.MD, USERTYPES.KYCHO, USERTYPES.HO, USERTYPES.FRAN, USERTYPES.RM, USERTYPES.BRANCH, USERTYPES.SALEHD, USERTYPES.STATEMG].includes(state.user.EmpUtype)) && (
                            <div>
                                <Link to={path.REKYC_LEADS} style={{ textDecoration: "none", color: "black" }} onClick={handleDrawerClose}>
                                    <ListItem button key="View Re-KYC Leads">
                                        <ListItemIcon className={classes.menuIcon}>
                                            <PeopleIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography variant="subtitle2">
                                                    View Re-KYC Leads
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                </Link>
                                <Divider />
                            </div>
                        )
                    }
                    {
                        ACCOUNT_CLOSURE_MODULE && state.user && ([USERTYPES.KYC, USERTYPES.MD, USERTYPES.KYCHO, USERTYPES.HO, USERTYPES.FRAN, USERTYPES.RM, USERTYPES.BRANCH, USERTYPES.SALEHD, USERTYPES.STATEMG].includes(state.user.EmpUtype)) && (
                            <div>
                                <Link to={path.CLOSURE_LEADS} style={{ textDecoration: "none", color: "black" }} onClick={handleDrawerClose}>
                                    <ListItem button key="View Account Closures">
                                        <ListItemIcon className={classes.menuIcon}>
                                            <PeopleIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography variant="subtitle2">
                                                    View Account Closures
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                </Link>
                                <Divider />
                            </div>
                        )
                    }
                    {
                        ADD_LEAD_MODULE && (
                            <ListItem
                                button
                                key="New Lead"
                                onClick={(e) => {
                                    handleDrawerClose();
                                    //openNewLoan();
                                    showConfirmDialog1D('Work in progress. Coming Soon', undefined, () => {
                                        closeConfirmDialog1D();
                                    }, 'OK')
                                }}>
                                <ListItemIcon className={classes.menuIcon}>
                                    <PersonAddIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography variant="subtitle2">
                                            New Lead
                                        </Typography>
                                    }
                                />
                            </ListItem>
                        )
                    }
                    <Divider />
                    {
                        state.user && ([USERTYPES.KYC, USERTYPES.SALEHD].includes(state.user.EmpUtype)) && (

                            <List className={classes.subMenuTitle}>
                                <ListItem button onClick={(e) => handleClick("masters")}>
                                    <ListItemIcon className={classes.menuIcon}>
                                        <StoreOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant="subtitle2"
                                                align="left"
                                                className="sub-menu"
                                            >
                                                Manage Masters
                                            </Typography>
                                        }
                                    />
                                    {openDrawer["masters"] ? (
                                        <ExpandLess />
                                    ) : (
                                        <ExpandMore />
                                    )}
                                </ListItem>
                                <Collapse
                                    in={openDrawer["masters"]}
                                    timeout="auto"
                                    unmountOnExit
                                    className={classes.subMenuItem}
                                >
                                    <List
                                        component="div"
                                        disablePadding
                                        onClick={handleDrawerClose}
                                    >
                                        {/* <Link to={path.DOCUMENTS} className={classes.link}>
                                    <ListItem
                                        button
                                        key="Documents"
                                        className={classes.nested}
                                    >
                                        <ListItemText
                                            primary={
                                                <Typography variant="subtitle2">
                                                    Documents
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                </Link> */}
                                        {
                                            state.user && ([USERTYPES.KYC].includes(state.user.EmpUtype)) && (
                                                <Link to={path.DROPDOWNS} className={classes.link}>
                                                    <ListItem
                                                        button
                                                        key="Dropdowns"
                                                        className={classes.nested}
                                                    >
                                                        <ListItemText
                                                            primary={
                                                                <Typography variant="subtitle2">
                                                                    Dropdowns
                                                                </Typography>
                                                            }
                                                        />
                                                    </ListItem>
                                                </Link>
                                            )
                                        }
                                        {
                                            state.user && ([USERTYPES.KYC, USERTYPES.SALEHD].includes(state.user.EmpUtype)) && (
                                                <Link to={path.COUPON} className={classes.link}>
                                                    <ListItem
                                                        button
                                                        key="Coupons"
                                                        className={classes.nested}
                                                    >
                                                        <ListItemText
                                                            primary={
                                                                <Typography variant="subtitle2">
                                                                    Coupons
                                                                </Typography>
                                                            }
                                                        />
                                                    </ListItem>
                                                </Link>
                                            )
                                        }
                                        {
                                            state.user && ([USERTYPES.KYC].includes(state.user.EmpUtype)) && (
                                                <Link to={path.TERMS} className={classes.link}>
                                                    <ListItem
                                                        button
                                                        key="Terms"
                                                        className={classes.nested}
                                                    >
                                                        <ListItemText
                                                            primary={
                                                                <Typography variant="subtitle2">
                                                                    Terms
                                                                </Typography>
                                                            }
                                                        />
                                                    </ListItem>
                                                </Link>
                                            )
                                        }
                                    </List>
                                </Collapse>
                            </List>
                        )
                    }
                    {
                        state.user && ([USERTYPES.KYC].includes(state.user.EmpUtype)) && (
                            <Divider />
                        )
                    }
                    {
                        state.user && ([USERTYPES.KYC].includes(state.user.EmpUtype)) && (
                            <List className={classes.subMenuTitle}>
                                <ListItem button onClick={(e) => handleClick("transactions")}>
                                    <ListItemIcon className={classes.menuIcon}>
                                        <DescriptionIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant="subtitle2"
                                                align="left"
                                                className="sub-menu"
                                            >
                                                View Transactions
                                            </Typography>
                                        }
                                    />
                                    {openDrawer["transactions"] ? (
                                        <ExpandLess />
                                    ) : (
                                        <ExpandMore />
                                    )}
                                </ListItem>
                                <Collapse
                                    in={openDrawer["transactions"]}
                                    timeout="auto"
                                    unmountOnExit
                                    className={classes.subMenuItem}
                                >
                                    <List
                                        component="div"
                                        disablePadding
                                        onClick={handleDrawerClose}
                                    >
                                        <Link to={path.PENNY_DROP_LOGS} className={classes.link}>
                                            <ListItem
                                                button
                                                key="Pennydrop"
                                                className={classes.nested}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="subtitle2">
                                                            Pennydrop
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        </Link>
                                        <Link to={path.LOGS} className={classes.link}>
                                            <ListItem
                                                button
                                                key="Logs"
                                                className={classes.nested}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="subtitle2">
                                                            Logs
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        </Link>
                                    </List>
                                </Collapse>
                            </List>
                        )
                    }
                    {
                        state.user && ([USERTYPES.KYC].includes(state.user.EmpUtype)) && (
                            <Divider />
                        )
                    }
                    {
                        state.user && ([USERTYPES.KYC].includes(state.user.EmpUtype)) && (
                            <List className={classes.subMenuTitle}>
                                <ListItem button onClick={(e) => handleClick("tools")}>
                                    <ListItemIcon className={classes.menuIcon}>
                                        <BuildIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant="subtitle2"
                                                align="left"
                                                className="sub-menu"
                                            >
                                                Tools
                                            </Typography>
                                        }
                                    />
                                    {openDrawer["tools"] ? (
                                        <ExpandLess />
                                    ) : (
                                        <ExpandMore />
                                    )}
                                </ListItem>
                                <Collapse
                                    in={openDrawer["tools"]}
                                    timeout="auto"
                                    unmountOnExit
                                    className={classes.subMenuItem}
                                >
                                    <List
                                        component="div"
                                        disablePadding
                                        onClick={handleDrawerClose}
                                    >
                                        <Link to={path.UTILITY} className={classes.link}>
                                            <ListItem
                                                button
                                                key="Import"
                                                className={classes.nested}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="subtitle2">
                                                            Import / Export
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        </Link>
                                        <Link to={path.JOB_LOGS} className={classes.link}>
                                            <ListItem
                                                button
                                                className={classes.nested}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="subtitle2">
                                                            Job Logs
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        </Link>
                                    </List>
                                </Collapse>
                            </List>
                        )
                    }
                    {
                        state.user && ([USERTYPES.KYC].includes(state.user.EmpUtype)) && (
                            <Divider />
                        )
                    }
                    {
                        state.user && ([USERTYPES.KYC, USERTYPES.KYCHO].includes(state.user.EmpUtype)) && (
                            <List className={classes.subMenuTitle}>
                                <ListItem button onClick={(e) => handleClick("uploads")}>
                                    <ListItemIcon className={classes.menuIcon}>
                                        <CloudUpload />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant="subtitle2"
                                                align="left"
                                                className="sub-menu"
                                            >
                                                KYC Uploads
                                            </Typography>
                                        }
                                    />
                                    {openDrawer["uploads"] ? (
                                        <ExpandLess />
                                    ) : (
                                        <ExpandMore />
                                    )}
                                </ListItem>
                                <Collapse
                                    in={openDrawer["uploads"]}
                                    timeout="auto"
                                    unmountOnExit
                                    className={classes.subMenuItem}
                                >
                                    <List
                                        component="div"
                                        disablePadding
                                        onClick={handleDrawerClose}
                                    >
                                        {
                                            Object.keys(UPLOAD_TYPES).map((key, index) => (
                                                <Link key={index} to={path.UPLOAD.replace(":type", key)} className={classes.link}>
                                                    <ListItem
                                                        button
                                                        key={key}
                                                        className={classes.nested}
                                                    >
                                                        <ListItemText
                                                            primary={
                                                                <Typography variant="subtitle2">
                                                                    {UPLOAD_TYPES[key].label}
                                                                </Typography>
                                                            }
                                                        />
                                                    </ListItem>
                                                </Link>
                                            ))
                                        }
                                    </List>
                                </Collapse>
                            </List>
                        )
                    }
                    {
                        state.user && ([USERTYPES.KYC, USERTYPES.KYCHO].includes(state.user.EmpUtype)) && (
                            <Divider />
                        )
                    }
                    {
                        state.user && ([USERTYPES.KYC, USERTYPES.KYCHO].includes(state.user.EmpUtype)) && (
                            <List className={classes.subMenuTitle}>
                                <ListItem button onClick={(e) => handleClick("rekycUploads")}>
                                    <ListItemIcon className={classes.menuIcon}>
                                        <CloudUpload />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant="subtitle2"
                                                align="left"
                                                className="sub-menu"
                                            >
                                                Re-KYC Uploads
                                            </Typography>
                                        }
                                    />
                                    {openDrawer["rekycUploads"] ? (
                                        <ExpandLess />
                                    ) : (
                                        <ExpandMore />
                                    )}
                                </ListItem>
                                <Collapse
                                    in={openDrawer["rekycUploads"]}
                                    timeout="auto"
                                    unmountOnExit
                                    className={classes.subMenuItem}
                                >
                                    <List
                                        component="div"
                                        disablePadding
                                        onClick={handleDrawerClose}
                                    >
                                        {
                                            Object.keys(REKYC_UPLOAD_TYPES).filter(u => REKYC_UPLOAD_TYPES[u].rekyc_module_enabled).map((key, index) => (
                                                <Link key={index} to={path.REKYC_UPLOAD.replace(":type", key)} className={classes.link}>
                                                    <ListItem
                                                        button
                                                        key={key}
                                                        className={classes.nested}
                                                    >
                                                        <ListItemText
                                                            primary={
                                                                <Typography variant="subtitle2">
                                                                    {REKYC_UPLOAD_TYPES[key].label}
                                                                </Typography>
                                                            }
                                                        />
                                                    </ListItem>
                                                </Link>
                                            ))
                                        }
                                    </List>
                                </Collapse>
                            </List>
                        )
                    }
                    {
                        state.user && ([USERTYPES.KYC, USERTYPES.KYCHO].includes(state.user.EmpUtype)) && (
                            <Divider />
                        )
                    }
                    <Link
                        to={path.IMP_LINKS}
                        style={{ textDecoration: "none", color: "black" }}
                        onClick={handleDrawerClose}
                    >
                        <ListItem button key="Dashboard">
                            <ListItemIcon className={classes.menuIcon}>
                                <LinkIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={<Typography variant="subtitle2">Important Links</Typography>}
                            />
                        </ListItem>
                    </Link>
                </div>
            </div>
            <div>
                <Container maxWidth="sm" style={{ position: "absolute", bottom: 0 }}>
                    <Typography variant="subtitle2" style={{ textAlign: "center", fontSize: 10 }}>
                        Version: {global.appVersion}
                        {/* <React.Fragment>
                            <br />
                                Powered by Acumen
                        </React.Fragment> */}
                    </Typography>
                </Container>
            </div>
        </Drawer>
    );
}
